//= ====================================
//            Faturalar
//= ====================================
export const faturalarServer = {
  FATURA_ID: 'FaturaID',
  FATURA_NO: 'FaturaNo',
  CARI_ADI: 'CariAdi',
  FATURA_NOTU: 'FaturaNotu', // Tarih
  YEKUN: 'Yekun',
  TARIH: 'Tarih',
  VADE_TARIHI: 'VadeTarihi',
  ACIK_KAPALI: 'AcikKapali',
  KASA_ID: 'KasaID',
  KASA_ADI: 'KasaAdi',
  FATURA_TURU: 'FaturaTuru',
  DEPO_KODU: 'DepoKodu',
  DEPO_ADI: 'DepoAdi',
  CARI_ID: 'CariID',
  TUTAR: 'Tutar',
  OTV_TUTAR: 'OtvTutar',
  KDV_TUTAR: 'KdvTutar',
  TOPLAM_TUTAR: 'ToplamTutar',
};

export const faturalarClient = {
  FATURA_ID: 'faturaId',
  FATURA_NO: 'faturaNo',
  CARI_ADI: 'cariAdi',
  FATURA_NOTU: 'faturaNotu', // Tarih
  YEKUN: 'yekun',
  TARIH: 'tarih',
  VADE_TARIHI: 'vadeTarihi',
  ACIK_KAPALI: 'acikKapali',
  KASA_ID: 'kasaID',
  KASA_ADI: 'kasaAdi',
  FATURA_TURU: 'faturaTuru',
  DEPO_KODU: 'depoKodu',
  DEPO_ADI: 'depoAdi',
  CARI_ID: 'cariId',
  TUTAR: 'tutar',
  OTV_TUTAR: 'otvTutar',
  KDV_TUTAR: 'kdvTutar',
  TOPLAM_TUTAR: 'toplamTutar',
};

export const faturaDetaylarServer = {
  STOK_FISI_DETAY_ID: 'StokFisiDetayID',
  FATURA_DETAY_ID: 'FaturaDetayID',
  CARI_FISI_DETAY_STOK_ID: 'CariFisiDetayStokID',
  STOK_CARI_ID: 'StokCariID',
  STOK_CARI_ADI: 'StokCariAdi',
  STOK_CARI_BIRIM: 'StokCariBirim',
  MIKTAR: 'Miktar',
  FIYAT: 'Fiyat',
  KDV: 'Kdv',
  OTV: 'Otv',
  TUTAR: 'Tutar',
  PARA_BIRIMI_ID: 'ParaBirimiID',
  PARA_BIRIMI: 'ParaBirimi',
  FATURA_TURU: 'FaturaTuru',
  FATURA_ID: 'FaturaID',
  SIRA_NO: 'SiraNo',
  DEPO_KODU: 'DepoKodu',
  STOK_ID: 'StokID',
  BIRIMI: 'Birimi',
};

export const faturaDetaylarClient = {
  STOK_FISI_DETAY_ID: 'stokFisiDetayId',
  FATURA_DETAY_ID: 'faturaDetayId',
  CARI_FISI_DETAY_STOK_ID: 'cariFisiDetayStokId',
  STOK_CARI_ID: 'stokCariId',
  STOK_CARI_ADI: 'stokCariAdi',
  STOK_CARI_BIRIM: 'stokCariBirim',
  MIKTAR: 'miktar',
  FIYAT: 'fiyat',
  KDV: 'kdv',
  OTV: 'otv',
  TUTAR: 'tutar',
  PARA_BIRIMI_ID: 'paraBirimiId',
  PARA_BIRIMI: 'paraBirimi',
  FATURA_TURU: 'faturaTuru',
  FATURA_ID: 'faturaId',
  SIRA_NO: 'siraNo',
  DEPO_KODU: 'depoKodu',
  STOK_ID: 'stokId',
  BIRIMI: 'birimi',
};
