/* eslint-disable arrow-body-style */
import moment from 'moment';
import * as actionTypes from './actionTypes';
import updateObject from '../../utility';

const initialCariRaporuState = {
  cariRaporu: null,
  selectedCariRaporuCari: null,
  cariRaporuSelectedDates: {
    startDate: null,
    endDate: null,
  },
};

const initialStokRaporuState = {
  stokRaporu: null,
  selectedStokRaporuStok: null,
  stokRaporuSelectedDates: {
    startDate: null,
    endDate: null,
  },
};

const initialKasaRaporuState = {
  kasaRaporu: null,
  selectedKasaRaporuKasa: null,
  kasaRaporuSelectedDates: {
    startDate: null,
    endDate: null,
  },
};

const initialHizmetRaporuState = {
  hizmetRaporu: null,
  selectedHizmetRaporuHizmet: null,
  hizmetRaporuSelectedDates: {
    startDate: null,
    endDate: null,
  },
  selectedHizmetRaporuAlisSatis: { value: '', label: 'Hepsi' },
};

const initialFaturaRaporuState = {
  faturaRaporu: null,
  selectedFaturaRaporuCari: null,
  selectedFaturaRaporuStok: null,
  faturaRaporuSelectedDates: {
    startDate: null,
    endDate: null,
  },
  selectedFaturaRaporuFaturaTuru: { value: '', label: 'Hepsi' },
};

const initialGunlukRaporuState = {
  gunlukRapor: null,
  gunlukRaporKasa: null,
  gunlukRaporSelectedDates: {
    startDate: moment().startOf('day'),
    endDate: moment().endOf('day'),
  },
  selectedGunlukRaporKasa: null,
};

const initialState = {
  ...initialGunlukRaporuState,
  ...initialCariRaporuState,
  ...initialStokRaporuState,
  ...initialKasaRaporuState,
  ...initialHizmetRaporuState,
  ...initialFaturaRaporuState,
  error: null,
  loading: null,
  pdfReportForMobile: null,
};

//= ====================================
//            Günlük Raporlar
//= ====================================
const fetchGunlukRaporlar = (state) => {
  return updateObject(state, {
    gunlukRapor: [],
    loading: true,
    error: null,
  });
};

const fetchGunlukRaporlarKasa = (state) => {
  return updateObject(state, {
    gunlukRaporKasa: [],
    loading: true,
    error: null,
  });
};

const changeGunlukRaporDates = (state, action) => {
  return updateObject(state, {
    gunlukRaporSelectedDates: {
      startDate: action.payload.startDate,
      endDate: action.payload.endDate,
    },
  });
};

const selectGunlukRaporKasa = (state, action) => {
  return updateObject(state, {
    selectedGunlukRaporKasa: action.payload.selectedKasa,
  });
};

//= ====================================
//            Cari Raporları
//= ====================================
const fetchCariRaporu = (state) => {
  return updateObject(state, {
    cariRaporu: [],
    loading: true,
    error: null,
  });
};

const changeCariRaporuDates = (state, action) => {
  return updateObject(state, {
    cariRaporuSelectedDates: {
      startDate: action.payload.startDate,
      endDate: action.payload.endDate,
    },
  });
};

const selectCariRaporuCari = (state, action) => {
  return updateObject(state, {
    selectedCariRaporuCari: action.payload.selectedCari,
  });
};

//= ====================================
//            Stok Raporları
//= ====================================
const fetchStokRaporu = (state) => {
  return updateObject(state, {
    stokRaporu: [],
    loading: true,
    error: null,
  });
};

const changeStokRaporuDates = (state, action) => {
  return updateObject(state, {
    stokRaporuSelectedDates: {
      startDate: action.payload.startDate,
      endDate: action.payload.endDate,
    },
  });
};

const selectStokRaporuStok = (state, action) => {
  return updateObject(state, {
    selectedStokRaporuStok: action.payload.selectedStok,
  });
};

//= ====================================
//            Kasa Raporları
//= ====================================
const fetchKasaRaporu = (state) => {
  return updateObject(state, {
    kasaRaporu: [],
    loading: true,
    error: null,
  });
};

const changeKasaRaporuDates = (state, action) => {
  return updateObject(state, {
    kasaRaporuSelectedDates: {
      startDate: action.payload.startDate,
      endDate: action.payload.endDate,
    },
  });
};

const selectKasaRaporuKasa = (state, action) => {
  return updateObject(state, {
    selectedKasaRaporuKasa: action.payload.selectedKasa,
  });
};

//= ====================================
//            Hizmet Raporları
//= ====================================
const fetchHizmetRaporu = (state) => {
  return updateObject(state, {
    hizmetRaporu: [],
    loading: true,
    error: null,
  });
};

const changeHizmetRaporuDates = (state, action) => {
  return updateObject(state, {
    hizmetRaporuSelectedDates: {
      startDate: action.payload.startDate,
      endDate: action.payload.endDate,
    },
  });
};

const selectHizmetRaporuHizmet = (state, action) => {
  return updateObject(state, {
    selectedHizmetRaporuHizmet: action.payload.selectedHizmet,
  });
};

const selectHizmetRaporuAlisSatis = (state, action) => {
  return updateObject(state, {
    selectedHizmetRaporuAlisSatis: action.payload.selectedAlisSatis,
  });
};

//= ====================================
//            Fatura Raporları
//= ====================================
const fetchFaturaRaporu = (state) => {
  return updateObject(state, {
    faturaRaporu: [],
    loading: true,
    error: null,
  });
};

const changeFaturaRaporuDates = (state, action) => {
  return updateObject(state, {
    faturaRaporuSelectedDates: {
      startDate: action.payload.startDate,
      endDate: action.payload.endDate,
    },
  });
};

const selectFaturaRaporuCari = (state, action) => {
  return updateObject(state, {
    selectedFaturaRaporuCari: action.payload.selectedCari,
  });
};

const selectFaturaRaporuStok = (state, action) => {
  return updateObject(state, {
    selectedFaturaRaporuStok: action.payload.selectedStok,
  });
};

const selectFaturaRaporuFaturaTuru = (state, action) => {
  return updateObject(state, {
    selectedFaturaRaporuFaturaTuru: action.payload.selectedFaturaTuru,
  });
};

//= ====================================
//            Others
//= ====================================
const raporlarFetchSuccess = (state, action) => {
  return updateObject(state, {
    [action.key]: action.payload,
    loading: false,
    error: null,
  });
};

const apiFailed = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.payload,
  });
};

const raporlar = (state = initialState, action) => {
  switch (action.type) {
    //= ====================================
    //            Günlük Raporlar
    //= ====================================
    case actionTypes.FETCH_GUNLUK_RAPOR:
      return fetchGunlukRaporlar(state);
    case actionTypes.FETCH_GUNLUK_RAPOR_KASA:
      return fetchGunlukRaporlarKasa(state);
    case actionTypes.CHANGE_GUNLUK_RAPOR_DATES:
      return changeGunlukRaporDates(state, action);
    case actionTypes.SELECT_GUNLUK_RAPOR_KASA:
      return selectGunlukRaporKasa(state, action);

    //= ====================================
    //            Cari Raporları
    //= ====================================
    case actionTypes.FETCH_CARI_RAPORU:
      return fetchCariRaporu(state);
    case actionTypes.CHANGE_CARI_RAPORU_DATES:
      return changeCariRaporuDates(state, action);
    case actionTypes.SELECT_CARI_RAPORU_CARI:
      return selectCariRaporuCari(state, action);

    //= ====================================
    //            Stok Raporları
    //= ====================================
    case actionTypes.FETCH_STOK_RAPORU:
      return fetchStokRaporu(state);
    case actionTypes.CHANGE_STOK_RAPORU_DATES:
      return changeStokRaporuDates(state, action);
    case actionTypes.SELECT_STOK_RAPORU_STOK:
      return selectStokRaporuStok(state, action);

    //= ====================================
    //            Kasa Raporları
    //= ====================================
    case actionTypes.FETCH_KASA_RAPORU:
      return fetchKasaRaporu(state);
    case actionTypes.CHANGE_KASA_RAPORU_DATES:
      return changeKasaRaporuDates(state, action);
    case actionTypes.SELECT_KASA_RAPORU_KASA:
      return selectKasaRaporuKasa(state, action);

    //= ====================================
    //            Hizmet Raporları
    //= ====================================
    case actionTypes.FETCH_HIZMET_RAPORU:
      return fetchHizmetRaporu(state);
    case actionTypes.CHANGE_HIZMET_RAPORU_DATES:
      return changeHizmetRaporuDates(state, action);
    case actionTypes.SELECT_HIZMET_RAPORU_HIZMET:
      return selectHizmetRaporuHizmet(state, action);
    case actionTypes.SELECT_HIZMET_RAPORU_ALIS_SATIS:
      return selectHizmetRaporuAlisSatis(state, action);

    //= ====================================
    //            Fatura Raporları
    //= ====================================
    case actionTypes.FETCH_FATURA_RAPORU:
      return fetchFaturaRaporu(state);
    case actionTypes.CHANGE_FATURA_RAPORU_DATES:
      return changeFaturaRaporuDates(state, action);
    case actionTypes.SELECT_FATURA_RAPORU_CARI:
      return selectFaturaRaporuCari(state, action);
    case actionTypes.SELECT_FATURA_RAPORU_STOK:
      return selectFaturaRaporuStok(state, action);
    case actionTypes.SELECT_FATURA_RAPORU_FATURA_TURU:
      return selectFaturaRaporuFaturaTuru(state, action);

    //= ====================================
    //            Others
    //= ====================================
    case actionTypes.RAPORLAR_FETCH_API_SUCCESS:
      return raporlarFetchSuccess(state, action);
    case actionTypes.API_FAILED:
      return apiFailed(state, action);
    default:
      return state;
  }
};

export default raporlar;
