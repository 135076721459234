/* eslint-disable import/prefer-default-export */
import { gunlukRaporlarClient } from '../../../../constants/raporlar';
import { formatMoney } from '../../../../helpers/utils';

export const responseFetchCariRaporuMapper = (data) =>
  data.reduce((memo, el) => {
    if (el.CariKodu !== null && el.CariKodu !== '') {
      if (el.Bakiye === '') {
        el.Bakiye = '0,00';
      }
      if (el.Borc === '') {
        el.Borc = '0,00';
      }
      if (el.Alacak === '') {
        el.Alacak = '0,00';
      }

      el.Bakiye = formatMoney(parseFloat(el.Bakiye.replace(',', '.')));
      el.Borc = formatMoney(parseFloat(el.Borc.replace(',', '.')));
      el.Alacak = formatMoney(parseFloat(el.Alacak.replace(',', '.')));
      memo.push(el);
    }
    return memo;
  }, []);

export const responseFetchStokRaporuMapper = (data) =>
  data.reduce((memo, el) => {
    if (el.StokKodu !== null && el.StokKodu !== '') {
      if (el.Giris === '') {
        el.Giris = '0,00';
      }
      if (el.Cikis === '') {
        el.Cikis = '0,00';
      }
      if (el.Kalan === '') {
        el.Kalan = '0,00';
      }
      el.Giris = formatMoney(parseFloat(el.Giris.replace(',', '.')));
      el.Cikis = formatMoney(parseFloat(el.Cikis.replace(',', '.')));
      el.Kalan = formatMoney(parseFloat(el.Kalan.replace(',', '.')));
      memo.push(el);
    }
    return memo;
  }, []);

export const responseFetchKasaRaporuMapper = (data) =>
  data.reduce((memo, el) => {
    if (el.KasaKodu !== null && el.KasaKodu !== '') {
      if (el.BorcTutari === '') {
        el.BorcTutari = '0,00';
      }
      if (el.AlacakTutari === '') {
        el.AlacakTutari = '0,00';
      }
      if (el.Bakiye === '') {
        el.Bakiye = '0,00';
      }
      el.BorcTutari = formatMoney(parseFloat(el.BorcTutari.replace(',', '.')));
      el.AlacakTutari = formatMoney(parseFloat(el.AlacakTutari.replace(',', '.')));
      el.Bakiye = formatMoney(parseFloat(el.Bakiye.replace(',', '.')));
      memo.push(el);
    }
    return memo;
  }, []);

export const responseFetchHizmetRaporuMapper = (data) =>
  data.reduce((memo, el) => {
    if (el.HizmetKodu !== null && el.HizmetKodu !== '') {
      if (el.Yekun === '') {
        el.Yekun = '0,00';
      }
      el.Yekun = formatMoney(parseFloat(el.Yekun.replace(',', '.')));
      memo.push(el);
    }
    return memo;
  }, []);

export const responseFetchFaturaRaporuMapper = (data) =>
  data.reduce((memo, el) => {
    if (el.FaturaNo !== null && el.FaturaNo !== '') {
      if (el.Miktar === '') {
        el.Miktar = '0,00';
      }
      if (el.Yekun === '') {
        el.Yekun = '0,00';
      }
      el.Miktar = formatMoney(parseFloat(el.Miktar.replace(',', '.')));
      el.Yekun = formatMoney(parseFloat(el.Yekun.replace(',', '.')));
      memo.push(el);
    }
    return memo;
  }, []);

export const responseFetchGunlukRaporMapper = (payload) => ({
  [gunlukRaporlarClient.ALISLAR_PESIN]: formatMoney(
    parseFloat(payload[0].deger.replace(',', '.')) || 0.0
  ),
  [gunlukRaporlarClient.ALISLAR_VADELI]: formatMoney(
    parseFloat(payload[1].deger.replace(',', '.')) || 0.0
  ),
  [gunlukRaporlarClient.ALISLAR_TOPLAM]: formatMoney(
    parseFloat(payload[2].deger.replace(',', '.')) || 0.0
  ),
  [gunlukRaporlarClient.SATISLAR_PESIN]: formatMoney(
    parseFloat(payload[3].deger.replace(',', '.')) || 0.0
  ),
  [gunlukRaporlarClient.SATISLAR_VADELI]: formatMoney(
    parseFloat(payload[4].deger.replace(',', '.')) || 0.0
  ),
  [gunlukRaporlarClient.SATISLAR_TOPLAM]: formatMoney(
    parseFloat(payload[5].deger.replace(',', '.')) || 0.0
  ),
  [gunlukRaporlarClient.TAHSILATLAR_PESIN]: formatMoney(
    parseFloat(payload[6].deger.replace(',', '.')) || 0.0
  ),
  [gunlukRaporlarClient.TEDIYELER_PESIN]: formatMoney(
    parseFloat(payload[7].deger.replace(',', '.')) || 0.0
  ),
});

export const responseFetchGunlukRaporKasaMapper = (payload) => ({
  [gunlukRaporlarClient.KASA_GIRIS]: formatMoney(
    parseFloat(payload[0].deger.replace(',', '.')) || 0.0
  ),
  [gunlukRaporlarClient.KASA_CIKIS]: formatMoney(
    parseFloat(payload[1].deger.replace(',', '.')) || 0.0
  ),
  [gunlukRaporlarClient.KASA_BAKIYE]: formatMoney(
    parseFloat(payload[2].deger.replace(',', '.')) || 0.0
  ),
});
