import { takeEvery, fork, put, all, call, select } from 'redux-saga/effects';
import * as actionTypes from './actionTypes';
import * as actions from './actions';
import * as layoutActions from '../../layout/actions';
import * as api from '../../../api/cariler';
import * as mapper from './utils/mapper';
import * as schemas from './utils/schemas';
import * as constants from '../../../constants/cariler';

const getMusteriId = (state) => state.auth.user.musteriId;
const getMusteriAdi = (state) => state.auth.user.musteriAdi;
const getMusteriSifre = (state) => state.auth.user.musteriSifre;

function* fetchCariKartlariWorker({ payload: { siralama, arama, kayitBaslangic, kayitBitis } }) {
  const musteriId = yield select(getMusteriId);
  const musteriAdi = yield select(getMusteriAdi);
  const musteriSifre = yield select(getMusteriSifre);

  try {
    const response = yield call(
      api.fetchCariKartlari,
      musteriId,
      musteriAdi,
      musteriSifre,
      siralama,
      arama,
      kayitBaslangic,
      kayitBitis
    );
    const { data } = response;

    yield put(actions.fetchCariKartlariSuccess(mapper.responseFetchCariKartlariMapper(data)));
  } catch (error) {
    yield put(layoutActions.showError(error.message));
  }
}

function* addCariKartiWorker({ payload, history }) {
  yield put(layoutActions.showLoading());
  const musteriId = yield select(getMusteriId);
  const musteriAdi = yield select(getMusteriAdi);
  const musteriSifre = yield select(getMusteriSifre);

  const mappedPayload = mapper.requestAddCariKartiMapper(
    musteriId,
    musteriAdi,
    musteriSifre,
    payload
  );

  try {
    const err = {
      message: 'Bilinmeyen bir hata oluştu',
    };

    // First check cari kodu is present or not.
    {
      const responseCheck = yield call(
        api.checkCariKodu,
        musteriId,
        musteriAdi,
        musteriSifre,
        mappedPayload.CariKodu
      );

      // Check response schema
      const { data } = responseCheck;
      if (!schemas.responseVarYokSchema.isValidSync(data)) {
        throw err;
      }

      if (data[0].geriDonenDeger === 'var') {
        err.message = 'Bu cari kodu kayıtlı. Lütfen başka cari kodu giriniz.';
        throw err;
      }
    }

    // Send request to add
    const response = yield call(api.addCariKarti, mappedPayload);

    // Check response schema
    const { data } = response;
    if (!schemas.responseAddCariSchema.isValidSync(data)) {
      throw err;
    }
    yield put(layoutActions.showMessage('Başarıyla eklendi'));
    history.push('/cariler');
  } catch (error) {
    yield put(layoutActions.showError(error.message));
  }
}

function* deleteCariKartiWorker({ payload: { cariId }, history }) {
  yield put(layoutActions.showLoading());
  const musteriId = yield select(getMusteriId);
  const musteriAdi = yield select(getMusteriAdi);
  const musteriSifre = yield select(getMusteriSifre);

  try {
    const err = {
      message: 'Bilinmeyen bir hata oluştu',
    };

    // First check stok is used or not
    {
      const responseCheck = yield call(
        api.checkCariFisi,
        musteriId,
        musteriAdi,
        musteriSifre,
        cariId
      );

      // Check response schema
      const { data } = responseCheck;
      if (!schemas.responseVarYokSchema.isValidSync(data)) {
        throw err;
      }

      if (data[0].geriDonenDeger === 'var') {
        err.message = 'Bu cari kartı işlem gördüğü için silinemez.';
        throw err;
      }
    }

    // Send request to delete
    const response = yield call(api.deleteCariKarti, musteriId, musteriAdi, musteriSifre, cariId);

    // Check response schema
    const { data } = response;
    if (!schemas.responseStringDegerSchema.isValidSync(data)) {
      throw err;
    }
    if (data[0].StringDeger !== 'Cari Karti silindi.') {
      err.message = data[0].stringDeger;
      throw err;
    }
    yield put(layoutActions.showMessage('Cari Karti silindi.'));
    history.push('/cariler');
  } catch (error) {
    yield put(layoutActions.showError(error.message));
  }
}

function* fetchCariDetaylarWorker({ payload: { cariId } }) {
  yield put(layoutActions.showLoading());
  const musteriId = yield select(getMusteriId);
  const musteriAdi = yield select(getMusteriAdi);
  const musteriSifre = yield select(getMusteriSifre);

  try {
    const err = {
      message: 'Bilinmeyen bir hata oluştu',
    };
    const response = yield call(api.fetchCariDetaylar, musteriId, musteriAdi, musteriSifre, cariId);
    const { data } = response;
    if (!schemas.responseCariDetaylarSchema.isValidSync(data)) {
      throw err;
    }
    yield put(actions.fetchCariDetaylarSuccess(mapper.responseFetchCariDetaylarMapper(data)));
  } catch (error) {
    yield put(layoutActions.showError(error.message));
  }
  yield put(layoutActions.hideLoading());
}

function* fetchCariFaturalarWorker({ payload: { cariId } }) {
  const musteriId = yield select(getMusteriId);
  const musteriAdi = yield select(getMusteriAdi);
  const musteriSifre = yield select(getMusteriSifre);

  try {
    const response = yield call(
      api.fetchCariFaturalar,
      musteriId,
      musteriAdi,
      musteriSifre,
      cariId
    );
    const { data } = response;

    yield put(
      actions.fetchCariFaturalarSuccess(
        mapper.responseDateMapper(
          data,
          constants.carilerFaturaServer.FATURA_TARIHI,
          constants.carilerFaturaServer.YEKUN
        )
      )
    );
  } catch (error) {
    yield put(layoutActions.showError(error.message));
  }
}

function* fetchCariMakbuzlarWorker({ payload: { cariId } }) {
  const musteriId = yield select(getMusteriId);
  const musteriAdi = yield select(getMusteriAdi);
  const musteriSifre = yield select(getMusteriSifre);

  try {
    const response = yield call(
      api.fetchCariMakbuzlar,
      musteriId,
      musteriAdi,
      musteriSifre,
      cariId
    );
    const { data } = response;

    yield put(
      actions.fetchCariMakbuzlarSuccess(
        mapper.responseDateMapper(
          data,
          constants.carilerMakbuzServer.MAKBUZ_TARIHI,
          constants.carilerMakbuzServer.TUTAR
        )
      )
    );
  } catch (error) {
    yield put(layoutActions.showError(error.message));
  }
}

function* updateCariKartiWorker({ payload, history }) {
  yield put(layoutActions.showLoading());
  const musteriId = yield select(getMusteriId);
  const musteriAdi = yield select(getMusteriAdi);
  const musteriSifre = yield select(getMusteriSifre);

  const mappedPayload = mapper.requestUpdateCariKartiMapper(
    musteriId,
    musteriAdi,
    musteriSifre,
    payload
  );

  try {
    const err = {
      message: 'Bilinmeyen bir hata oluştu',
    };

    // Send request to add
    const response = yield call(api.updateCariKarti, mappedPayload);

    // Check response schema
    const { data } = response;
    if (!schemas.responseStringDegerSchema.isValidSync(data)) {
      throw err;
    }
    if (data[0].StringDeger === 'Cari Karti Güncellenemedi.') {
      err.message = data[0].stringDeger;
      throw err;
    }

    yield put(layoutActions.showMessage('Başarıyla güncellendi'));
    history.push('/cariler');
  } catch (error) {
    yield put(layoutActions.showError(error.message));
  }
}

export function* watchCariler() {
  yield takeEvery(actionTypes.FETCH_CARI_KARTLARI, fetchCariKartlariWorker);
  yield takeEvery(actionTypes.ADD_CARI_KARTI, addCariKartiWorker);
  yield takeEvery(actionTypes.FETCH_CARI_DETAYLAR, fetchCariDetaylarWorker);
  yield takeEvery(actionTypes.FETCH_CARI_FATURALAR, fetchCariFaturalarWorker);
  yield takeEvery(actionTypes.FETCH_CARI_MAKBUZLAR, fetchCariMakbuzlarWorker);
  yield takeEvery(actionTypes.FETCH_CARI_DELETE, deleteCariKartiWorker);
  yield takeEvery(actionTypes.UPDATE_CARI_KARTI, updateCariKartiWorker);
}

function* carilerSaga() {
  yield all([fork(watchCariler)]);
}

export default carilerSaga;
