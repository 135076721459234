/* eslint-disable arrow-body-style */
import * as actionTypes from './actionTypes';
import updateObject from '../../utility';

const initialState = {
  cariKartlari: [],
  cariFaturalar: [],
  cariMakbuzlar: [],
  cariKartlariTableLoading: false,
  cariFaturalarTableLoading: false,
  cariMakbuzlarTableLoading: false,
  cariKarti: null,
};

//= ====================================
//            Cari Kartlari
//= ====================================
const fetchCariKartlari = (state) => {
  return updateObject(state, {
    cariKartlari: [],
    cariKartlariTableLoading: true,
    cariKarti: null,
  });
};

const fetchCariKartlariSuccess = (state, action) => {
  return updateObject(state, {
    cariKartlari: action.payload.cariKartlari,
    cariKartlariTableLoading: false,
  });
};

const fetchCariKartlariFailure = (state) => {
  return updateObject(state, {
    cariKartlari: null,
    cariKartlariTableLoading: false,
  });
};

//= ====================================
//            Cari Faturalar
//= ====================================
const fetchCariFaturalar = (state) => {
  return updateObject(state, {
    cariFaturalar: [],
    cariFaturalarTableLoading: true,
    cariFaturalarMessage: null,
  });
};

const fetchCariFaturalarSuccess = (state, action) => {
  return updateObject(state, {
    cariFaturalar: action.payload.cariFaturalar,
    cariFaturalarTableLoading: false,
  });
};

const fetchCariFaturalarFailure = (state) => {
  return updateObject(state, {
    cariFaturalar: null,
    cariFaturalarTableLoading: false,
  });
};

//= ====================================
//            Cari Makbuzlar
//= ====================================
const fetchCariMakbuzlar = (state) => {
  return updateObject(state, {
    cariMakbuzlar: [],
    cariMakbuzlarTableLoading: true,
    cariMakbuzlarMessage: null,
  });
};

const fetchCariMakbuzlarSuccess = (state, action) => {
  return updateObject(state, {
    cariMakbuzlar: action.payload.cariMakbuzlar,
    cariMakbuzlarTableLoading: false,
  });
};

const fetchCariMakbuzlarFailure = (state) => {
  return updateObject(state, {
    cariMakbuzlar: null,
    cariMakbuzlarTableLoading: false,
  });
};

//= ====================================
//            Cari Karti Detaylar
//= ====================================
const fetchCariKartiDetaylar = (state) => {
  return updateObject(state, {
    cariKarti: {},
  });
};

const fetchCariKartiDetaylarSuccess = (state, action) => {
  return updateObject(state, {
    cariKarti: action.payload.cariKarti,
  });
};

const fetchCariKartiDetaylarFailure = (state) => {
  return updateObject(state, {
    cariKarti: null,
  });
};

const cariler = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_CARI_KARTLARI:
      return fetchCariKartlari(state);
    case actionTypes.FETCH_CARI_KARTLARI_SUCCESS:
      return fetchCariKartlariSuccess(state, action);
    case actionTypes.FETCH_CARI_KARTLARI_FAILURE:
      return fetchCariKartlariFailure(state);
    case actionTypes.FETCH_CARI_FATURALAR:
      return fetchCariFaturalar(state);
    case actionTypes.FETCH_CARI_FATURALAR_SUCCESS:
      return fetchCariFaturalarSuccess(state, action);
    case actionTypes.FETCH_CARI_FATURALAR_FAILURE:
      return fetchCariFaturalarFailure(state);
    case actionTypes.FETCH_CARI_MAKBUZLAR:
      return fetchCariMakbuzlar(state);
    case actionTypes.FETCH_CARI_MAKBUZLAR_SUCCESS:
      return fetchCariMakbuzlarSuccess(state, action);
    case actionTypes.FETCH_CARI_MAKBUZLAR_FAILURE:
      return fetchCariMakbuzlarFailure(state);
    case actionTypes.FETCH_CARI_DETAYLAR:
      return fetchCariKartiDetaylar(state);
    case actionTypes.FETCH_CARI_DETAYLAR_SUCCESS:
      return fetchCariKartiDetaylarSuccess(state, action);
    case actionTypes.FETCH_CARI_DETAYLAR_FAILURE:
      return fetchCariKartiDetaylarFailure(state);
    default:
      return state;
  }
};

export default cariler;
