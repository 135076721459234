import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { LOGIN, CHECK_AUTO_SIGNIN } from './actionTypes';
import { apiError, authSuccess, logout, updateUserDetails } from './actions';
import { login } from '../../api/auth/auth';
import responseFetchUserDetailsMapper from './utils/mapper';
import { isAuthExpired } from '../../helpers/utils';

//= =========== Dispatch Redux Actions directly =============*
function* loginUser({ payload: { musteriId, musteriAdi, musteriSifre, rememberMe } }) {
  try {
    const response = yield call(login, musteriId, musteriAdi, musteriSifre);
    const { data } = response;

    if (data && data.FirmaAdi !== 'Hatalı Giriş.') {
      const userDetails = responseFetchUserDetailsMapper(data);
      const user = { musteriId, musteriAdi, musteriSifre };
      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('userDetails', JSON.stringify(userDetails));
      localStorage.setItem('rememberMe', rememberMe);
      localStorage.setItem('ts', Date.now());
      yield put(authSuccess(user));
      yield put(updateUserDetails(userDetails));
    } else {
      yield put(apiError('Kimliğiniz doğrulanamadı. Bilgilerinizi lütfen tekrar kontrol ediniz.'));
    }
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function* checkAutoSignin() {
  try {
    const user = localStorage.getItem('user');
    const userDetails = localStorage.getItem('userDetails');
    const ts = localStorage.getItem('ts');
    const rememberMe = localStorage.getItem('rememberMe');
    if (user && user !== 'undefined') {
      if (rememberMe === 'false' && isAuthExpired(ts)) {
        yield put(logout());
      } else {
        const userObj = JSON.parse(user);
        const userDetailsObj = JSON.parse(userDetails);
        localStorage.setItem('ts', Date.now());
        yield put(authSuccess(userObj));
        yield put(updateUserDetails(userDetailsObj));
      }
    } else {
      yield put(logout());
    }
  } catch (error) {
    yield put(apiError(error.message));
  }
}

export function* watchUserLogin() {
  yield takeEvery(LOGIN, loginUser);
}

export function* watchAutoSignin() {
  yield takeEvery(CHECK_AUTO_SIGNIN, checkAutoSignin);
}

function* loginSaga() {
  yield all([fork(watchUserLogin), fork(watchAutoSignin)]);
}

export default loginSaga;
