//= ====================================
//            Cariler
//= ====================================
export const carilerServer = {
  CARI_ID: 'CariID',
  CARI_KODU: 'CariKodu',
  CARI_ADI: 'Aciklama',
  CARI_GRUBU: 'CariGrubu',
  TELEFON: 'Telefon1',
  EMAIL: 'Email1',
  SEHIR: 'Sehir',
  VERGI_DAIRESI: 'VergiDairesi',
  VERGI_NUMARASI: 'VergiNumarasi',
  VADE_GUN: 'AylikVade',
};

export const carilerClient = {
  CARI_KODU: 'cariKodu',
  CARI_ADI: 'cariAdi',
  CARI_ID: 'cariId',
  CARI_GRUBU: 'cariGrubu',
  TELEFON: 'telefon',
  EMAIL: 'email',
  SEHIR: 'sehir',
  VERGI_DAIRESI: 'vergiDairesi',
  VERGI_NUMARASI: 'vergiNumarasi',
  VADE_GUN: 'vadeGun',
};

export const carilerFaturaServer = {
  FATURA_ID: 'FaturaID',
  FATURA_NO: 'FaturaNo',
  FATURA_NOTU: 'FaturaNotu',
  FATURA_TARIHI: 'FaturaTarihi',
  YEKUN: 'Yekun',
};

export const carilerMakbuzServer = {
  ACIKLAMA: 'Aciklama',
  MAKBUZ_ID: 'MakbuzID',
  MAKBUZ_NO: 'MakbuzNo',
  MAKBUZ_TARIHI: 'MakbuzTarihi',
  TUTAR: 'Tutar',
};
