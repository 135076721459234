import { takeEvery, fork, put, all, call, select } from 'redux-saga/effects';
import * as actionTypes from './actionTypes';
import { raporlarApiError, raporlarFetchApiSuccess } from './actions';
import * as api from '../../../api/raporlar';
import * as mapper from './utils/mapper';
import * as layoutActions from '../../layout/actions';
import { formatDateFromMomentToServer } from '../../../helpers/utils';
import { responseFetchFaturaDetaylarMapper } from '../faturalar/utils/mapper';
import { responseFaturaDetaylarSchema } from '../faturalar/utils/schemas';
import { fetchFaturaDetaylar } from '../../../api/faturalar';

const getMusteriId = (state) => state.auth.user.musteriId;
const getMusteriAdi = (state) => state.auth.user.musteriAdi;
const getMusteriSifre = (state) => state.auth.user.musteriSifre;

function* fetchGunlukRaporWorker({ payload: { startDate, endDate } }) {
  yield put(layoutActions.showLoading());
  const musteriId = yield select(getMusteriId);
  const musteriAdi = yield select(getMusteriAdi);
  const musteriSifre = yield select(getMusteriSifre);

  try {
    const response = yield call(
      api.apiFetchGunlukRapor,
      musteriId,
      musteriAdi,
      musteriSifre,
      formatDateFromMomentToServer(startDate),
      formatDateFromMomentToServer(endDate)
    );
    const { data } = response;
    yield put(raporlarFetchApiSuccess('gunlukRapor', mapper.responseFetchGunlukRaporMapper(data)));
  } catch (error) {
    yield put(raporlarApiError(error.message));
  }
  yield put(layoutActions.hideLoading());
}

function* fetchGunlukRaporKasaWorker({ payload: { startDate, endDate, kasaId } }) {
  yield put(layoutActions.showLoading());
  const musteriId = yield select(getMusteriId);
  const musteriAdi = yield select(getMusteriAdi);
  const musteriSifre = yield select(getMusteriSifre);
  try {
    const response = yield call(
      api.apiFetchGunlukRaporKasa,
      musteriId,
      musteriAdi,
      musteriSifre,
      formatDateFromMomentToServer(startDate),
      formatDateFromMomentToServer(endDate),
      kasaId
    );
    const { data } = response;

    yield put(
      raporlarFetchApiSuccess('gunlukRaporKasa', mapper.responseFetchGunlukRaporKasaMapper(data))
    );
  } catch (error) {
    yield put(raporlarApiError(error.message));
  }
  yield put(layoutActions.hideLoading());
}

function* fetchCariRaporuWorker({ payload: { startDate, endDate, cariId } }) {
  const musteriId = yield select(getMusteriId);
  const musteriAdi = yield select(getMusteriAdi);
  const musteriSifre = yield select(getMusteriSifre);

  try {
    const response = yield call(
      api.apiFetchCariRaporu,
      musteriId,
      musteriAdi,
      musteriSifre,
      formatDateFromMomentToServer(startDate, ''),
      formatDateFromMomentToServer(endDate, ''),
      cariId
    );
    const { data } = response;

    yield put(raporlarFetchApiSuccess('cariRaporu', mapper.responseFetchCariRaporuMapper(data)));
  } catch (error) {
    yield put(raporlarApiError(error.message));
  }
}

function* fetchStokRaporuWorker({ payload: { startDate, endDate, stokId } }) {
  const musteriId = yield select(getMusteriId);
  const musteriAdi = yield select(getMusteriAdi);
  const musteriSifre = yield select(getMusteriSifre);

  try {
    const response = yield call(
      api.apiFetchStokRaporu,
      musteriId,
      musteriAdi,
      musteriSifre,
      formatDateFromMomentToServer(startDate, ''),
      formatDateFromMomentToServer(endDate, ''),
      stokId
    );
    const { data } = response;

    yield put(raporlarFetchApiSuccess('stokRaporu', mapper.responseFetchStokRaporuMapper(data)));
  } catch (error) {
    yield put(raporlarApiError(error.message));
  }
}

function* fetchKasaRaporuWorker({ payload: { startDate, endDate, kasaId } }) {
  const musteriId = yield select(getMusteriId);
  const musteriAdi = yield select(getMusteriAdi);
  const musteriSifre = yield select(getMusteriSifre);

  try {
    const response = yield call(
      api.apiFetchKasaRaporu,
      musteriId,
      musteriAdi,
      musteriSifre,
      formatDateFromMomentToServer(startDate, ''),
      formatDateFromMomentToServer(endDate, ''),
      kasaId
    );
    const { data } = response;

    yield put(raporlarFetchApiSuccess('kasaRaporu', mapper.responseFetchKasaRaporuMapper(data)));
  } catch (error) {
    yield put(raporlarApiError(error.message));
  }
}

function* fetchHizmetRaporuWorker({ payload: { startDate, endDate, hizmetId, alisSatis } }) {
  const musteriId = yield select(getMusteriId);
  const musteriAdi = yield select(getMusteriAdi);
  const musteriSifre = yield select(getMusteriSifre);

  try {
    const response = yield call(
      api.apiFetchHizmetRaporu,
      musteriId,
      musteriAdi,
      musteriSifre,
      formatDateFromMomentToServer(startDate, ''),
      formatDateFromMomentToServer(endDate, ''),
      hizmetId,
      alisSatis
    );
    const { data } = response;

    yield put(
      raporlarFetchApiSuccess('hizmetRaporu', mapper.responseFetchHizmetRaporuMapper(data))
    );
  } catch (error) {
    yield put(raporlarApiError(error.message));
  }
}

function* fetchFaturaRaporuWorker({ payload: { startDate, endDate, cariId, stokId, faturaTuru } }) {
  const musteriId = yield select(getMusteriId);
  const musteriAdi = yield select(getMusteriAdi);
  const musteriSifre = yield select(getMusteriSifre);

  try {
    const response = yield call(
      api.apiFetchFaturaRaporu,
      musteriId,
      musteriAdi,
      musteriSifre,
      formatDateFromMomentToServer(startDate, ''),
      formatDateFromMomentToServer(endDate, ''),
      cariId,
      stokId,
      faturaTuru
    );
    const { data } = response;

    yield put(
      raporlarFetchApiSuccess('faturaRaporu', mapper.responseFetchFaturaRaporuMapper(data))
    );
  } catch (error) {
    yield put(raporlarApiError(error.message));
  }
}

//= ====================================
//            Mobile Raporlar
//= ====================================
function* fetchCariRaporuForMobileWorker({ payload }) {
  try {
    const response = yield call(
      api.apiFetchCariRaporu,
      payload.musteriId,
      payload.musteriAdi,
      payload.musteriSifre,
      payload.baslangicTarihi,
      payload.bitisTarihi,
      payload.cariId
    );
    const { data } = response;

    yield put(
      raporlarFetchApiSuccess('pdfReportForMobile', mapper.responseFetchCariRaporuMapper(data))
    );
  } catch (error) {
    yield put(raporlarApiError(error.message));
  }
}

function* fetchStokRaporuForMobileWorker({ payload }) {
  try {
    const response = yield call(
      api.apiFetchStokRaporu,
      payload.musteriId,
      payload.musteriAdi,
      payload.musteriSifre,
      payload.baslangicTarihi,
      payload.bitisTarihi,
      payload.stokId
    );
    const { data } = response;

    yield put(
      raporlarFetchApiSuccess('pdfReportForMobile', mapper.responseFetchStokRaporuMapper(data))
    );
  } catch (error) {
    yield put(raporlarApiError(error.message));
  }
}

function* fetchHizmetRaporuForMobileWorker({ payload }) {
  try {
    const response = yield call(
      api.apiFetchHizmetRaporu,
      payload.musteriId,
      payload.musteriAdi,
      payload.musteriSifre,
      payload.baslangicTarihi,
      payload.bitisTarihi,
      payload.hizmetId,
      payload.alisSatis
    );
    const { data } = response;

    yield put(
      raporlarFetchApiSuccess('pdfReportForMobile', mapper.responseFetchHizmetRaporuMapper(data))
    );
  } catch (error) {
    yield put(raporlarApiError(error.message));
  }
}

function* fetchFaturaRaporuForMobileWorker({ payload }) {
  try {
    const response = yield call(
      api.apiFetchFaturaRaporu,
      payload.musteriId,
      payload.musteriAdi,
      payload.musteriSifre,
      payload.baslangicTarihi,
      payload.bitisTarihi,
      payload.cariId,
      payload.stokId,
      payload.faturaTuru
    );
    const { data } = response;

    yield put(
      raporlarFetchApiSuccess('pdfReportForMobile', mapper.responseFetchFaturaRaporuMapper(data))
    );
  } catch (error) {
    yield put(raporlarApiError(error.message));
  }
}

function* fetchKasaRaporuForMobileWorker({ payload }) {
  try {
    const response = yield call(
      api.apiFetchKasaRaporu,
      payload.musteriId,
      payload.musteriAdi,
      payload.musteriSifre,
      payload.baslangicTarihi,
      payload.bitisTarihi,
      payload.kasaId
    );
    const { data } = response;

    yield put(
      raporlarFetchApiSuccess('pdfReportForMobile', mapper.responseFetchKasaRaporuMapper(data))
    );
  } catch (error) {
    yield put(raporlarApiError(error.message));
  }
}

function* fetchFaturaDetaylarWorker({ payload }) {
  yield put(layoutActions.showLoading());
  try {
    const err = {
      message: 'Bilinmeyen bir hata oluştu',
    };
    const response = yield call(
      fetchFaturaDetaylar,
      payload.musteriId,
      payload.musteriAdi,
      payload.musteriSifre,
      payload.faturaId
    );
    const { data } = response;
    if (!responseFaturaDetaylarSchema.isValidSync(data)) {
      throw err;
    }
    yield put(
      raporlarFetchApiSuccess(
        'pdfInvoiceForMobile',
        responseFetchFaturaDetaylarMapper(data, payload.faturaId)
      )
    );
  } catch (error) {
    yield put(layoutActions.showError(error.message));
  }
  yield put(layoutActions.hideLoading());
}

export function* watchRaporlar() {
  yield takeEvery(actionTypes.FETCH_GUNLUK_RAPOR, fetchGunlukRaporWorker);
  yield takeEvery(actionTypes.FETCH_GUNLUK_RAPOR_KASA, fetchGunlukRaporKasaWorker);
  yield takeEvery(actionTypes.FETCH_CARI_RAPORU, fetchCariRaporuWorker);
  yield takeEvery(actionTypes.FETCH_STOK_RAPORU, fetchStokRaporuWorker);
  yield takeEvery(actionTypes.FETCH_KASA_RAPORU, fetchKasaRaporuWorker);
  yield takeEvery(actionTypes.FETCH_HIZMET_RAPORU, fetchHizmetRaporuWorker);
  yield takeEvery(actionTypes.FETCH_FATURA_RAPORU, fetchFaturaRaporuWorker);
}

export function* watchRaporlarForMobile() {
  yield takeEvery(actionTypes.FETCH_CARI_RAPORU_FOR_MOBILE, fetchCariRaporuForMobileWorker);
  yield takeEvery(actionTypes.FETCH_STOK_RAPORU_FOR_MOBILE, fetchStokRaporuForMobileWorker);
  yield takeEvery(actionTypes.FETCH_HIZMET_RAPORU_FOR_MOBILE, fetchHizmetRaporuForMobileWorker);
  yield takeEvery(actionTypes.FETCH_FATURA_RAPORU_FOR_MOBILE, fetchFaturaRaporuForMobileWorker);
  yield takeEvery(actionTypes.FETCH_KASA_RAPORU_FOR_MOBILE, fetchKasaRaporuForMobileWorker);
  yield takeEvery(actionTypes.FETCH_FATURA_INVOICE_FOR_MOBILE, fetchFaturaDetaylarWorker);
}

function* raporlarSaga() {
  yield all([fork(watchRaporlar), fork(watchRaporlarForMobile)]);
}

export default raporlarSaga;
