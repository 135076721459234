import React, { useState } from 'react';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { CButton } from '@coreui/react';
import PropTypes from 'prop-types';
import accounting from 'accounting';
import TikirReport from '../TikirReport';
import { formatMoney, formatMoneyFromMoney } from '../../../helpers/utils';

const propTypes = {
  title: PropTypes.string.isRequired,
  firm: PropTypes.string.isRequired,
  headers: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  fileName: PropTypes.string.isRequired,
  showFooter: PropTypes.bool,
};
const defaultProps = {
  showFooter: false,
};
const TikirReportWrapperMobile = (props) => {
  const { title, firm, headers, data, fileName, showFooter } = props;
  const [isClicked, setClicked] = useState(false);
  const [isOpenedAlready, setOpenedAlready] = useState(false);

  const footer = {};
  headers.forEach((header) => {
    if (!header.number) {
      footer[header.key] = header.footerText ?? '';
    } else {
      footer[header.key] = 0;
    }
  });

  const transformedData = data.reduce((memo, el) => {
    const clonedElement = { ...el };
    headers.forEach((header) => {
      if (header.number) {
        footer[header.key] += parseFloat(
          clonedElement[header.key].replaceAll('.', '').replace(',', '.')
        );
        clonedElement[header.key] = formatMoneyFromMoney(clonedElement[header.key]);
      }
    });
    memo.push(clonedElement);
    return memo;
  }, []);

  headers.forEach((header) => {
    if (header.number) {
      footer[header.key] = header.footerText ?? formatMoney(footer[header.key]);
    }
  });

  const downloadFile = (filename, url) => {
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    link.click();
  };

  const openPdfInNewTab = (filename, url, blob) => {
    setOpenedAlready(true);
    // IE
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, filename);
      return;
    }

    if (
      navigator.userAgent.indexOf('Chrome') !== -1 ||
      navigator.userAgent.indexOf('Firefox') !== -1
    ) {
      // Chrome, FF
      const w = window.open(url, '_self');
      if (w) {
        w.focus();
      }
    } else {
      // Safari & Opera iOS
      window.location.href = url;
    }
  };

  return (
    <PDFDownloadLink
      document={
        <TikirReport
          title={title}
          firm={firm}
          headers={headers}
          data={transformedData}
          footer={showFooter && footer}
        />
      }
      fileName={fileName}
    >
      {({ blob, url, loading, error }) => {
        if (!loading && url && blob && !isOpenedAlready) {
          // downloadFile(fileName, url);
          openPdfInNewTab(fileName, url, blob);
        }
      }}
    </PDFDownloadLink>
  );
};

TikirReportWrapperMobile.propTypes = propTypes;
TikirReportWrapperMobile.defaultProps = defaultProps;
export default TikirReportWrapperMobile;
