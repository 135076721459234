import { takeEvery, fork, put, all, call, select } from 'redux-saga/effects';
import * as actionTypes from './actionTypes';
import { othersApiError, othersFetchApiSuccess } from './actions';
import * as api from '../../../api/others';
import { fetchStokKartlari } from '../../../api/stoklar';
import { fetchHizmetKartlari } from '../../../api/hizmetler';
import * as mapper from './utils/mapper';

const getMusteriId = (state) => state.auth.user.musteriId;
const getMusteriAdi = (state) => state.auth.user.musteriAdi;
const getMusteriSifre = (state) => state.auth.user.musteriSifre;

function* fetchSehirlerWorker() {
  const musteriId = yield select(getMusteriId);
  const musteriAdi = yield select(getMusteriAdi);
  const musteriSifre = yield select(getMusteriSifre);

  try {
    const response = yield call(api.apiFetchSehirler, musteriId, musteriAdi, musteriSifre);
    const { data } = response;

    yield put(othersFetchApiSuccess('sehirler', mapper.sehirlerMapper(data)));
  } catch (error) {
    yield put(othersApiError(error.message));
  }
}

function* fetchParaBirimleriWorker() {
  const musteriId = yield select(getMusteriId);
  const musteriAdi = yield select(getMusteriAdi);
  const musteriSifre = yield select(getMusteriSifre);

  try {
    const response = yield call(api.apiFetchParaBirimleri, musteriId, musteriAdi, musteriSifre);
    const { data } = response;

    yield put(othersFetchApiSuccess('paraBirimleri', mapper.paraBirimleriMapper(data)));
  } catch (error) {
    yield put(othersApiError(error.message));
  }
}

function* fetchCariListesiWorker({ payload: { arama, kayitBaslangic, kayitBitis } }) {
  const musteriId = yield select(getMusteriId);
  const musteriAdi = yield select(getMusteriAdi);
  const musteriSifre = yield select(getMusteriSifre);

  try {
    const response = yield call(
      api.apiFetchCariListesi,
      musteriId,
      musteriAdi,
      musteriSifre,
      arama,
      kayitBaslangic,
      kayitBitis
    );
    const { data } = response;

    yield put(othersFetchApiSuccess('cariListesi', mapper.cariListesiMapper(data)));
  } catch (error) {
    yield put(othersApiError(error.message));
  }
}

function* fetchStokListesiWorker({ payload: { siralama, arama, kayitBaslangic, kayitBitis } }) {
  const musteriId = yield select(getMusteriId);
  const musteriAdi = yield select(getMusteriAdi);
  const musteriSifre = yield select(getMusteriSifre);

  try {
    const response = yield call(
      fetchStokKartlari,
      musteriId,
      musteriAdi,
      musteriSifre,
      siralama,
      arama,
      kayitBaslangic,
      kayitBitis
    );
    const { data } = response;

    yield put(othersFetchApiSuccess('stokListesi', mapper.stokListesiMapper(data)));
  } catch (error) {
    yield put(othersApiError(error.message));
  }
}

function* fetchHizmetListesiWorker({ payload: { siralama, arama, kayitBaslangic, kayitBitis } }) {
  const musteriId = yield select(getMusteriId);
  const musteriAdi = yield select(getMusteriAdi);
  const musteriSifre = yield select(getMusteriSifre);

  try {
    const response = yield call(
      fetchHizmetKartlari,
      musteriId,
      musteriAdi,
      musteriSifre,
      siralama,
      arama,
      kayitBaslangic,
      kayitBitis
    );
    const { data } = response;

    yield put(othersFetchApiSuccess('hizmetListesi', mapper.hizmetListesiMapper(data)));
  } catch (error) {
    yield put(othersApiError(error.message));
  }
}

function* fetchDepoListesiWorker() {
  const musteriId = yield select(getMusteriId);
  const musteriAdi = yield select(getMusteriAdi);
  const musteriSifre = yield select(getMusteriSifre);

  try {
    const response = yield call(api.apiFetchDepoListesi, musteriId, musteriAdi, musteriSifre);
    const { data } = response;

    yield put(othersFetchApiSuccess('depoListesi', mapper.depoListesiMapper(data)));
  } catch (error) {
    yield put(othersApiError(error.message));
  }
}

function* fetchKasaListesiWorker() {
  const musteriId = yield select(getMusteriId);
  const musteriAdi = yield select(getMusteriAdi);
  const musteriSifre = yield select(getMusteriSifre);

  try {
    const response = yield call(api.apiFetchKasaListesi, musteriId, musteriAdi, musteriSifre);
    const { data } = response;

    yield put(othersFetchApiSuccess('kasaListesi', mapper.kasaListesiMapper(data)));
  } catch (error) {
    yield put(othersApiError(error.message));
  }
}

export function* watchOthers() {
  yield takeEvery(actionTypes.FETCH_SEHIRLER, fetchSehirlerWorker);
  yield takeEvery(actionTypes.FETCH_PARA_BIRIMLERI, fetchParaBirimleriWorker);
  yield takeEvery(actionTypes.FETCH_CARI_LISTESI, fetchCariListesiWorker);
  yield takeEvery(actionTypes.FETCH_STOK_LISTESI, fetchStokListesiWorker);
  yield takeEvery(actionTypes.FETCH_HIZMET_LISTESI, fetchHizmetListesiWorker);
  yield takeEvery(actionTypes.FETCH_DEPO_LISTESI, fetchDepoListesiWorker);
  yield takeEvery(actionTypes.FETCH_KASA_LISTESI, fetchKasaListesiWorker);
}

function* othersSaga() {
  yield all([fork(watchOthers)]);
}

export default othersSaga;
