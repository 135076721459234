//= ====================================
//            Makbuzlar
//= ====================================
export const makbuzlarServer = {
  MAKBUZ_ID: 'MakbuzID',
  MAKBUZ_NO: 'MakbuzNo',
  CARI_ADI: 'CariAdi',
  ACIKLAMA: 'Aciklama',
  TARIH: 'Tarih',
  VEZNE_ID: 'VezneID',
  MAKBUZ_TURU: 'MakbuzTuru',
  CARI_ID: 'CariID',
  TUTAR: 'Tutar',
  UNKNOWN_1: 'Unknown1',
  UNKNOWN_2: 'Unknown2',
  UNKNOWN_3: 'Unknown3',
  UNKNOWN_4: 'Unknown4',
};

export const makbuzlarClient = {
  MAKBUZ_ID: 'makbuzId',
  MAKBUZ_NO: 'makbuzNo',
  CARI_ADI: 'cariAdi',
  ACIKLAMA: 'aciklama',
  TARIH: 'tarih',
  VEZNE_ID: 'vezneId',
  VEZNE_ADI: 'vezneAdi',
  MAKBUZ_TURU: 'makbuzTuru',
  CARI_ID: 'cariId',
  TUTAR: 'tutar',
  UNKNOWN_1: 'unknown1',
  UNKNOWN_2: 'unknown2',
  UNKNOWN_3: 'unknown3',
  UNKNOWN_4: 'unknown4',
};

export const makbuzDetaylarServer = {
  MAKBUZ_DETAY_ID: 'MakbuzDetayID',
  VEZNE_ID: 'KasaID',
  TUTAR: 'Tutar',
  PARA_BIRIMI: 'ParaBirimi',
  SIRA_NO: 'SiraNo',
};

export const makbuzDetaylarClient = {
  MAKBUZ_DETAY_ID: 'makbuzDetayId',
  VEZNE_ID: 'vezneId',
  VEZNE_ADI: 'vezneAdi',
  TUTAR: 'tutar',
  PARA_BIRIMI_ID: 'paraBirimiId',
  PARA_BIRIMI: 'paraBirimi',
  SIRA_NO: 'siraNo',
};
