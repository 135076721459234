//= ====================================
//            Depolar
//= ====================================
export const depolarServer = {
  DEPO_ID: 'Kod',
  DEPO_ADI: 'Aciklama',
  KISA_ACIKLAMA: 'KisaAciklama',
};

export const depolarClient = {
  DEPO_ID: 'kasaId',
  DEPO_ADI: 'kasaAdi',
  KISA_ACIKLAMA: 'kisaAciklama',
};

//= ====================================
//            Kasalar
//= ====================================
export const kasalarServer = {
  KASA_ID: 'KasaID',
  KASA_KODU: 'KasaKodu',
  KASA_ADI: 'Aciklama',
};

export const kasalarClient = {
  KASA_ID: 'kasaId',
  KASA_KODU: 'kasaKodu',
  KASA_ADI: 'kasaAdi',
};
