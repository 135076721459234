//= ====================================
//            Hizmetler
//= ====================================
export const hizmetlerServer = {
  HIZMET_ID: 'HizmetID',
  HIZMET_KODU: 'HizmetKodu',
  HIZMET_ADI: 'Aciklama',
  BIRIMI: 'Birimi',
  SATIS_FIYATI: 'SatisFiyati',
  BARKOD: 'Barkod',
  HIZMET_GRUBU: 'HizmetGrubu',
  HIZMET_GRUBU_ID: 'HizmetGrubuID',
  KDV: 'Kdv',
  ALIS_FIYATI: 'AlisFiyati',
  PARA_BIRIMI: 'ParaBirimi',
  PARA_BIRIMI_ADI: 'ParaBirimiAdi',
};

export const hizmetlerClient = {
  HIZMET_ID: 'hizmetId',
  HIZMET_KODU: 'hizmetKodu',
  HIZMET_ADI: 'hizmetAdi',
  BIRIMI: 'birimi',
  SATIS_FIYATI: 'satisFiyati',
  BARKOD: 'barkod',
  HIZMET_GRUBU: 'hizmetGrubu',
  HIZMET_GRUBU_ID: 'hizmetGrubuId',
  KDV: 'kdv',
  ALIS_FIYATI: 'alisFiyati',
  PARA_BIRIMI: 'paraBirimi',
  PARA_BIRIMI_ADI: 'paraBirimiAdi',
};
