export const cariler = [
  '24 24',
  `
  <title>Aymet Logo Black</title>
  <path fill="rgba(255, 255, 255, 0.5)" d="M12.43 11C12.28 10.84 10 7 7 7s-4.68 3.18-5 4v2h9.57c.15.16 2.43 4 5.43 4s4.68-3.18 5-4v-2h-9.57M7 9c1.17 0 2.18.85 3 2H4.31c.47-.83 1.23-2 2.69-2m10 6c-1.17 0-2.18-.85-3-2h5.69c-.47.83-1.23 2-2.69 2z" />
`,
];

export const stoklar = [
  '24 24',
  `
  <path fill="rgba(255, 255, 255, 0.5)" d="M20 4a2 2 0 012 2v12a2 2 0 01-2 2H4a2 2 0 01-2-2V6a2 2 0 012-2h16m-9 9H9v2h2v-2m8 0h-6v2h6v-2M7 9H5v2h2V9m12 0H9v2h10V9z" />
`,
];

export const hizmetler = [
  '24 24',
  `
  <path fill="rgba(255, 255, 255, 0.5)" d="M20 18v-4h-3v1h-2v-1H9v1H7v-1H4v4h16M6.33 8l-1.74 4H7v-1h2v1h6v-1h2v1h2.41l-1.74-4H6.33M9 5v1h6V5H9m12.84 7.61c.1.22.16.48.16.8V18c0 .53-.21 1-.6 1.41-.4.4-.85.59-1.4.59H4c-.55 0-1-.19-1.4-.59C2.21 19 2 18.53 2 18v-4.59c0-.32.06-.58.16-.8L4.5 7.22C4.84 6.41 5.45 6 6.33 6H7V5c0-.55.18-1 .57-1.41C7.96 3.2 8.44 3 9 3h6c.56 0 1.04.2 1.43.59.39.41.57.86.57 1.41v1h.67c.88 0 1.49.41 1.83 1.22l2.34 5.39z" />
`,
];

export const tahsilatlar = [
  '24 24',
  `
  <path fill="rgba(255, 255, 255, 0.5)" d="M20 4H4a2 2 0 00-2 2v12a2 2 0 002 2h9.09a5.47 5.47 0 01-.09-1 6 6 0 016-6 5.88 5.88 0 013 .81V6a2 2 0 00-2-2m0 7H4V8h16m0 7v3h3v2h-3v3h-2v-3h-3v-2h3v-3z" />
`,
];

export const tediyeler = [
  '24 24',
  `
  <path fill="rgba(255, 255, 255, 0.5)" d="M20 4H4a2 2 0 00-2 2v12a2 2 0 002 2h9.09c-.06-.33-.09-.66-.09-1 0-3.31 2.69-6 6-6 1.06 0 2.09.28 3 .81V6c0-1.11-.89-2-2-2m0 7H4V8h16m3 10v2h-8v-2h8z" />
`,
];

export const faturalar = [
  '24 24',
  `
  <path fill="rgba(255, 255, 255, 0.5)" d="M3 22l1.5-1.5L6 22l1.5-1.5L9 22l1.5-1.5L12 22l1.5-1.5L15 22l1.5-1.5L18 22l1.5-1.5L21 22V2l-1.5 1.5L18 2l-1.5 1.5L15 2l-1.5 1.5L12 2l-1.5 1.5L9 2 7.5 3.5 6 2 4.5 3.5 3 2m15 7H6V7h12m0 6H6v-2h12m0 6H6v-2h12v2z" />
`,
];

export const gunlukRaporlar = [
  '24 24',
  `
  <path fill="rgba(255, 255, 255, 0.5)" d="M21 16V4H3v12h18m0-14a2 2 0 012 2v12a2 2 0 01-2 2h-7v2h2v2H8v-2h2v-2H3a2 2 0 01-2-2V4c0-1.11.89-2 2-2h18M5 6h9v5H5V6m10 0h4v2h-4V6m4 3v5h-4V9h4M5 12h4v2H5v-2m5 0h4v2h-4v-2z" />
`,
];

export const detayliRaporlar = [
  '24 24',
  `
  <path fill="rgba(255, 255, 255, 0.5)" d="M21 14V4H3v10h18m0-12a2 2 0 012 2v12a2 2 0 01-2 2h-7l2 3v1H8v-1l2-3H3a2 2 0 01-2-2V4c0-1.11.89-2 2-2h18M4 5h11v5H4V5m12 0h4v2h-4V5m4 3v5h-4V8h4M4 11h5v2H4v-2m6 0h5v2h-5v-2z" />
`,
];

export const alislar = [
  '24 24',
  `
  <path fill="rgba(255, 255, 255, 1)" d="M17 13h-4v4h-2v-4H7v-2h4V7h2v4h4m2-8H5c-1.11 0-2 .89-2 2v14a2 2 0 002 2h14a2 2 0 002-2V5a2 2 0 00-2-2z" />
`,
];

export const satislar = [
  '24 24',
  `
  <path fill="rgba(255, 255, 255, 1)" d="M17 13H7v-2h10m2-8H5c-1.11 0-2 .89-2 2v14a2 2 0 002 2h14a2 2 0 002-2V5a2 2 0 00-2-2z" />
`,
];

export const makbuzlar = [
  '24 24',
  `
  <path fill="rgba(255, 255, 255, 1)" d="M3 22l1.5-1.5L6 22l1.5-1.5L9 22l1.5-1.5L12 22l1.5-1.5L15 22l1.5-1.5L18 22l1.5-1.5L21 22V2l-1.5 1.5L18 2l-1.5 1.5L15 2l-1.5 1.5L12 2l-1.5 1.5L9 2 7.5 3.5 6 2 4.5 3.5 3 2m15 7H6V7h12m0 6H6v-2h12m0 6H6v-2h12v2z" />
`,
];

export const kasa = [
  '24 24',
  `
  <path fill="rgba(255, 255, 255, 1)" d="M10 2h4a2 2 0 012 2v2h4a2 2 0 012 2v11a2 2 0 01-2 2H4a2 2 0 01-2-2V8c0-1.11.89-2 2-2h4V4c0-1.11.89-2 2-2m4 4V4h-4v2h4z" />
`,
];
