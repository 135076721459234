import moment from 'moment';
import accounting from 'accounting';
import { dateFormats } from '../constants';
import { AUTH_EXPIRATION_IN_SECONDS } from '../constants/auth';

export const getBadge = (bakiyeStr) => {
  if (bakiyeStr) {
    const bakiye = parseFloat(bakiyeStr.replace(',', '.'));
    if (bakiye > 0.0) {
      return 'success';
    }
    if (bakiye === 0.0) {
      return 'secondary';
    }
    return 'danger';
  }
  return '';
};

export const formatMoney = (money, defaultValue) => {
  try {
    return accounting.formatMoney(money, '', 2, '.', ',');
  } catch (e) {
    return defaultValue || '0,00';
  }
};

export const formatMoneyFromMoney = (money, defaultValue) => {
  try {
    return accounting.formatMoney(money.replaceAll('.', '').replace(',', '.'), '', 2, '.', ',');
  } catch (e) {
    return defaultValue || '0,00';
  }
};

export const formatNumberFromMoney = (money, defaultValue) => {
  try {
    return parseFloat(money.replaceAll('.', '').replace(',', '.'));
  } catch (e) {
    return defaultValue || 0.0;
  }
};

export const checkNumberIsAccountingFormat = (number) => {
  // 346.000 -> accounting
  // 35.505.444 -> accounting
  // 466.544,24 -> accounting
  // 55,355 -> accounting
  // 55.35 -> not accounting
  // 346000 -> not accounting
  // !!! the problem is that this method count 55.355 as accounting.
  if (number.toString().indexOf(',') > -1) {
    return true;
  }
  if (number.toString().split('.').length - 1 > 1) {
    return true;
  }
  if (
    number.toString().split('.').length - 1 === 1 &&
    number.toString().split('.')[1].length === 3
  ) {
    return true;
  }
  return false;
};

export const parseAccountingSafely = (number) => {
  if (checkNumberIsAccountingFormat(number)) {
    return number ? parseFloat(number.replaceAll('.', '').replace(',', '.')) : 0.0;
  }
  return number ? parseFloat(number) : 0.0;
};

export const getValueOfPairSafely = (pair, defaultValue) => {
  if (pair) {
    if (pair.value) {
      return pair.value;
    }
    return defaultValue;
  }
  return defaultValue;
};

export const formatDateFromClientToServer = (date, defaultDate) => {
  try {
    return moment(date, dateFormats.DATE_FORMAT_CLIENT).format(dateFormats.DATE_FORMAT_SERVER);
  } catch (e) {
    return defaultDate || moment().format(dateFormats.DATE_FORMAT_SERVER);
  }
};

export const formatDateFromMomentToServer = (momentDate, defaultDate) => {
  try {
    return momentDate.format(dateFormats.DATE_FORMAT_SERVER);
  } catch (e) {
    return defaultDate || moment().format(dateFormats.DATE_FORMAT_SERVER);
  }
};

export const formatDateFromMomentToFile = (momentDate, defaultDate) => {
  try {
    return momentDate.format(dateFormats.DATE_FORMAT_FILE);
  } catch (e) {
    return defaultDate || moment().format(dateFormats.DATE_FORMAT_FILE);
  }
};

export const safelyParseJSON = (json) => {
  try {
    return JSON.parse(json);
  } catch (e) {
    return null;
  }
};

export const decodeBase64 = (encoded) => decodeURIComponent(escape(window.atob(encoded)));

export const isAuthExpired = (timestamp) => {
  if (Date.now() / 1000 - timestamp / 1000 >= AUTH_EXPIRATION_IN_SECONDS) {
    return true;
  }
  return false;
};
