//= ====================================
//            Auth
//= ====================================
export const authServer = {
  FIRMA_ADI: 'FirmaAdi',
  KULLANICI_ADI: 'KullaniciAdi',
  KULLANICI_SIFRE: 'KullaniciSifre',
  MUSTERI_ADI: 'MusteriAdi',
};

export const authClient = {
  FIRMA_ADI: 'firmaAdi',
  KULLANICI_ADI: 'kullaniciAdi',
  KULLANICI_SIFRE: 'kullaniciSifre',
  MUSTERI_ADI: 'musteriAdi',
};

export const AUTH_EXPIRATION_IN_SECONDS = 1200;
