import * as actionTypes from './actionTypes';

//= ====================================
//            Makbuz Kartlari
//= ====================================
export const fetchMakbuzKartlari = (siralama, arama, kayitBaslangic, kayitBitis, makbuzTuru) => ({
  type: actionTypes.FETCH_MAKBUZ_KARTLARI,
  payload: { siralama, arama, kayitBaslangic, kayitBitis, makbuzTuru },
});

export const fetchMakbuzKartlariSuccess = (makbuzKartlari) => ({
  type: actionTypes.FETCH_MAKBUZ_KARTLARI_SUCCESS,
  payload: {
    makbuzKartlari,
  },
});

//= ====================================
//            Add Makbuz
//= ====================================
export const addMakbuzKarti = (payload, history) => ({
  type: actionTypes.ADD_MAKBUZ_KARTI,
  payload,
  history,
});

//= ====================================
//            Makbuz Karti Detaylar
//= ====================================
export const fetchMakbuzDetaylar = (makbuzId) => ({
  type: actionTypes.FETCH_MAKBUZ_DETAYLAR,
  payload: { makbuzId },
});

export const fetchMakbuzDetaylarSuccess = (makbuzKarti) => ({
  type: actionTypes.FETCH_MAKBUZ_DETAYLAR_SUCCESS,
  payload: {
    makbuzKarti,
  },
});

export const fetchMakbuzDetaylarFailure = (error) => ({
  type: actionTypes.FETCH_MAKBUZ_DETAYLAR_FAILURE,
  payload: { error },
});

//= ====================================
//            Makbuz Karti Delete
//= ====================================
export const deleteMakbuzKarti = (makbuzId, history) => ({
  type: actionTypes.DELETE_MAKBUZ_KARTI,
  payload: { makbuzId },
  history,
});

//= ====================================
//            Makbuz Karti Row Delete
//= ====================================
export const deleteMakbuzRow = (payload) => ({
  type: actionTypes.DELETE_MAKBUZ_ROW,
  payload,
});

//= ====================================
//            Update Makbuz Karti
//= ====================================
export const updateMakbuzKarti = (payload, history, previousMakbuzNo) => ({
  type: actionTypes.UPDATE_MAKBUZ_KARTI,
  payload,
  history,
  previousMakbuzNo,
});

export const updateMakbuzKartiAfterRowUpdating = (payload, makbuzId) => ({
  type: actionTypes.UPDATE_MAKBUZ_KARTI_AFTER_ROWS_CHANGE,
  payload,
  makbuzId,
});

//= ====================================
//            Select Stok Karti For Makbuz Row
//= ====================================
export const selectStokKartiOnMakbuzRow = (selectedStokKarti) => ({
  type: actionTypes.SELECT_MAKBUZ_ROW_STOK,
  payload: { selectedStokKarti },
});

export const fetchStokKartiOnMakbuzRow = (stokId) => ({
  type: actionTypes.FETCH_MAKBUZ_ROW_STOK,
  payload: { stokId },
});

//= ====================================
//            Select Hizmet Karti For Makbuz Row
//= ====================================
export const selectHizmetKartiOnMakbuzRow = (selectedHizmetKarti) => ({
  type: actionTypes.SELECT_MAKBUZ_ROW_HIZMET,
  payload: { selectedHizmetKarti },
});

export const fetchHizmetKartiOnMakbuzRow = (hizmetId) => ({
  type: actionTypes.FETCH_MAKBUZ_ROW_HIZMET,
  payload: { hizmetId },
});

//= ====================================
//            Update Makbuz Karti
//= ====================================
export const updateMakbuzRow = (payload, selectedRow) => ({
  type: actionTypes.UPDATE_MAKBUZ_ROW,
  payload,
  selectedRow,
});

export const addMakbuzRow = (payload) => ({
  type: actionTypes.ADD_MAKBUZ_ROW,
  payload,
});
