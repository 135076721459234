import { takeEvery, fork, put, all, call, select } from 'redux-saga/effects';
import * as actionTypes from './actionTypes';
import * as actions from './actions';
import * as layoutActions from '../../layout/actions';
import * as api from '../../../api/faturalar';
import * as mapper from './utils/mapper';
import * as schemas from './utils/schemas';
import * as otherSchemas from '../others/utils/schemas';
import * as constants from '../../../constants/faturalar';
import {
  calculateKdvTutar,
  calculateOtvTutar,
  calculateToplamTutar,
  calculateTutar,
} from '../../../helpers/faturalar';

const getMusteriId = (state) => state.auth.user.musteriId;
const getMusteriAdi = (state) => state.auth.user.musteriAdi;
const getMusteriSifre = (state) => state.auth.user.musteriSifre;
const getFaturaKarti = (state) => state.faturalar.faturaKarti;

function* fetchFaturaKartlariWorker({
  payload: { siralama, arama, kayitBaslangic, kayitBitis, faturaTuru },
}) {
  const musteriId = yield select(getMusteriId);
  const musteriAdi = yield select(getMusteriAdi);
  const musteriSifre = yield select(getMusteriSifre);

  try {
    const response = yield call(
      api.fetchFaturaKartlari,
      musteriId,
      musteriAdi,
      musteriSifre,
      siralama,
      arama,
      kayitBaslangic,
      kayitBitis,
      faturaTuru
    );
    const { data } = response;
    yield put(actions.fetchFaturaKartlariSuccess(mapper.responseFetchFaturaKartlariMapper(data)));
  } catch (error) {
    yield put(layoutActions.showError(error.message));
  }
}

function* addFaturaKartiWorker({ payload, history }) {
  yield put(layoutActions.showLoading());
  const musteriId = yield select(getMusteriId);
  const musteriAdi = yield select(getMusteriAdi);
  const musteriSifre = yield select(getMusteriSifre);

  const mappedPayload = mapper.requestAddFaturaKartiMapper(
    musteriId,
    musteriAdi,
    musteriSifre,
    payload
  );

  try {
    const err = {
      message: 'Bilinmeyen bir hata oluştu!',
    };
    // First check fatura no is present or not.
    {
      const responseCheck = yield call(
        api.checkFaturaNo,
        musteriId,
        musteriAdi,
        musteriSifre,
        mappedPayload[constants.faturalarServer.FATURA_NO]
      );

      // Check response schema
      const { data } = responseCheck;
      if (!otherSchemas.responseVarYokSchema.isValidSync(data)) {
        throw err;
      }

      if (data[0].geriDonenDeger === 'var') {
        err.message = 'Bu fatura no kayıtlı. Lütfen başka fatura no giriniz.';
        throw err;
      }
    }

    // Send request to add
    const response = yield call(api.addFaturaKarti, mappedPayload);

    // Check response schema
    const { data } = response;
    if (!schemas.responseFaturaEkleSchema.isValidSync(data)) {
      throw err;
    }

    yield put(layoutActions.showMessage('Başarıyla eklendi'));
    history.push(data[0].Veriler);
  } catch (error) {
    yield put(layoutActions.showError(error.message));
  }
}

function* fetchFaturaDetaylarWorker({ payload: { faturaId } }) {
  yield put(layoutActions.showLoading());
  const musteriId = yield select(getMusteriId);
  const musteriAdi = yield select(getMusteriAdi);
  const musteriSifre = yield select(getMusteriSifre);

  try {
    const err = {
      message: 'Bilinmeyen bir hata oluştu',
    };
    const response = yield call(
      api.fetchFaturaDetaylar,
      musteriId,
      musteriAdi,
      musteriSifre,
      faturaId
    );
    const { data } = response;
    if (!schemas.responseFaturaDetaylarSchema.isValidSync(data)) {
      throw err;
    }
    yield put(
      actions.fetchFaturaDetaylarSuccess(mapper.responseFetchFaturaDetaylarMapper(data, faturaId))
    );
  } catch (error) {
    yield put(layoutActions.showError(error.message));
  }
  yield put(layoutActions.hideLoading());
}

function* updateFaturaKartiWorker({ payload, history, previousFaturaNo }) {
  yield put(layoutActions.showLoading());
  const musteriId = yield select(getMusteriId);
  const musteriAdi = yield select(getMusteriAdi);
  const musteriSifre = yield select(getMusteriSifre);

  const mappedPayload = mapper.requestUpdateFaturaKartiMapper(
    musteriId,
    musteriAdi,
    musteriSifre,
    payload
  );

  try {
    const err = {
      message: 'Bilinmeyen bir hata oluştu',
    };

    // First check fatura no is present or not if it is necessary.
    if (previousFaturaNo !== mappedPayload[constants.faturalarServer.FATURA_NO]) {
      const responseCheck = yield call(
        api.checkFaturaNo,
        musteriId,
        musteriAdi,
        musteriSifre,
        mappedPayload[constants.faturalarServer.FATURA_NO]
      );

      // Check response schema
      const { data } = responseCheck;
      if (!otherSchemas.responseVarYokSchema.isValidSync(data)) {
        throw err;
      }

      if (data[0].geriDonenDeger === 'var') {
        err.message = 'Bu fatura no kayıtlı. Lütfen başka fatura no giriniz.';
        throw err;
      }
    }

    // Send request to update
    const response = yield call(api.updateFaturaKarti, mappedPayload);

    // Check response schema
    const { data } = response;
    if (!schemas.responseFaturaGuncelleSchema.isValidSync(data)) {
      throw err;
    }

    yield put(layoutActions.showMessage('Başarıyla güncellendi'));
    history.goBack();
  } catch (error) {
    yield put(layoutActions.showError(error.message));
  }
}

function* updateFaturaKartiAfterRowsChangeWorker({ payload }) {
  yield put(layoutActions.showLoading());
  const musteriId = yield select(getMusteriId);
  const musteriAdi = yield select(getMusteriAdi);
  const musteriSifre = yield select(getMusteriSifre);

  const mappedPayload = mapper.requestUpdateFaturaKartiAfterRowChangeMapper(
    musteriId,
    musteriAdi,
    musteriSifre,
    payload
  );

  try {
    const err = {
      message: 'Bilinmeyen bir hata oluştu',
    };

    // Send request to update
    const response = yield call(api.updateFaturaKarti, mappedPayload);

    // Check response schema
    const { data } = response;
    if (!schemas.responseFaturaGuncelleSchema.isValidSync(data)) {
      throw err;
    }
  } catch (error) {
    yield put(layoutActions.showError(error.message));
  }
}

function* addFaturaRowWorker({ payload }) {
  yield put(layoutActions.showLoading());
  const musteriId = yield select(getMusteriId);
  const musteriAdi = yield select(getMusteriAdi);
  const musteriSifre = yield select(getMusteriSifre);
  const faturaKarti = yield select(getFaturaKarti);

  const mappedPayload = mapper.requestAddFaturaRowMapper(
    musteriId,
    musteriAdi,
    musteriSifre,
    payload,
    faturaKarti
  );

  try {
    // Send request to update
    faturaKarti.rows.push(payload);
    faturaKarti[constants.faturalarClient.TUTAR] = calculateTutar(faturaKarti.rows, false);
    faturaKarti[constants.faturalarClient.KDV_TUTAR] = calculateKdvTutar(faturaKarti.rows, false);
    faturaKarti[constants.faturalarClient.OTV_TUTAR] = calculateOtvTutar(faturaKarti.rows, false);
    faturaKarti[constants.faturalarClient.TOPLAM_TUTAR] = calculateToplamTutar(
      faturaKarti.rows,
      false
    );

    yield call(updateFaturaKartiAfterRowsChangeWorker, { payload: faturaKarti });
    yield call(api.updateFaturaDetaylar, mappedPayload);
    yield put(actions.fetchFaturaDetaylar(faturaKarti[constants.faturalarClient.FATURA_ID]));
    yield put(layoutActions.showMessage('Başarıyla eklendi'));
  } catch (error) {
    yield put(layoutActions.showError(error.message));
  }
}

function* updateFaturaRowWorker({ payload, selectedRow }) {
  yield put(layoutActions.showLoading());
  const musteriId = yield select(getMusteriId);
  const musteriAdi = yield select(getMusteriAdi);
  const musteriSifre = yield select(getMusteriSifre);
  const faturaKarti = yield select(getFaturaKarti);
  const mappedPayload = mapper.requestUpdateFaturaRowMapper(
    musteriId,
    musteriAdi,
    musteriSifre,
    payload,
    faturaKarti,
    selectedRow
  );

  try {
    // Send request to update
    faturaKarti.rows[selectedRow[constants.faturaDetaylarClient.SIRA_NO] - 1] = payload;
    faturaKarti[constants.faturalarClient.TUTAR] = calculateTutar(faturaKarti.rows, false);
    faturaKarti[constants.faturalarClient.KDV_TUTAR] = calculateKdvTutar(faturaKarti.rows, false);
    faturaKarti[constants.faturalarClient.OTV_TUTAR] = calculateOtvTutar(faturaKarti.rows, false);
    faturaKarti[constants.faturalarClient.TOPLAM_TUTAR] = calculateToplamTutar(
      faturaKarti.rows,
      false
    );
    yield call(updateFaturaKartiAfterRowsChangeWorker, { payload: faturaKarti });
    yield call(api.updateFaturaDetaylar, mappedPayload);
    yield put(actions.fetchFaturaDetaylar(faturaKarti[constants.faturalarClient.FATURA_ID]));
    yield put(layoutActions.showMessage('Başarıyla eklendi'));
  } catch (error) {
    yield put(layoutActions.showError(error.message));
  }
}

function* updateFaturaRowAfterDeleteWorker({ selectedRow }) {
  yield put(layoutActions.showLoading());
  const musteriId = yield select(getMusteriId);
  const musteriAdi = yield select(getMusteriAdi);
  const musteriSifre = yield select(getMusteriSifre);
  const faturaKarti = yield select(getFaturaKarti);
  const mappedPayload = mapper.requestUpdateFaturaRowAfterDeleteMapper(
    musteriId,
    musteriAdi,
    musteriSifre,
    faturaKarti,
    selectedRow
  );

  try {
    yield call(api.updateFaturaDetaylar, mappedPayload);
  } catch (error) {
    yield put(layoutActions.showError(error.message));
  }
}

function* deleteFaturaKartiWorker({ payload: { faturaId }, history }) {
  yield put(layoutActions.showLoading());
  const musteriId = yield select(getMusteriId);
  const musteriAdi = yield select(getMusteriAdi);
  const musteriSifre = yield select(getMusteriSifre);

  try {
    const err = {
      message: 'Bilinmeyen bir hata oluştu',
    };

    // Send request to delete
    const response = yield call(
      api.deleteFaturaKarti,
      musteriId,
      musteriAdi,
      musteriSifre,
      faturaId
    );

    // Check response schema
    const { data } = response;
    if (!otherSchemas.responseTrueFalseSchema.isValidSync(data)) {
      throw err;
    }

    if (data.geriDonenDeger !== 'true') {
      err.message = 'Bilinmeyen bir hata oluştu. Fatura Karti silinemedi.';
      throw err;
    }
    yield put(layoutActions.showMessage('Fatura Karti silindi.'));
    history.goBack();
  } catch (error) {
    yield put(layoutActions.showError(error.message));
  }
}

function* deleteFaturaRowWorker({ payload }) {
  yield put(layoutActions.showLoading());
  const musteriId = yield select(getMusteriId);
  const musteriAdi = yield select(getMusteriAdi);
  const musteriSifre = yield select(getMusteriSifre);

  const mappedPayload = mapper.requestDeleteFaturaRowMapper(
    musteriId,
    musteriAdi,
    musteriSifre,
    payload
  );

  try {
    // Send request to delete
    yield call(api.deleteFaturaDetaylar, mappedPayload);

    // Update fatura kartı
    const faturaKarti = yield select(getFaturaKarti);
    const index = payload[constants.faturaDetaylarClient.SIRA_NO] - 1;
    if (index >= 0 && index < faturaKarti.rows.length) {
      faturaKarti.rows.splice(index, 1);
    }
    faturaKarti.rows = faturaKarti.rows.map((e, i) => {
      e[constants.faturaDetaylarClient.SIRA_NO] = i + 1;
      return e;
    });
    faturaKarti[constants.faturalarClient.TUTAR] = calculateTutar(faturaKarti.rows);
    faturaKarti[constants.faturalarClient.KDV_TUTAR] = calculateKdvTutar(faturaKarti.rows);
    faturaKarti[constants.faturalarClient.OTV_TUTAR] = calculateOtvTutar(faturaKarti.rows);
    faturaKarti[constants.faturalarClient.TOPLAM_TUTAR] = calculateToplamTutar(faturaKarti.rows);
    yield call(updateFaturaKartiAfterRowsChangeWorker, { payload: faturaKarti });

    // Update rows' sıra no
    for (let i = 0; i < faturaKarti.rows.length; i += 1) {
      yield call(updateFaturaRowAfterDeleteWorker, {
        selectedRow: faturaKarti.rows[i],
      });
    }

    // Fetch fatura
    yield put(actions.fetchFaturaDetaylar(payload[constants.faturalarClient.FATURA_ID]));
    yield put(layoutActions.showMessage('Fatura satırı silindi.'));
  } catch (error) {
    yield put(layoutActions.showError(error.message));
  }
}

export function* watchFaturalar() {
  yield takeEvery(actionTypes.FETCH_FATURA_KARTLARI, fetchFaturaKartlariWorker);
  yield takeEvery(actionTypes.ADD_FATURA_KARTI, addFaturaKartiWorker);
  yield takeEvery(actionTypes.FETCH_FATURA_DETAYLAR, fetchFaturaDetaylarWorker);
  yield takeEvery(actionTypes.UPDATE_FATURA_KARTI, updateFaturaKartiWorker);
  yield takeEvery(
    actionTypes.UPDATE_FATURA_KARTI_AFTER_ROWS_CHANGE,
    updateFaturaKartiAfterRowsChangeWorker
  );
  yield takeEvery(actionTypes.ADD_FATURA_ROW, addFaturaRowWorker);
  yield takeEvery(actionTypes.UPDATE_FATURA_ROW, updateFaturaRowWorker);
  yield takeEvery(actionTypes.DELETE_FATURA_KARTI, deleteFaturaKartiWorker);
  yield takeEvery(actionTypes.DELETE_FATURA_ROW, deleteFaturaRowWorker);
}

function* faturalarSaga() {
  yield all([fork(watchFaturalar)]);
}

export default faturalarSaga;
