export const FETCH_FATURA_KARTLARI = 'FETCH_FATURA_KARTLARI';
export const FETCH_FATURA_KARTLARI_SUCCESS = 'FETCH_FATURA_KARTLARI_SUCCESS';
export const FETCH_FATURA_KARTLARI_FAILURE = 'FETCH_FATURA_KARTLARI_FAILURE';

export const ADD_FATURA_KARTI = 'ADD_FATURA_KARTI';

export const UPDATE_FATURA_KARTI = 'UPDATE_FATURA_KARTI';
export const UPDATE_FATURA_KARTI_AFTER_ROWS_CHANGE = 'UPDATE_FATURA_KARTI_AFTER_ROWS_CHANGE';

export const UPDATE_FATURA_ROW = 'UPDATE_FATURA_ROW';
export const ADD_FATURA_ROW = 'ADD_FATURA_ROW';

export const DELETE_FATURA_KARTI = 'DELETE_FATURA_KARTI';
export const DELETE_FATURA_ROW = 'DELETE_FATURA_ROW';

export const SELECT_FATURA_ROW_STOK = 'SELECT_FATURA_ROW_STOK';
export const FETCH_FATURA_ROW_STOK = 'FETCH_FATURA_ROW_STOK';

export const SELECT_FATURA_ROW_HIZMET = 'SELECT_FATURA_ROW_HIZMET';
export const FETCH_FATURA_ROW_HIZMET = 'FETCH_FATURA_ROW_HIZMET';

export const FETCH_FATURA_DETAYLAR = 'FETCH_FATURA_DETAYLAR';
export const FETCH_FATURA_DETAYLAR_SUCCESS = 'FETCH_FATURA_DETAYLAR_SUCCESS';
export const FETCH_FATURA_DETAYLAR_FAILURE = 'FETCH_FATURA_DETAYLAR_FAILURE';
