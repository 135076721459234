export const FETCH_GUNLUK_RAPOR = 'FETCH_GUNLUK_RAPOR';
export const FETCH_GUNLUK_RAPOR_KASA = 'FETCH_GUNLUK_RAPOR_KASA';
export const CHANGE_GUNLUK_RAPOR_DATES = 'CHANGE_GUNLUK_RAPOR_DATES';
export const SELECT_GUNLUK_RAPOR_KASA = 'SELECT_GUNLUK_RAPOR_KASA';

export const FETCH_CARI_RAPORU = 'FETCH_CARI_RAPORU';
export const CHANGE_CARI_RAPORU_DATES = 'CHANGE_CARI_RAPORU_DATES';
export const SELECT_CARI_RAPORU_CARI = 'SELECT_CARI_RAPORU_CARI';

export const FETCH_STOK_RAPORU = 'FETCH_STOK_RAPORU';
export const CHANGE_STOK_RAPORU_DATES = 'CHANGE_STOK_RAPORU_DATES';
export const SELECT_STOK_RAPORU_STOK = 'SELECT_STOK_RAPORU_STOK';

export const FETCH_KASA_RAPORU = 'FETCH_KASA_RAPORU';
export const CHANGE_KASA_RAPORU_DATES = 'CHANGE_KASA_RAPORU_DATES';
export const SELECT_KASA_RAPORU_KASA = 'SELECT_KASA_RAPORU_KASA';

export const FETCH_HIZMET_RAPORU = 'FETCH_HIZMET_RAPORU';
export const CHANGE_HIZMET_RAPORU_DATES = 'CHANGE_HIZMET_RAPORU_DATES';
export const SELECT_HIZMET_RAPORU_HIZMET = 'SELECT_HIZMET_RAPORU_HIZMET';
export const SELECT_HIZMET_RAPORU_ALIS_SATIS = 'SELECT_HIZMET_RAPORU_ALIS_SATIS';

export const FETCH_FATURA_RAPORU = 'FETCH_FATURA_RAPORU';
export const CHANGE_FATURA_RAPORU_DATES = 'CHANGE_FATURA_RAPORU_DATES';
export const SELECT_FATURA_RAPORU_CARI = 'SELECT_FATURA_RAPORU_CARI';
export const SELECT_FATURA_RAPORU_STOK = 'SELECT_FATURA_RAPORU_STOK';
export const SELECT_FATURA_RAPORU_FATURA_TURU = 'SELECT_FATURA_RAPORU_FATURA_TURU';

export const RAPORLAR_FETCH_API_SUCCESS = 'RAPORLAR_FETCH_API_SUCCESS';
export const API_FAILED = 'RAPORLAR_API_FAILED';

export const FETCH_FATURA_INVOICE_FOR_MOBILE = 'FETCH_FATURA_INVOICE_FOR_MOBILE';
export const FETCH_CARI_RAPORU_FOR_MOBILE = 'FETCH_CARI_RAPORU_FOR_MOBILE';
export const FETCH_STOK_RAPORU_FOR_MOBILE = 'FETCH_STOK_RAPORU_FOR_MOBILE';
export const FETCH_HIZMET_RAPORU_FOR_MOBILE = 'FETCH_HIZMET_RAPORU_FOR_MOBILE';
export const FETCH_FATURA_RAPORU_FOR_MOBILE = 'FETCH_FATURA_RAPORU_FOR_MOBILE';
export const FETCH_KASA_RAPORU_FOR_MOBILE = 'FETCH_KASA_RAPORU_FOR_MOBILE';
