import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { safelyParseJSON, decodeBase64 } from '../../helpers/utils';
import { pdfGeneratorFields, pdfGeneratorTypes } from '../../constants';
import {
  fetchCariRaporuForMobile,
  fetchStokRaporuForMobile,
  fetchHizmetRaporuForMobile,
  fetchFaturaRaporuForMobile,
  fetchKasaRaporuForMobile,
} from '../../store/actions';
import TikirReportWrapperMobile from '../../components/TikirReport/TikirReportWrapperMobile/TikirReportWrapperMobile';

const PDFGenerator = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const pdfReportForMobile = useSelector((state) => state.raporlar.pdfReportForMobile);
  const { payload } = params;

  const decodedPayload = decodeBase64(payload);

  const payloadObj = safelyParseJSON(decodedPayload);

  const { type, firm } = payloadObj;

  /*
    Example payload:

    {
      type: 0,
      firm: 'Firkateyn',
      timestamp: 2149449343,
      payload: {
        ...
      }
    }
  */

  useEffect(() => {
    if (payloadObj) {
      const { type, timestamp, payload } = payloadObj;
      switch (type) {
        case pdfGeneratorTypes.TYPE_CARI_REPORT:
          dispatch(fetchCariRaporuForMobile(payload));
          break;
        case pdfGeneratorTypes.TYPE_STOK_REPORT:
          dispatch(fetchStokRaporuForMobile(payload));
          break;
        case pdfGeneratorTypes.TYPE_HIZMET_REPORT:
          dispatch(fetchHizmetRaporuForMobile(payload));
          break;
        case pdfGeneratorTypes.TYPE_FATURA_REPORT:
          dispatch(fetchFaturaRaporuForMobile(payload));
          break;
        case pdfGeneratorTypes.TYPE_KASA_REPORT:
          dispatch(fetchKasaRaporuForMobile(payload));
          break;
        default:
          break;
      }
    }
  }, [dispatch, payload]);

  if (!payloadObj || !pdfReportForMobile) {
    return null;
  }

  return (
    <TikirReportWrapperMobile
      title="Rapor"
      firm={firm}
      headers={pdfGeneratorFields[type]}
      data={pdfReportForMobile}
      fileName="Rapor.pdf"
      showFooter
    />
  );
};

export default PDFGenerator;
