import { apiCall, apiCallPost, methods } from '../api';

const urls = {
  FETCH_HIZMET_KARTLARI_URL:
    '/HizmetKartlari?musteri_id={0}&musteri_adi={1}&musteri_sifre={2}&Siralama={3}&Arama={4}&KayitBaslangic={5}&KayitBitis={6}',
  ADD_HIZMET_KARTI_URL: '/HizmetKartiEkle',
  CHECK_HIZMET_KODU_URL:
    '/HizmetKoduKontrol?musteri_id={0}&musteri_adi={1}&musteri_sifre={2}&HizmetKodu={3}',
  FETCH_HIZMET_KARTI_DETAY_URL:
    '/HizmetKartiDetay?musteri_id={0}&musteri_adi={1}&musteri_sifre={2}&HizmetID={3}',
  CHECK_HIZMET_FISI_URL:
    '/HizmetFisiKontrol?musteri_id={0}&musteri_adi={1}&musteri_sifre={2}&HizmetID={3}',
  DELETE_HIZMET_KARTI_URL: '/HizmetKartiSil',
  UPDATE_HIZMET_KARTI_URL: '/HizmetKartiGuncelle',
};

export const fetchHizmetKartlari = (
  musteriId,
  musteriAdi,
  musteriSifre,
  siralama,
  arama,
  kayitBaslangic,
  kayitBitis
) =>
  apiCall(
    urls.FETCH_HIZMET_KARTLARI_URL.format(
      musteriId,
      musteriAdi,
      musteriSifre,
      siralama,
      arama,
      kayitBaslangic,
      kayitBitis
    ),
    methods.GET
  );

export const addHizmetKarti = (payload) => {
  const data = new URLSearchParams();
  Object.keys(payload).forEach((key) => {
    data.append(key, payload[key]);
  });
  return apiCallPost(urls.ADD_HIZMET_KARTI_URL, data);
};

export const checkHizmetKodu = (musteriId, musteriAdi, musteriSifre, hizmetKodu) =>
  apiCall(
    urls.CHECK_HIZMET_KODU_URL.format(musteriId, musteriAdi, musteriSifre, hizmetKodu),
    methods.GET
  );

export const fetchHizmetDetaylar = (musteriId, musteriAdi, musteriSifre, hizmetId) =>
  apiCall(
    urls.FETCH_HIZMET_KARTI_DETAY_URL.format(musteriId, musteriAdi, musteriSifre, hizmetId),
    methods.GET
  );

export const deleteHizmetKarti = (musteriId, musteriAdi, musteriSifre, hizmetId) => {
  const data = new URLSearchParams();
  data.append('musteri_id', musteriId);
  data.append('musteri_adi', musteriAdi);
  data.append('musteri_sifre', musteriSifre);
  data.append('HizmetID', hizmetId);
  return apiCallPost(urls.DELETE_HIZMET_KARTI_URL, data);
};

export const checkHizmetFisi = (musteriId, musteriAdi, musteriSifre, hizmetId) =>
  apiCall(
    urls.CHECK_HIZMET_FISI_URL.format(musteriId, musteriAdi, musteriSifre, hizmetId),
    methods.GET
  );

export const updateHizmetKarti = (payload) => {
  const data = new URLSearchParams();
  Object.keys(payload).forEach((key) => {
    data.append(key, payload[key]);
  });
  return apiCallPost(urls.UPDATE_HIZMET_KARTI_URL, data);
};
