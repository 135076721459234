import { all } from 'redux-saga/effects';
import loginSaga from './auth/saga';
import carilerSaga from './modules/cariler/saga';
import stoklarSaga from './modules/stoklar/saga';
import hizmetlerSaga from './modules/hizmetler/saga';
import faturalarSaga from './modules/faturalar/saga';
import makbuzlarSaga from './modules/makbuzlar/saga';
import raporlarSaga from './modules/raporlar/saga';
import othersSaga from './modules/others/saga';
import layoutSaga from './layout/saga';

export default function* rootSaga() {
  yield all([
    loginSaga(),
    carilerSaga(),
    stoklarSaga(),
    hizmetlerSaga(),
    faturalarSaga(),
    makbuzlarSaga(),
    raporlarSaga(),
    othersSaga(),
    layoutSaga(),
  ]);
}
