import moment from 'moment';
import accounting from 'accounting';
import { hizmetlerClient, hizmetlerServer } from '../../../../constants/hizmetler';
import { formatMoney } from '../../../../helpers/utils';

export const responseFetchHizmetKartlariMapper = (data) =>
  data.reduce((memo, el) => {
    if (el[hizmetlerServer.HIZMET_KODU] !== null && el[hizmetlerServer.HIZMET_KODU] !== '') {
      if (el[hizmetlerServer.SATIS_FIYATI] === '') {
        el[hizmetlerServer.SATIS_FIYATI] = '0,00';
      }
      el[hizmetlerServer.SATIS_FIYATI] = accounting.formatMoney(
        parseFloat(el[hizmetlerServer.SATIS_FIYATI].replace(',', '.')),
        '',
        2,
        '.',
        ','
      );

      memo.push(el);
    }
    return memo;
  }, []);

export const responseDateMapper = (data, dateField) =>
  data.reduce((memo, el) => {
    if (el[dateField]) {
      el[dateField] = moment(el[dateField], 'yyyy-MM-DD HH:mm:ss.SSS').format(
        'DD.MM.yyyy HH:mm:ss'
      );
    }
    memo.push(el);
    return memo;
  }, []);

export const requestAddHizmetKartiMapper = (musteriId, musteriAdi, musteriSifre, payload) => ({
  musteri_id: musteriId,
  musteri_adi: musteriAdi,
  musteri_sifre: musteriSifre,
  [hizmetlerServer.HIZMET_KODU]: payload[hizmetlerClient.HIZMET_KODU] ?? '',
  [hizmetlerServer.HIZMET_ADI]: payload[hizmetlerClient.HIZMET_ADI] ?? '',
  [hizmetlerServer.HIZMET_GRUBU]: payload[hizmetlerClient.HIZMET_GRUBU] ?? '',
  [hizmetlerServer.BARKOD]: payload[hizmetlerClient.BARKOD] ?? '',
  [hizmetlerServer.BIRIMI]: payload[hizmetlerClient.BIRIMI] ?? '',
  [hizmetlerServer.PARA_BIRIMI]: payload[hizmetlerClient.PARA_BIRIMI]
    ? payload[hizmetlerClient.PARA_BIRIMI].value ?? '0'
    : '0',
  [hizmetlerServer.ALIS_FIYATI]: payload[hizmetlerClient.ALIS_FIYATI] || '0.0',
  [hizmetlerServer.SATIS_FIYATI]: payload[hizmetlerClient.SATIS_FIYATI] || '0.0',
  [hizmetlerServer.KDV]: payload[hizmetlerClient.KDV] || '0.0',
  [hizmetlerServer.HIZMET_ID]: payload[hizmetlerClient.HIZMET_ID] ?? '',
});

export const requestUpdateHizmetKartiMapper = (musteriId, musteriAdi, musteriSifre, payload) =>
  requestAddHizmetKartiMapper(musteriId, musteriAdi, musteriSifre, payload);

export const responseFetchHizmetDetaylarMapper = (payload, hizmetId) => ({
  [hizmetlerClient.HIZMET_KODU]: payload[0].StokVeriler,
  [hizmetlerClient.HIZMET_ADI]: payload[1].StokVeriler,
  [hizmetlerClient.BIRIMI]: payload[2].StokVeriler,
  [hizmetlerClient.HIZMET_GRUBU]: payload[3].StokVeriler,
  [hizmetlerClient.HIZMET_GRUBU_ID]: payload[4].StokVeriler,
  [hizmetlerClient.KDV]: formatMoney(parseFloat(payload[5].StokVeriler.replace(',', '.')) || 0.0),
  [hizmetlerClient.ALIS_FIYATI]: formatMoney(
    parseFloat(payload[6].StokVeriler.replace(',', '.')) || 0.0
  ),
  [hizmetlerClient.SATIS_FIYATI]: formatMoney(
    parseFloat(payload[7].StokVeriler.replace(',', '.')) || 0.0
  ),
  [hizmetlerClient.PARA_BIRIMI]: payload[8].StokVeriler,
  [hizmetlerClient.PARA_BIRIMI_ADI]: payload[9].StokVeriler,
  [hizmetlerClient.HIZMET_ID]: hizmetId,
});
