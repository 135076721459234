import * as actionTypes from './actionTypes';

//= ====================================
//            Fatura Kartlari
//= ====================================
export const fetchFaturaKartlari = (siralama, arama, kayitBaslangic, kayitBitis, faturaTuru) => ({
  type: actionTypes.FETCH_FATURA_KARTLARI,
  payload: { siralama, arama, kayitBaslangic, kayitBitis, faturaTuru },
});

export const fetchFaturaKartlariSuccess = (faturaKartlari) => ({
  type: actionTypes.FETCH_FATURA_KARTLARI_SUCCESS,
  payload: {
    faturaKartlari,
  },
});

//= ====================================
//            Add Fatura
//= ====================================
export const addFaturaKarti = (payload, history) => ({
  type: actionTypes.ADD_FATURA_KARTI,
  payload,
  history,
});

//= ====================================
//            Fatura Karti Detaylar
//= ====================================
export const fetchFaturaDetaylar = (faturaId) => ({
  type: actionTypes.FETCH_FATURA_DETAYLAR,
  payload: { faturaId },
});

export const fetchFaturaDetaylarSuccess = (faturaKarti) => ({
  type: actionTypes.FETCH_FATURA_DETAYLAR_SUCCESS,
  payload: {
    faturaKarti,
  },
});

export const fetchFaturaDetaylarFailure = (error) => ({
  type: actionTypes.FETCH_FATURA_DETAYLAR_FAILURE,
  payload: { error },
});

//= ====================================
//            Fatura Karti Delete
//= ====================================
export const deleteFaturaKarti = (faturaId, history) => ({
  type: actionTypes.DELETE_FATURA_KARTI,
  payload: { faturaId },
  history,
});

//= ====================================
//            Fatura Karti Row Delete
//= ====================================
export const deleteFaturaRow = (payload) => ({
  type: actionTypes.DELETE_FATURA_ROW,
  payload,
});

//= ====================================
//            Update Fatura Karti
//= ====================================
export const updateFaturaKarti = (payload, history, previousFaturaNo) => ({
  type: actionTypes.UPDATE_FATURA_KARTI,
  payload,
  history,
  previousFaturaNo,
});

export const updateFaturaKartiAfterRowUpdating = (payload, faturaId) => ({
  type: actionTypes.UPDATE_FATURA_KARTI_AFTER_ROWS_CHANGE,
  payload,
  faturaId,
});

//= ====================================
//            Select Stok Karti For Fatura Row
//= ====================================
export const selectStokKartiOnFaturaRow = (selectedStokKarti) => ({
  type: actionTypes.SELECT_FATURA_ROW_STOK,
  payload: { selectedStokKarti },
});

export const fetchStokKartiOnFaturaRow = (stokId) => ({
  type: actionTypes.FETCH_FATURA_ROW_STOK,
  payload: { stokId },
});

//= ====================================
//            Select Hizmet Karti For Fatura Row
//= ====================================
export const selectHizmetKartiOnFaturaRow = (selectedHizmetKarti) => ({
  type: actionTypes.SELECT_FATURA_ROW_HIZMET,
  payload: { selectedHizmetKarti },
});

export const fetchHizmetKartiOnFaturaRow = (hizmetId) => ({
  type: actionTypes.FETCH_FATURA_ROW_HIZMET,
  payload: { hizmetId },
});

//= ====================================
//            Update Fatura Karti
//= ====================================
export const updateFaturaRow = (payload, selectedRow) => ({
  type: actionTypes.UPDATE_FATURA_ROW,
  payload,
  selectedRow,
});

export const addFaturaRow = (payload) => ({
  type: actionTypes.ADD_FATURA_ROW,
  payload,
});
