const constants = {
  // Default sorting in the module
  SORT_DEFAULT_CARILER: 'S',
  SORT_DEFAULT_STOKLAR: 'S',
  SORT_DEFAULT_HIZMETLER: 'S',
  SORT_DEFAULT_MAKBUZLAR: 'S',
  SORT_DEFAULT_FATURALAR: 'S',
};

const prod = {
  API_BASE_URL: 'https://aymet.web.tr/Webservice.asmx',
};
const dev = {
  API_BASE_URL: 'https://aymet.web.tr/Webservice.asmx',
};

export const patterns = {
  PATTERN_ONLY_NUMBER: /^([0-9]+([,][0-9]*)?|[,][0-9]+)$/,
};

// Fatura Types
export const faturalar = {
  TYPE_FATURA_ALIS_STOK: {
    faturaTuru: 'A',
    isSatis: false,
    name: 'Alış Stok Faturaları',
    path: '/faturalar/alis/stok/',
    add: {
      path: '/faturalar/alis/stok/add',
      name: 'Yeni Alış Stok Faturası',
    },
    details: {
      path: '/faturalar/alis/stok/:faturaId',
      name: 'Alış Stok Faturası',
    },
  },
  TYPE_FATURA_ALIS_HIZMET: {
    faturaTuru: 'H',
    isSatis: false,
    name: 'Alış Hizmet Faturaları',
    path: '/faturalar/alis/hizmet/',
    add: {
      path: '/faturalar/alis/hizmet/add',
      name: 'Yeni Alış Hizmet Faturası',
    },
    details: {
      path: '/faturalar/alis/hizmet/:faturaId',
      name: 'Alış Hizmet Faturası',
    },
  },
  TYPE_FATURA_SATIS_STOK: {
    faturaTuru: 'S',
    isSatis: true,
    name: 'Satış Stok Faturaları',
    path: '/faturalar/satis/stok/',
    add: {
      path: '/faturalar/satis/stok/add',
      name: 'Yeni Satış Stok Faturası',
    },
    details: {
      path: '/faturalar/satis/stok/:faturaId',
      name: 'Satış Stok Faturası',
    },
  },
  TYPE_FATURA_SATIS_HIZMET: {
    faturaTuru: 'Z',
    isSatis: true,
    name: 'Satış Hizmet Faturaları',
    path: '/faturalar/satis/hizmet/',
    add: {
      path: '/faturalar/satis/hizmet/add',
      name: 'Yeni Satış Hizmet Faturası',
    },
    details: {
      path: '/faturalar/satis/hizmet/:faturaId',
      name: 'Satış Hizmet Faturası',
    },
  },
};

// Fatura Types
export const makbuzlar = {
  TYPE_MAKBUZ_TEDIYE: {
    makbuzTuru: 'D',
    name: 'Tediye Makbuzları',
    path: '/makbuzlar/tediyeler/',
    add: {
      path: '/makbuzlar/tediye/add',
      name: 'Yeni Tediye Makbuzu',
    },
    details: {
      path: '/makbuzlar/tediyeler/:makbuzId',
      name: 'Tediye Makbuzu',
    },
  },
  TYPE_MAKBUZ_TAHSILAT: {
    makbuzTuru: 'T',
    name: 'Tahsilat Makbuzları',
    path: '/makbuzlar/tahsilatlar/',
    add: {
      path: '/makbuzlar/tahsilat/add',
      name: 'Yeni Tahsilat Makbuzu',
    },
    details: {
      path: '/makbuzlar/tahsilatlar/:makbuzId',
      name: 'Tahsilat Makbuzu',
    },
  },
};

export const dateFormats = {
  DATE_FORMAT_CLIENT: 'yyyy-MM-DDTHH:mm',
  DATE_FORMAT_TIKIR: 'DD.MM.yyyy HH:mm:ss',
  DATE_FORMAT_SERVER: 'yyyy-MM-DD HH:mm:ss.SSS',
  DATE_FORMAT_FILE: 'yyyyMMDD',
  DATE_FORMAT_PDF: 'DD.MM.yyyy',
};

export const pdfGeneratorTypes = {
  TYPE_FATURA_INVOICE: 0,
  TYPE_CARI_REPORT: 1,
  TYPE_STOK_REPORT: 2,
  TYPE_HIZMET_REPORT: 3,
  TYPE_FATURA_REPORT: 4,
  TYPE_KASA_REPORT: 5,
};

export const pdfGeneratorFields = {
  [pdfGeneratorTypes.TYPE_FATURA_INVOICE]: [],
  [pdfGeneratorTypes.TYPE_CARI_REPORT]: [
    { key: 'CariKodu', label: 'Cari Kodu' },
    { key: 'CariAdi', label: 'Cari Adı', footerText: 'TOPLAM:' },
    { key: 'Borc', label: 'Borç', number: true },
    { key: 'Alacak', label: 'Alacak', number: true },
    { key: 'Bakiye', label: 'Bakiye', number: true },
  ],
  [pdfGeneratorTypes.TYPE_STOK_REPORT]: [
    { key: 'StokKodu', label: 'Stok Kodu' },
    { key: 'StokAdi', label: 'Stok Adı' },
    { key: 'Giris', label: 'Giriş', number: true },
    { key: 'Cikis', label: 'Çıkış', number: true },
    { key: 'Kalan', label: 'Kalan', number: true },
  ],
  [pdfGeneratorTypes.TYPE_HIZMET_REPORT]: [
    { key: 'HizmetKodu', label: 'Hizmet Kodu' },
    { key: 'HizmetAciklamasi', label: 'Hizmet Adı' },
    { key: 'Yekun', label: 'Toplam Tutar', number: true },
  ],
  [pdfGeneratorTypes.TYPE_FATURA_REPORT]: [
    { key: 'FaturaTarihi', label: 'Fatura Tarihi' },
    { key: 'FaturaNo', label: 'Fatura No' },
    { key: 'CariAdi', label: 'Cari Adı' },
    { key: 'StokAdi', label: 'Stok Adı' },
    { key: 'Miktar', label: 'Miktar', number: true, footerText: 'TOPLAM:' },
    { key: 'Yekun', label: 'Tutar', number: true },
  ],
  [pdfGeneratorTypes.TYPE_KASA_REPORT]: [
    { key: 'KasaKodu', label: 'Kasa Kodu' },
    { key: 'KasaAdi', label: 'Kasa Adı', footerText: 'TOPLAM:' },
    { key: 'BorcTutari', label: 'Borç Tutarı', number: true },
    { key: 'AlacakTutari', label: 'Alacak Tutarı', number: true },
    { key: 'Bakiye', label: 'Bakiye', number: true },
  ],
};

export const config = process.env.NODE_ENV === 'development' ? dev : prod;
export default constants;
