import { takeEvery, fork, put, all, call, select } from 'redux-saga/effects';
import * as actionTypes from './actionTypes';
import * as actions from './actions';
import * as layoutActions from '../../layout/actions';
import * as api from '../../../api/hizmetler';
import * as mapper from './utils/mapper';
import * as schemas from './utils/schemas';
import * as otherSchemas from '../others/utils/schemas';
import * as constants from '../../../constants/hizmetler';
import { FETCH_FATURA_ROW_HIZMET } from '../faturalar/actionTypes';
import { selectHizmetKartiOnFaturaRow } from '../faturalar/actions';

const getMusteriId = (state) => state.auth.user.musteriId;
const getMusteriAdi = (state) => state.auth.user.musteriAdi;
const getMusteriSifre = (state) => state.auth.user.musteriSifre;

function* fetchHizmetKartlariWorker({ payload: { siralama, arama, kayitBaslangic, kayitBitis } }) {
  const musteriId = yield select(getMusteriId);
  const musteriAdi = yield select(getMusteriAdi);
  const musteriSifre = yield select(getMusteriSifre);

  try {
    const response = yield call(
      api.fetchHizmetKartlari,
      musteriId,
      musteriAdi,
      musteriSifre,
      siralama,
      arama,
      kayitBaslangic,
      kayitBitis
    );
    const { data } = response;
    yield put(actions.fetchHizmetKartlariSuccess(mapper.responseFetchHizmetKartlariMapper(data)));
  } catch (error) {
    yield put(layoutActions.showError(error.message));
  }
}

function* addHizmetKartiWorker({ payload, history }) {
  yield put(layoutActions.showLoading());
  const musteriId = yield select(getMusteriId);
  const musteriAdi = yield select(getMusteriAdi);
  const musteriSifre = yield select(getMusteriSifre);

  const mappedPayload = mapper.requestAddHizmetKartiMapper(
    musteriId,
    musteriAdi,
    musteriSifre,
    payload
  );

  try {
    const err = {
      message: 'Bilinmeyen bir hata oluştu!!',
    };

    // First check hizmet kodu is present or not.
    {
      const responseCheck = yield call(
        api.checkHizmetKodu,
        musteriId,
        musteriAdi,
        musteriSifre,
        mappedPayload[constants.hizmetlerServer.HIZMET_KODU]
      );

      // Check response schema
      const { data } = responseCheck;
      if (!otherSchemas.responseVarYokSchema.isValidSync(data)) {
        throw err;
      }

      if (data[0].geriDonenDeger === 'var') {
        err.message = 'Bu hizmet kodu kayıtlı. Lütfen başka hizmet kodu giriniz.';
        throw err;
      }
    }

    // Send request to add
    const response = yield call(api.addHizmetKarti, mappedPayload);

    // Check response schema
    const { data } = response;
    if (!otherSchemas.responseIdObjectArraySchema.isValidSync(data)) {
      throw err;
    }

    yield put(layoutActions.showMessage('Başarıyla eklendi'));
    history.push('/hizmetler');
  } catch (error) {
    yield put(layoutActions.showError(error.message));
  }
}

function* fetchHizmetDetaylarWorker({ payload: { hizmetId } }) {
  yield put(layoutActions.showLoading());
  const musteriId = yield select(getMusteriId);
  const musteriAdi = yield select(getMusteriAdi);
  const musteriSifre = yield select(getMusteriSifre);

  try {
    const err = {
      message: 'Bilinmeyen bir hata oluştu',
    };
    const response = yield call(
      api.fetchHizmetDetaylar,
      musteriId,
      musteriAdi,
      musteriSifre,
      hizmetId
    );
    const { data } = response;
    if (!schemas.responseHizmetDetaylarSchema.isValidSync(data)) {
      throw err;
    }
    yield put(
      actions.fetchHizmetDetaylarSuccess(mapper.responseFetchHizmetDetaylarMapper(data, hizmetId))
    );
  } catch (error) {
    yield put(layoutActions.showError(error.message));
  }
  yield put(layoutActions.hideLoading());
}

function* updateHizmetKartiWorker({ payload, history, previousHizmetKodu }) {
  yield put(layoutActions.showLoading());
  const musteriId = yield select(getMusteriId);
  const musteriAdi = yield select(getMusteriAdi);
  const musteriSifre = yield select(getMusteriSifre);

  const mappedPayload = mapper.requestUpdateHizmetKartiMapper(
    musteriId,
    musteriAdi,
    musteriSifre,
    payload
  );

  try {
    const err = {
      message: 'Bilinmeyen bir hata oluştu',
    };

    // First check hizmet kodu is present or not if it is necessary.
    if (previousHizmetKodu !== mappedPayload[constants.hizmetlerServer.HIZMET_KODU]) {
      const responseCheck = yield call(
        api.checkHizmetKodu,
        musteriId,
        musteriAdi,
        musteriSifre,
        mappedPayload[constants.hizmetlerServer.HIZMET_KODU]
      );

      // Check response schema
      const { data } = responseCheck;
      if (!otherSchemas.responseVarYokSchema.isValidSync(data)) {
        throw err;
      }

      if (data[0].geriDonenDeger === 'var') {
        err.message = 'Bu hizmet kodu kayıtlı. Lütfen başka hizmet kodu giriniz.';
        throw err;
      }
    }

    // Send request to update
    const response = yield call(api.updateHizmetKarti, mappedPayload);

    // Check response schema
    const { data } = response;
    if (!otherSchemas.responseTrueFalseSchema.isValidSync(data)) {
      throw err;
    }

    if (data.geriDonenDeger !== 'true') {
      err.message = 'Hizmet Kartı Güncellenemedi.';
      throw err;
    }

    yield put(layoutActions.showMessage('Başarıyla güncellendi'));
    history.push('/hizmetler');
  } catch (error) {
    yield put(layoutActions.showError(error.message));
  }
}

function* deleteHizmetKartiWorker({ payload: { hizmetId }, history }) {
  yield put(layoutActions.showLoading());
  const musteriId = yield select(getMusteriId);
  const musteriAdi = yield select(getMusteriAdi);
  const musteriSifre = yield select(getMusteriSifre);

  try {
    const err = {
      message: 'Bilinmeyen bir hata oluştu',
    };

    // First check hizmet is used or not
    {
      const responseCheck = yield call(
        api.checkHizmetFisi,
        musteriId,
        musteriAdi,
        musteriSifre,
        hizmetId
      );

      // Check response schema
      const { data } = responseCheck;
      if (!otherSchemas.responseVarYokSchema.isValidSync(data)) {
        throw err;
      }

      if (data[0].geriDonenDeger === 'var') {
        err.message = 'Bu hizmet kartı işlem gördüğü için silinemez.';
        throw err;
      }
    }

    // Send request to delete
    const response = yield call(
      api.deleteHizmetKarti,
      musteriId,
      musteriAdi,
      musteriSifre,
      hizmetId
    );

    // Check response schema
    const { data } = response;
    if (!otherSchemas.responseTrueFalseSchema.isValidSync(data)) {
      throw err;
    }

    if (data.geriDonenDeger !== 'true') {
      err.message = 'Bilinmeyen bir hata oluştu. Hizmet Karti silinemedi.';
      throw err;
    }
    yield put(layoutActions.showMessage('Hizmet Karti silindi.'));
    history.push('/hizmetler');
  } catch (error) {
    yield put(layoutActions.showError(error.message));
  }
}

function* fetchHizmetDetaylarForFaturaRowWorker({ payload: { hizmetId } }) {
  yield put(layoutActions.showLoading());
  const musteriId = yield select(getMusteriId);
  const musteriAdi = yield select(getMusteriAdi);
  const musteriSifre = yield select(getMusteriSifre);

  try {
    const err = {
      message: 'Bilinmeyen bir hata oluştu',
    };
    const response = yield call(
      api.fetchHizmetDetaylar,
      musteriId,
      musteriAdi,
      musteriSifre,
      hizmetId
    );
    const { data } = response;
    if (!schemas.responseHizmetDetaylarSchema.isValidSync(data)) {
      throw err;
    }
    yield put(
      selectHizmetKartiOnFaturaRow(mapper.responseFetchHizmetDetaylarMapper(data, hizmetId))
    );
  } catch (error) {
    yield put(layoutActions.showError(error.message));
  }
  yield put(layoutActions.hideLoading());
}

export function* watchHizmetler() {
  yield takeEvery(actionTypes.FETCH_HIZMET_KARTLARI, fetchHizmetKartlariWorker);
  yield takeEvery(actionTypes.ADD_HIZMET_KARTI, addHizmetKartiWorker);
  yield takeEvery(actionTypes.FETCH_HIZMET_DETAYLAR, fetchHizmetDetaylarWorker);
  yield takeEvery(actionTypes.UPDATE_HIZMET_KARTI, updateHizmetKartiWorker);
  yield takeEvery(actionTypes.DELETE_HIZMET_KARTI, deleteHizmetKartiWorker);
  yield takeEvery(FETCH_FATURA_ROW_HIZMET, fetchHizmetDetaylarForFaturaRowWorker);
}

function* hizmetlerSaga() {
  yield all([fork(watchHizmetler)]);
}

export default hizmetlerSaga;
