import * as actionTypes from './actionTypes';

export const fetchSehirler = () => ({
  type: actionTypes.FETCH_SEHIRLER,
});

export const fetchParaBirimleri = () => ({
  type: actionTypes.FETCH_PARA_BIRIMLERI,
});

export const fetchCariListesi = (arama, kayitBaslangic, kayitBitis) => ({
  type: actionTypes.FETCH_CARI_LISTESI,
  payload: { arama, kayitBaslangic, kayitBitis },
});

export const fetchStokListesi = (siralama, arama, kayitBaslangic, kayitBitis) => ({
  type: actionTypes.FETCH_STOK_LISTESI,
  payload: { siralama, arama, kayitBaslangic, kayitBitis },
});

export const fetchHizmetListesi = (siralama, arama, kayitBaslangic, kayitBitis) => ({
  type: actionTypes.FETCH_HIZMET_LISTESI,
  payload: { siralama, arama, kayitBaslangic, kayitBitis },
});

export const fetchDepoListesi = () => ({
  type: actionTypes.FETCH_DEPO_LISTESI,
});

export const fetchKasaListesi = () => ({
  type: actionTypes.FETCH_KASA_LISTESI,
});

export const othersFetchApiSuccess = (key, payload) => ({
  type: actionTypes.OTHERS_FETCH_API_SUCCESS,
  payload,
  key,
});

export const othersApiError = (error) => ({
  type: actionTypes.API_FAILED,
  payload: error,
});
