export const FETCH_MAKBUZ_KARTLARI = 'FETCH_MAKBUZ_KARTLARI';
export const FETCH_MAKBUZ_KARTLARI_SUCCESS = 'FETCH_MAKBUZ_KARTLARI_SUCCESS';
export const FETCH_MAKBUZ_KARTLARI_FAILURE = 'FETCH_MAKBUZ_KARTLARI_FAILURE';

export const ADD_MAKBUZ_KARTI = 'ADD_MAKBUZ_KARTI';

export const UPDATE_MAKBUZ_KARTI = 'UPDATE_MAKBUZ_KARTI';
export const UPDATE_MAKBUZ_KARTI_AFTER_ROWS_CHANGE = 'UPDATE_MAKBUZ_KARTI_AFTER_ROWS_CHANGE';

export const UPDATE_MAKBUZ_ROW = 'UPDATE_MAKBUZ_ROW';
export const ADD_MAKBUZ_ROW = 'ADD_MAKBUZ_ROW';

export const DELETE_MAKBUZ_KARTI = 'DELETE_MAKBUZ_KARTI';
export const DELETE_MAKBUZ_ROW = 'DELETE_MAKBUZ_ROW';

export const SELECT_MAKBUZ_ROW_STOK = 'SELECT_MAKBUZ_ROW_STOK';
export const FETCH_MAKBUZ_ROW_STOK = 'FETCH_MAKBUZ_ROW_STOK';

export const SELECT_MAKBUZ_ROW_HIZMET = 'SELECT_MAKBUZ_ROW_HIZMET';
export const FETCH_MAKBUZ_ROW_HIZMET = 'FETCH_MAKBUZ_ROW_HIZMET';

export const FETCH_MAKBUZ_DETAYLAR = 'FETCH_MAKBUZ_DETAYLAR';
export const FETCH_MAKBUZ_DETAYLAR_SUCCESS = 'FETCH_MAKBUZ_DETAYLAR_SUCCESS';
export const FETCH_MAKBUZ_DETAYLAR_FAILURE = 'FETCH_MAKBUZ_DETAYLAR_FAILURE';
