import moment from 'moment';
import accounting from 'accounting';
import {
  getValueOfPairSafely,
  formatDateFromClientToServer,
  formatMoney,
  formatNumberFromMoney,
} from '../../../../helpers/utils';
import {
  faturalarClient,
  faturalarServer,
  faturaDetaylarClient,
  faturaDetaylarServer,
} from '../../../../constants/faturalar';
import { calculateToplamTutar } from '../../../../helpers/faturalar';
import { calculateRowTutar, calculateTutar } from '../../../../helpers/makbuzlar';

export const responseFetchFaturaKartlariMapper = (data) =>
  data.reduce((memo, el) => {
    if (el[faturalarServer.FATURA_NO] !== null && el[faturalarServer.FATURA_NO] !== '') {
      if (el[faturalarServer.YEKUN] === '') {
        el[faturalarServer.YEKUN] = '0,00';
      }
      el[faturalarServer.YEKUN] = accounting.formatMoney(
        parseFloat(el[faturalarServer.YEKUN].replace(',', '.')).toFixed(2).toString(),
        '',
        2,
        '.',
        ','
      );
      memo.push(el);
    }
    return memo;
  }, []);

export const responseDateMapper = (data, dateField) =>
  data.reduce((memo, el) => {
    if (el[dateField]) {
      el[dateField] = moment(el[dateField], 'yyyy-MM-DD HH:mm:ss.SSS').format(
        'DD.MM.yyyy HH:mm:ss'
      );
    }
    memo.push(el);
    return memo;
  }, []);

export const requestAddFaturaKartiMapper = (musteriId, musteriAdi, musteriSifre, payload) => ({
  musteri_id: musteriId,
  musteri_adi: musteriAdi,
  musteri_sifre: musteriSifre,
  [faturalarServer.TARIH]: formatDateFromClientToServer(payload[faturalarClient.TARIH]),
  [faturalarServer.VADE_TARIHI]: formatDateFromClientToServer(payload[faturalarClient.VADE_TARIHI]),
  [faturalarServer.ACIK_KAPALI]: getValueOfPairSafely(payload[faturalarClient.ACIK_KAPALI], 'A'),
  [faturalarServer.KASA_ID]: getValueOfPairSafely(payload[faturalarClient.KASA_ID], ''),
  [faturalarServer.FATURA_NO]: payload[faturalarClient.FATURA_NO] ?? '',
  [faturalarServer.FATURA_TURU]: payload[faturalarClient.FATURA_TURU] ?? '',
  [faturalarServer.FATURA_NOTU]: payload[faturalarClient.FATURA_NOTU] ?? '',
  [faturalarServer.DEPO_KODU]: getValueOfPairSafely(payload[faturalarClient.DEPO_KODU], '0'),
  [faturalarServer.CARI_ID]: getValueOfPairSafely(payload[faturalarClient.CARI_ID], ''),
  [faturalarServer.TUTAR]: formatNumberFromMoney(payload[faturalarClient.TUTAR], 0.0),
  [faturalarServer.KDV_TUTAR]: formatNumberFromMoney(payload[faturalarClient.KDV_TUTAR], 0.0),
  [faturalarServer.OTV_TUTAR]: formatNumberFromMoney(payload[faturalarClient.OTV_TUTAR], 0.0),
  [faturalarServer.TOPLAM_TUTAR]: formatNumberFromMoney(payload[faturalarClient.TOPLAM_TUTAR], 0.0),
  [faturalarServer.FATURA_ID]: formatNumberFromMoney(payload[faturalarClient.FATURA_ID], 0.0),
});

export const requestUpdateFaturaKartiMapper = (musteriId, musteriAdi, musteriSifre, payload) =>
  requestAddFaturaKartiMapper(musteriId, musteriAdi, musteriSifre, payload);

export const requestUpdateFaturaKartiAfterRowChangeMapper = (
  musteriId,
  musteriAdi,
  musteriSifre,
  payload
) => ({
  musteri_id: musteriId,
  musteri_adi: musteriAdi,
  musteri_sifre: musteriSifre,
  [faturalarServer.TARIH]: formatDateFromClientToServer(payload[faturalarClient.TARIH]),
  [faturalarServer.VADE_TARIHI]: formatDateFromClientToServer(payload[faturalarClient.VADE_TARIHI]),
  [faturalarServer.ACIK_KAPALI]: payload[faturalarClient.ACIK_KAPALI] || 'A',
  [faturalarServer.KASA_ID]: payload[faturalarClient.KASA_ID] || '',
  [faturalarServer.FATURA_NO]: payload[faturalarClient.FATURA_NO] ?? '',
  [faturalarServer.FATURA_TURU]: payload[faturalarClient.FATURA_TURU] ?? '',
  [faturalarServer.FATURA_NOTU]: payload[faturalarClient.FATURA_NOTU] ?? '',
  [faturalarServer.DEPO_KODU]: payload[faturalarClient.DEPO_KODU] || '0',
  [faturalarServer.CARI_ID]: payload[faturalarClient.CARI_ID] || '',
  [faturalarServer.TUTAR]: payload[faturalarClient.TUTAR] || 0.0,
  [faturalarServer.KDV_TUTAR]: payload[faturalarClient.KDV_TUTAR] || 0.0,
  [faturalarServer.OTV_TUTAR]: payload[faturalarClient.OTV_TUTAR] || 0.0,
  [faturalarServer.TOPLAM_TUTAR]: payload[faturalarClient.TOPLAM_TUTAR] || 0.0,
  [faturalarServer.FATURA_ID]: payload[faturalarClient.FATURA_ID] || '',
});

export const responseFetchFaturaDetaylarMapper = (payload, faturaId) => {
  const rows = [];
  let counter = 1;

  for (let i = 12; i < payload.length; i += 13) {
    const rowTutar = calculateRowTutar(
      payload[i + 6].FaturaVeriler,
      payload[i + 7].FaturaVeriler,
      payload[i + 8].FaturaVeriler,
      payload[i + 9].FaturaVeriler
    );
    const row = {
      [faturaDetaylarClient.STOK_FISI_DETAY_ID]: payload[i + 0].FaturaVeriler,
      [faturaDetaylarClient.FATURA_DETAY_ID]: payload[i + 1].FaturaVeriler,
      [faturaDetaylarClient.CARI_FISI_DETAY_STOK_ID]: payload[i + 2].FaturaVeriler,
      [faturaDetaylarClient.STOK_CARI_ID]: payload[i + 3].FaturaVeriler,
      [faturaDetaylarClient.STOK_CARI_ADI]: payload[i + 4].FaturaVeriler,
      [faturaDetaylarClient.STOK_CARI_BIRIM]: payload[i + 5].FaturaVeriler,
      [faturaDetaylarClient.MIKTAR]: accounting.formatMoney(
        parseFloat(payload[i + 6].FaturaVeriler.replace(',', '.')) || 0.0,
        '',
        2,
        '.',
        ','
      ),
      [faturaDetaylarClient.FIYAT]: accounting.formatMoney(
        parseFloat(payload[i + 7].FaturaVeriler.replace(',', '.')) || 0.0,
        '',
        2,
        '.',
        ','
      ),
      [faturaDetaylarClient.KDV]: accounting.formatMoney(
        parseFloat(payload[i + 8].FaturaVeriler.replace(',', '.')) || 0.0,
        '',
        2,
        '.',
        ','
      ),
      [faturaDetaylarClient.OTV]: accounting.formatMoney(
        parseFloat(payload[i + 9].FaturaVeriler.replace(',', '.')) || 0.0,
        '',
        2,
        '.',
        ','
      ),
      [faturaDetaylarClient.TUTAR]: rowTutar,
      [faturaDetaylarClient.PARA_BIRIMI_ID]: payload[i + 11].FaturaVeriler,
      [faturaDetaylarClient.PARA_BIRIMI]: payload[i + 12].FaturaVeriler,
      [faturaDetaylarClient.SIRA_NO]: counter,
    };
    counter += 1;
    rows.push(row);
  }
  return {
    [faturalarClient.TARIH]: payload[0].FaturaVeriler,
    [faturalarClient.VADE_TARIHI]: payload[1].FaturaVeriler,
    [faturalarClient.ACIK_KAPALI]: payload[2].FaturaVeriler,
    [faturalarClient.KASA_ID]: payload[3].FaturaVeriler,
    [faturalarClient.KASA_ADI]: payload[4].FaturaVeriler,
    [faturalarClient.FATURA_NO]: payload[5].FaturaVeriler,
    [faturalarClient.FATURA_TURU]: payload[6].FaturaVeriler,
    [faturalarClient.FATURA_NOTU]: payload[7].FaturaVeriler,
    [faturalarClient.CARI_ID]: payload[8].FaturaVeriler,
    [faturalarClient.CARI_ADI]: payload[9].FaturaVeriler,
    [faturalarClient.DEPO_KODU]: payload[10].FaturaVeriler,
    [faturalarClient.DEPO_ADI]: payload[11].FaturaVeriler,
    [faturalarClient.TOPLAM_TUTAR]: calculateToplamTutar(rows),
    [faturalarClient.FATURA_ID]: faturaId,
    rows,
  };
};

export const requestAddFaturaRowMapper = (
  musteriId,
  musteriAdi,
  musteriSifre,
  payload,
  faturaKarti
) => ({
  musteri_id: musteriId,
  musteri_adi: musteriAdi,
  musteri_sifre: musteriSifre,
  [faturalarServer.FATURA_TURU]: faturaKarti[faturalarClient.FATURA_TURU] || '',
  [faturalarServer.FATURA_ID]: faturaKarti[faturalarClient.FATURA_ID] || '',
  [faturaDetaylarServer.SIRA_NO]: (faturaKarti.rows.length || 0) + 1,
  [faturaDetaylarServer.STOK_FISI_DETAY_ID]: 0,
  [faturaDetaylarServer.FATURA_DETAY_ID]: 0,
  [faturaDetaylarServer.CARI_FISI_DETAY_STOK_ID]: 0,
  [faturalarServer.DEPO_KODU]: faturaKarti[faturalarClient.DEPO_KODU] || '0',
  [faturaDetaylarServer.STOK_ID]: getValueOfPairSafely(
    payload[faturaDetaylarClient.STOK_CARI_ID],
    ''
  ),
  [faturaDetaylarServer.BIRIMI]: payload[faturaDetaylarClient.BIRIMI] || '',
  [faturaDetaylarServer.KDV]: payload[faturaDetaylarClient.KDV] || 0.0,
  [faturaDetaylarServer.OTV]: payload[faturaDetaylarClient.OTV] || 0.0,
  [faturaDetaylarServer.MIKTAR]: payload[faturaDetaylarClient.MIKTAR] || 0.0,
  [faturaDetaylarServer.FIYAT]: payload[faturaDetaylarClient.FIYAT] || 0.0,
  [faturaDetaylarServer.PARA_BIRIMI]: getValueOfPairSafely(
    payload[faturaDetaylarClient.PARA_BIRIMI_ID],
    '0'
  ),
});

export const requestUpdateFaturaRowMapper = (
  musteriId,
  musteriAdi,
  musteriSifre,
  payload,
  faturaKarti,
  selectedRow
) => ({
  musteri_id: musteriId,
  musteri_adi: musteriAdi,
  musteri_sifre: musteriSifre,
  [faturalarServer.FATURA_TURU]: faturaKarti[faturalarClient.FATURA_TURU] || '',
  [faturalarServer.FATURA_ID]: faturaKarti[faturalarClient.FATURA_ID] || '',
  [faturaDetaylarServer.SIRA_NO]: selectedRow[faturaDetaylarClient.SIRA_NO],
  [faturaDetaylarServer.STOK_FISI_DETAY_ID]:
    selectedRow[faturaDetaylarClient.STOK_FISI_DETAY_ID] ?? '',
  [faturaDetaylarServer.FATURA_DETAY_ID]: selectedRow[faturaDetaylarClient.FATURA_DETAY_ID] ?? '',
  [faturaDetaylarServer.CARI_FISI_DETAY_STOK_ID]:
    selectedRow[faturaDetaylarClient.CARI_FISI_DETAY_STOK_ID] ?? '',
  [faturalarServer.DEPO_KODU]: faturaKarti[faturalarClient.DEPO_KODU] || '0',
  [faturaDetaylarServer.STOK_ID]: getValueOfPairSafely(
    payload[faturaDetaylarClient.STOK_CARI_ID],
    ''
  ),
  [faturaDetaylarServer.BIRIMI]: payload[faturaDetaylarClient.BIRIMI] || '',
  [faturaDetaylarServer.KDV]: payload[faturaDetaylarClient.KDV] || 0.0,
  [faturaDetaylarServer.OTV]: payload[faturaDetaylarClient.OTV] || 0.0,
  [faturaDetaylarServer.MIKTAR]: payload[faturaDetaylarClient.MIKTAR] || 0.0,
  [faturaDetaylarServer.FIYAT]: payload[faturaDetaylarClient.FIYAT] || 0.0,
  [faturaDetaylarServer.PARA_BIRIMI]: getValueOfPairSafely(
    payload[faturaDetaylarClient.PARA_BIRIMI_ID],
    '0'
  ),
});

export const requestUpdateFaturaRowAfterDeleteMapper = (
  musteriId,
  musteriAdi,
  musteriSifre,
  faturaKarti,
  selectedRow
) => ({
  musteri_id: musteriId,
  musteri_adi: musteriAdi,
  musteri_sifre: musteriSifre,
  [faturalarServer.FATURA_TURU]: faturaKarti[faturalarClient.FATURA_TURU] || '',
  [faturalarServer.FATURA_ID]: faturaKarti[faturalarClient.FATURA_ID] || '',
  [faturaDetaylarServer.SIRA_NO]: selectedRow[faturaDetaylarClient.SIRA_NO],
  [faturaDetaylarServer.STOK_FISI_DETAY_ID]:
    selectedRow[faturaDetaylarClient.STOK_FISI_DETAY_ID] ?? '',
  [faturaDetaylarServer.FATURA_DETAY_ID]: selectedRow[faturaDetaylarClient.FATURA_DETAY_ID] ?? '',
  [faturaDetaylarServer.CARI_FISI_DETAY_STOK_ID]:
    selectedRow[faturaDetaylarClient.CARI_FISI_DETAY_STOK_ID] ?? '',
  [faturalarServer.DEPO_KODU]: faturaKarti[faturalarClient.DEPO_KODU] || '0',
  [faturaDetaylarServer.STOK_ID]: selectedRow[faturaDetaylarClient.STOK_CARI_ID] || '0',
  [faturaDetaylarServer.BIRIMI]: selectedRow[faturaDetaylarClient.STOK_CARI_BIRIM] || '',
  [faturaDetaylarServer.KDV]: selectedRow[faturaDetaylarClient.KDV] || 0.0,
  [faturaDetaylarServer.OTV]: selectedRow[faturaDetaylarClient.OTV] || 0.0,
  [faturaDetaylarServer.MIKTAR]: selectedRow[faturaDetaylarClient.MIKTAR] || 0.0,
  [faturaDetaylarServer.FIYAT]: selectedRow[faturaDetaylarClient.FIYAT] || 0.0,
  [faturaDetaylarServer.PARA_BIRIMI]: selectedRow[faturaDetaylarClient.PARA_BIRIMI_ID] || '0',
});

export const requestDeleteFaturaRowMapper = (musteriId, musteriAdi, musteriSifre, payload) => ({
  musteri_id: musteriId,
  musteri_adi: musteriAdi,
  musteri_sifre: musteriSifre,
  [faturalarServer.FATURA_TURU]: payload[faturalarClient.FATURA_TURU] || '',
  [faturaDetaylarServer.STOK_FISI_DETAY_ID]: payload[faturaDetaylarClient.STOK_FISI_DETAY_ID] ?? '',
  [faturaDetaylarServer.FATURA_DETAY_ID]: payload[faturaDetaylarClient.FATURA_DETAY_ID] ?? '',
  [faturaDetaylarServer.CARI_FISI_DETAY_STOK_ID]:
    payload[faturaDetaylarClient.CARI_FISI_DETAY_STOK_ID] ?? '',
});
