import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { safelyParseJSON, decodeBase64 } from '../../helpers/utils';
import { pdfGeneratorFields, pdfGeneratorTypes } from '../../constants';
import TikirReportWrapperMobile from '../../components/TikirReport/TikirReportWrapperMobile/TikirReportWrapperMobile';
import TikirInvoiceWrapperMobile from '../../components/TikirReport/TikirInvoiceWrapperMobile/TikirInvoiceWrapperMobile';
import { fetchFaturaInvoiceForMobile } from '../../store/actions';

const PDFInvoiceGenerator = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const pdfInvoiceForMobile = useSelector((state) => state.raporlar.pdfInvoiceForMobile);
  const { payload } = params;

  const decodedPayload = decodeBase64(payload);

  const payloadObj = safelyParseJSON(decodedPayload);

  const { isSatis, settings } = payloadObj;

  /*
    Example payload:

    {
      type: 0,
      isSatis: true,
      timestamp: 2149449343,
      payload: {
        musteriId,
        musteriAdi,
        musteriSifre,
        faturaId
      },
      settings: {

      }
    }
  */

  useEffect(() => {
    if (payloadObj) {
      const { type, timestamp, payload } = payloadObj;
      if (type === pdfGeneratorTypes.TYPE_FATURA_INVOICE) {
        dispatch(fetchFaturaInvoiceForMobile(payload));
      }
    }
  }, [dispatch, payload]);

  if (!payloadObj || !pdfInvoiceForMobile) {
    return null;
  }

  return (
    <TikirInvoiceWrapperMobile
      fileName="Rapor.pdf"
      faturaKarti={pdfInvoiceForMobile}
      isSatis={isSatis}
      pdfSettings={settings}
    />
  );
};

export default PDFInvoiceGenerator;
