import { makbuzDetaylarClient } from '../../constants/makbuzlar';
import { formatMoney, parseAccountingSafely } from '../utils';

export const calculateTutar = (rows) => {
  let total = 0.0;
  for (let i = 0; i < rows.length; i += 1) {
    total += parseAccountingSafely(rows[i][makbuzDetaylarClient.TUTAR]);
  }
  return formatMoney(total);
};

export const calculateRowTutar = (miktar, fiyat, kdv, otv) => {
  const parsedMiktar = parseFloat(miktar.replace(',', '.')) || 0.0;
  const parsedFiyat = parseFloat(fiyat.replace(',', '.')) || 0.0;
  const parsedOtv = parseFloat(otv.replace(',', '.')) || 0.0;
  const parsedKdv = parseFloat(kdv.replace(',', '.')) || 0.0;

  let total = 0.0;
  const totalCostWithoutFees = parsedMiktar * parsedFiyat;
  const totalKdv = (totalCostWithoutFees * parsedKdv) / 100;
  const totalOtv = (totalKdv * parsedOtv) / 100;
  total += totalCostWithoutFees;
  total += totalKdv;
  total += totalOtv;
  return formatMoney(total);
};
