import moment from 'moment';
import accounting from 'accounting';
import { carilerClient, carilerServer } from '../../../../constants/cariler';

export const responseFetchCariKartlariMapper = (data) =>
  data.reduce((memo, el) => {
    if (el.CariKodu !== null && el.CariKodu !== '') {
      if (el.Bakiye === '') {
        el.Bakiye = '0,00';
      }
      el.Bakiye = accounting.formatMoney(
        parseFloat(el.Bakiye.replace(',', '.')).toFixed(2).toString(),
        '',
        2,
        '.',
        ','
      );
      memo.push(el);
    }
    return memo;
  }, []);

export const responseDateMapper = (data, dateField, tutarField) =>
  data.reduce((memo, el) => {
    if (el[dateField]) {
      el[dateField] = moment(el[dateField], 'yyyy-MM-DD HH:mm:ss.SSS').format(
        'DD.MM.yyyy HH:mm:ss'
      );
    }
    if (el[tutarField]) {
      if (el[tutarField] === '') {
        el[tutarField] = '0,00';
      }
      el[tutarField] = parseFloat(el[tutarField].replace(',', '.'))
        .toFixed(2)
        .toString()
        .replace('.', ',');
    }
    memo.push(el);
    return memo;
  }, []);

export const requestAddCariKartiMapper = (musteriId, musteriAdi, musteriSifre, payload) => ({
  musteri_id: musteriId,
  musteri_adi: musteriAdi,
  musteri_sifre: musteriSifre,
  [carilerServer.CARI_KODU]: payload[carilerClient.CARI_KODU] ?? '',
  [carilerServer.CARI_ADI]: payload[carilerClient.CARI_ADI] ?? '',
  [carilerServer.CARI_GRUBU]: payload[carilerClient.CARI_GRUBU] ?? '',
  [carilerServer.TELEFON]: payload[carilerClient.TELEFON] ?? '',
  [carilerServer.EMAIL]: payload[carilerClient.EMAIL] ?? '',
  [carilerServer.SEHIR]: payload[carilerClient.SEHIR]
    ? payload[carilerClient.SEHIR].value ?? ''
    : '',
  [carilerServer.VERGI_DAIRESI]: payload[carilerClient.VERGI_DAIRESI] ?? '',
  [carilerServer.VERGI_NUMARASI]: payload[carilerClient.VERGI_NUMARASI] ?? '',
  [carilerServer.VADE_GUN]: payload[carilerClient.VADE_GUN] ? payload[carilerClient.VADE_GUN] : 0,
  [carilerServer.CARI_ID]: payload[carilerClient.CARI_ID] ?? '',
});

export const requestUpdateCariKartiMapper = (musteriId, musteriAdi, musteriSifre, payload) =>
  requestAddCariKartiMapper(musteriId, musteriAdi, musteriSifre, payload);

export const responseFetchCariDetaylarMapper = (payload) => ({
  [carilerClient.CARI_KODU]: payload[0].Veriler,
  [carilerClient.CARI_ADI]: payload[1].Veriler,
  [carilerClient.CARI_ID]: payload[2].Veriler,
  [carilerClient.CARI_GRUBU]: payload[3].Veriler,
  [carilerClient.TELEFON]: payload[4].Veriler,
  [carilerClient.EMAIL]: payload[5].Veriler,
  [carilerClient.SEHIR]: payload[6].Veriler,
  [carilerClient.VERGI_DAIRESI]: payload[7].Veriler,
  [carilerClient.VERGI_NUMARASI]: payload[8].Veriler,
  [carilerClient.VADE_GUN]: (parseFloat(payload[9].Veriler.replace(',', '.')) || 0).toFixed(0),
});
