import * as actionTypes from './actionTypes';

//= ====================================
//            Stok Kartlari
//= ====================================
export const fetchStokKartlari = (siralama, arama, kayitBaslangic, kayitBitis) => ({
  type: actionTypes.FETCH_STOK_KARTLARI,
  payload: { siralama, arama, kayitBaslangic, kayitBitis },
});

export const fetchStokKartlariSuccess = (stokKartlari) => ({
  type: actionTypes.FETCH_STOK_KARTLARI_SUCCESS,
  payload: {
    stokKartlari,
  },
});

//= ====================================
//            Add Stok
//= ====================================
export const addStokKarti = (payload, history) => ({
  type: actionTypes.ADD_STOK_KARTI,
  payload,
  history,
});

//= ====================================
//            Stok Karti Detaylar
//= ====================================
export const fetchStokDetaylar = (stokId) => ({
  type: actionTypes.FETCH_STOK_DETAYLAR,
  payload: { stokId },
});

export const fetchStokDetaylarSuccess = (stokKarti) => ({
  type: actionTypes.FETCH_STOK_DETAYLAR_SUCCESS,
  payload: {
    stokKarti,
  },
});

export const fetchStokDetaylarFailure = (error) => ({
  type: actionTypes.FETCH_STOK_DETAYLAR_FAILURE,
  payload: { error },
});

//= ====================================
//            Stok Karti Delete
//= ====================================
export const deleteStokKarti = (stokId, history) => ({
  type: actionTypes.DELETE_STOK_KARTI,
  payload: { stokId },
  history,
});

//= ====================================
//            Update Stok Karti
//= ====================================
export const updateStokKarti = (payload, history, previousStokKodu) => ({
  type: actionTypes.UPDATE_STOK_KARTI,
  payload,
  history,
  previousStokKodu,
});
