import * as yup from 'yup';

export const responseFaturaDetaylarSchema = yup
  .array()
  .of(
    yup.object().shape({
      FaturaVeriler: yup.lazy((value) => {
        switch (typeof value) {
          case 'number':
            return yup.number();
          case 'string':
            return yup.string();
          default:
            return yup.mixed();
        }
      }),
    })
  )
  .required()
  .min(12);

export const responseFaturaEkleSchema = yup
  .array()
  .of(
    yup.object().shape({
      Veriler: yup.number().required(),
    })
  )
  .required()
  .min(3)
  .max(3);

export const responseFaturaGuncelleSchema = yup
  .array()
  .of(
    yup.object().shape({
      Veriler: yup.string().nullable(),
    })
  )
  .required()
  .min(3)
  .max(3);
