/* eslint-disable arrow-body-style */
import * as actionTypes from './actionTypes';
import updateObject from '../../utility';

const initialState = {
  faturaKartlari: [],
  faturaKartlariTableLoading: false,
  faturaKarti: null,
  selectedStokKarti: null,
  selectedHizmetKarti: null,
};

//= ====================================
//            Fatura Kartlari
//= ====================================
const fetchFaturaKartlari = (state) => {
  return updateObject(state, {
    faturaKartlari: [],
    faturaKartlariTableLoading: true,
    faturaKartlariMessage: null,
    faturaKarti: null,
    selectedStokKarti: null,
    selectedHizmetKarti: null,
  });
};

const fetchFaturaKartlariSuccess = (state, action) => {
  return updateObject(state, {
    faturaKartlari: action.payload.faturaKartlari,
    faturaKartlariTableLoading: false,
  });
};

const fetchFaturaKartlariFailure = (state) => {
  return updateObject(state, {
    faturaKartlari: null,
    faturaKartlariTableLoading: false,
  });
};

//= ====================================
//            Fatura Karti Detaylar
//= ====================================
const fetchFaturaKartiDetaylar = (state) => {
  return updateObject(state, {
    faturaKarti: null,
    selectedStokKarti: null,
    selectedHizmetKarti: null,
  });
};

const fetchFaturaKartiDetaylarSuccess = (state, action) => {
  return updateObject(state, {
    faturaKarti: action.payload.faturaKarti,
  });
};

const fetchFaturaKartiDetaylarFailure = (state) => {
  return updateObject(state, {
    faturaKarti: null,
  });
};

//= ====================================
//            Select Stok Karti For Fatura Row
//= ====================================
const selectStokKartiOnFaturaRow = (state, action) => {
  return updateObject(state, {
    selectedStokKarti: action.payload.selectedStokKarti,
  });
};

//= ====================================
//            Select Hizmet Karti For Fatura Row
//= ====================================
const selectHizmetKartiOnFaturaRow = (state, action) => {
  return updateObject(state, {
    selectedHizmetKarti: action.payload.selectedHizmetKarti,
  });
};

const faturalar = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_FATURA_KARTLARI:
      return fetchFaturaKartlari(state);
    case actionTypes.FETCH_FATURA_KARTLARI_SUCCESS:
      return fetchFaturaKartlariSuccess(state, action);
    case actionTypes.FETCH_FATURA_KARTLARI_FAILURE:
      return fetchFaturaKartlariFailure(state, action);
    case actionTypes.FETCH_FATURA_DETAYLAR:
      return fetchFaturaKartiDetaylar(state);
    case actionTypes.FETCH_FATURA_DETAYLAR_SUCCESS:
      return fetchFaturaKartiDetaylarSuccess(state, action);
    case actionTypes.FETCH_FATURA_DETAYLAR_FAILURE:
      return fetchFaturaKartiDetaylarFailure(state);
    case actionTypes.SELECT_FATURA_ROW_STOK:
      return selectStokKartiOnFaturaRow(state, action);
    case actionTypes.SELECT_FATURA_ROW_HIZMET:
      return selectHizmetKartiOnFaturaRow(state, action);
    default:
      return state;
  }
};

export default faturalar;
