import * as actionTypes from './actionTypes';

//= ====================================
//            Hizmet Kartlari
//= ====================================
export const fetchHizmetKartlari = (siralama, arama, kayitBaslangic, kayitBitis) => ({
  type: actionTypes.FETCH_HIZMET_KARTLARI,
  payload: { siralama, arama, kayitBaslangic, kayitBitis },
});

export const fetchHizmetKartlariSuccess = (hizmetKartlari) => ({
  type: actionTypes.FETCH_HIZMET_KARTLARI_SUCCESS,
  payload: {
    hizmetKartlari,
  },
});

//= ====================================
//            Add Hizmet
//= ====================================
export const addHizmetKarti = (payload, history) => ({
  type: actionTypes.ADD_HIZMET_KARTI,
  payload,
  history,
});

//= ====================================
//            Hizmet Karti Detaylar
//= ====================================
export const fetchHizmetDetaylar = (hizmetId) => ({
  type: actionTypes.FETCH_HIZMET_DETAYLAR,
  payload: { hizmetId },
});

export const fetchHizmetDetaylarSuccess = (hizmetKarti) => ({
  type: actionTypes.FETCH_HIZMET_DETAYLAR_SUCCESS,
  payload: {
    hizmetKarti,
  },
});

export const fetchHizmetDetaylarFailure = (error) => ({
  type: actionTypes.FETCH_HIZMET_DETAYLAR_FAILURE,
  payload: { error },
});

//= ====================================
//            Hizmet Karti Delete
//= ====================================
export const deleteHizmetKarti = (hizmetId, history) => ({
  type: actionTypes.DELETE_HIZMET_KARTI,
  payload: { hizmetId },
  history,
});

//= ====================================
//            Update Hizmet Karti
//= ====================================
export const updateHizmetKarti = (payload, history, previousHizmetKodu) => ({
  type: actionTypes.UPDATE_HIZMET_KARTI,
  payload,
  history,
  previousHizmetKodu,
});
