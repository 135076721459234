import React from 'react';
import { Document, Page, Text, StyleSheet, View, Font } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import { formatMoney, formatMoneyFromMoney } from '../../helpers/utils';
import {
  calculateKdvTutar,
  calculateOtvTutar,
  calculateToplamTutar,
  calculateTutar,
} from '../../helpers/faturalar';

const BORDER_COLOR = '#000';
const BORDER_STYLE = 'solid';
const COL1_WIDTH = 50;
const COLN_WIDTH = (100 - COL1_WIDTH) / 4;
const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    fontFamily: 'Roboto',
  },
  title: {
    textAlign: 'center',
    fontFamily: 'Roboto-Bold',
    marginBottom: 10,
    paddingVertical: 3,
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
  },
  text: {
    margin: 12,
    textAlign: 'justify',
    fontFamily: 'Roboto',
  },
  image: {
    marginVertical: 0,
    marginHorizontal: 200,
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 10,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  // body: {
  //   padding: 10
  // },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginBottom: 30,
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
  },
  headerRow: {
    flexDirection: 'row',
    marginBottom: 10,
  },
  tableColHeader: {
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderBottomColor: '#000',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol: {
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCellHeader: {
    margin: 2,
    fontWeight: 500,
    fontFamily: 'Roboto-Bold',
  },
  tableCell: {
    margin: 2,
    fontFamily: 'Roboto',
  },

  // Table Result (footer)
  tableResult: {
    display: 'table',
    width: 'auto',
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableResultCol: {
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableResultTitleCol: {
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableResultTitleCell: {
    margin: 2,
    fontWeight: 500,
    fontFamily: 'Roboto-Bold',
    textAlign: 'right',
  },
  tableResultCell: {
    margin: 2,
    fontFamily: 'Roboto',
    textAlign: 'right',
  },

  // Table Header
  tableHeaderLeftCol: {
    width: '60%',
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableHeaderRightCol: {
    width: '40%',
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableHeaderEmptyCol: {
    width: '40%',
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 0,
    borderRightWidth: 1,
  },
  tableHeaderEmptyBottomCol: {
    width: '40%',
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 0,
    borderRightWidth: 1,
    borderBottomWidth: 1,
  },
  tableHeaderCell: {
    margin: 2,
  },
  textBold: {
    fontFamily: 'Roboto-Bold',
    fontWeight: 500,
  },
  textRegular: {
    fontFamily: 'Roboto',
  },
  alignLeft: {
    textAlign: 'left',
  },
  alignRight: {
    textAlign: 'right',
  },
  marginLarge: {
    marginBottom: 8,
  },
});

Font.register({
  family: 'Roboto',
  src: 'https://fonts.gstatic.com/s/roboto/v20/KFOmCnqEu92Fr1Me5WZNCzc.ttf',
});

Font.register({
  family: 'Roboto-Bold',
  src: 'https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmWUlvAx0_IsE.ttf',
});

const propTypes = {
  faturaKarti: PropTypes.object.isRequired,
  faturaType: PropTypes.object.isRequired,
  pdfSettings: PropTypes.object.isRequired,
};
const TikirInvoice = (props) => {
  const { faturaKarti, faturaType, pdfSettings } = props;
  const faturaName = !faturaType.isSatis ? 'ALIŞ FATURASI' : 'SATIŞ FATURASI';
  console.log(JSON.stringify(pdfSettings));
  styles.tableHeaderLeftCol.width = `${pdfSettings.isimAdresGenislik}%`;
  styles.tableHeaderRightCol.width = `${pdfSettings.tarihSaatGenislik}%`;
  styles.tableHeaderEmptyCol.width = `${pdfSettings.tarihSaatGenislik}%`;
  styles.tableHeaderEmptyBottomCol.width = `${pdfSettings.tarihSaatGenislik}%`;
  styles.tableResultTitleCol.width = `${pdfSettings.solKisimGenislik}%`;
  styles.tableResultCol.width = `${pdfSettings.sagKisimGenislik}%`;

  return (
    <Document>
      <Page
        style={[
          styles.body,
          {
            paddingTop: pdfSettings.ustBosluk,
            paddingBottom: pdfSettings.altBosluk,
            paddingRight: pdfSettings.sagBosluk,
            paddingLeft: pdfSettings.solBosluk,
            fontSize: pdfSettings.yaziFontu,
          },
        ]}
      >
        <Text style={styles.title}>{`${faturaKarti.faturaNo} NO'LU ${faturaName}`}</Text>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableHeaderLeftCol}>
              <Text
                style={[
                  styles.tableHeaderCell,
                  styles.textBold,
                  {
                    marginLeft: pdfSettings.isimSolBosluk,
                    marginBottom: pdfSettings.isimUstBosluk,
                  },
                ]}
              >
                Sayın:
              </Text>
            </View>
            <View style={styles.tableHeaderRightCol}>
              <Text style={[styles.tableHeaderCell, styles.textBold]}>Fatura Tarihi:</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableHeaderLeftCol}>
              <Text style={[styles.tableHeaderCell, styles.textRegular]}>
                {faturaKarti.cariAdi}
              </Text>
            </View>
            <View style={styles.tableHeaderRightCol}>
              <Text
                style={[
                  styles.tableHeaderCell,
                  styles.textRegular,
                  {
                    marginRight: pdfSettings.tarihSagBosluk,
                    marginBottom: pdfSettings.tarihUstBosluk,
                  },
                ]}
              >
                {faturaKarti.tarih}
              </Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableHeaderLeftCol}>
              <Text
                style={[
                  styles.tableHeaderCell,
                  styles.textBold,
                  styles.marginLarge,
                  {
                    marginLeft: pdfSettings.adresSolBosluk,
                    marginBottom: pdfSettings.adresUstBosluk,
                  },
                ]}
              >
                Adres:
              </Text>
            </View>
            <View style={styles.tableHeaderEmptyCol}>
              <Text style={[styles.tableHeaderCell, styles.textRegular]} />
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableHeaderLeftCol}>
              <Text
                style={[
                  styles.tableHeaderCell,
                  styles.textBold,
                  styles.marginLarge,
                  {
                    marginLeft: pdfSettings.vergiDairesiSolBosluk,
                    marginBottom: pdfSettings.vergiDairesiUstBosluk,
                  },
                ]}
              >
                Vergi Dairesi / Numarası:
              </Text>
            </View>
            <View style={styles.tableHeaderEmptyCol}>
              <Text style={[styles.tableHeaderCell, styles.textRegular]} />
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableHeaderLeftCol}>
              <Text style={[styles.tableHeaderCell, styles.textRegular]}>/</Text>
            </View>
            <View style={styles.tableHeaderEmptyBottomCol}>
              <Text style={[styles.tableHeaderCell, styles.textRegular]} />
            </View>
          </View>
        </View>
        <View
          style={[
            styles.table,

            {
              marginTop: pdfSettings.ustBolumdenUzaklik,
            },
          ]}
        >
          <View style={styles.tableRow}>
            <View
              style={[
                styles.tableColHeader,
                {
                  width: `${pdfSettings.aciklamaGenislik}%`,
                },
              ]}
            >
              <Text style={styles.tableCellHeader}>AÇIKLAMA</Text>
            </View>
            <View
              style={[
                styles.tableColHeader,
                {
                  width: `${pdfSettings.kdvGenislik}%`,
                },
              ]}
            >
              <Text style={styles.tableCellHeader}>KDV</Text>
            </View>
            <View
              style={[
                styles.tableColHeader,
                {
                  width: `${pdfSettings.miktarGenislik}%`,
                },
              ]}
            >
              <Text style={styles.tableCellHeader}>MİKTARI</Text>
            </View>
            <View
              style={[
                styles.tableColHeader,
                {
                  width: `${pdfSettings.birimFiyatGenislik}%`,
                },
              ]}
            >
              <Text style={styles.tableCellHeader}>BİRİM FİYATI</Text>
            </View>
            <View
              style={[
                styles.tableColHeader,
                {
                  width: `${pdfSettings.tutarGenislik}%`,
                },
              ]}
            >
              <Text style={styles.tableCellHeader}>TUTARI</Text>
            </View>
          </View>
          {faturaKarti.rows.map((el) => (
            <View style={styles.tableRow}>
              <View
                style={[
                  styles.tableCol,
                  styles.tableColHeader,
                  {
                    width: `${pdfSettings.aciklamaGenislik}%`,
                  },
                ]}
              >
                <Text style={styles.tableCell}>{el.stokCariAdi}</Text>
              </View>
              <View
                style={[
                  styles.tableCol,
                  styles.tableColHeader,
                  {
                    width: `${pdfSettings.kdvGenislik}%`,
                  },
                ]}
              >
                <Text style={styles.tableCell}>{formatMoneyFromMoney(el.kdv)}</Text>
              </View>
              <View
                style={[
                  styles.tableCol,
                  styles.tableColHeader,
                  {
                    width: `${pdfSettings.miktarGenislik}%`,
                  },
                ]}
              >
                <Text style={styles.tableCell}>{formatMoneyFromMoney(el.miktar)}</Text>
              </View>
              <View
                style={[
                  styles.tableCol,
                  styles.tableColHeader,
                  {
                    width: `${pdfSettings.birimFiyatGenislik}%`,
                  },
                ]}
              >
                <Text style={styles.tableCell}>{formatMoneyFromMoney(el.fiyat)}</Text>
              </View>
              <View
                style={[
                  styles.tableCol,
                  styles.tableColHeader,
                  {
                    width: `${pdfSettings.tutarGenislik}%`,
                  },
                ]}
              >
                <Text style={styles.tableCell}>{formatMoneyFromMoney(el.tutar)}</Text>
              </View>
            </View>
          ))}
        </View>
        <View style={styles.tableResult}>
          <View style={styles.tableRow}>
            <View style={styles.tableResultTitleCol}>
              <Text
                style={[
                  styles.tableResultTitleCell,
                  {
                    marginRight: pdfSettings.toplamTutarSagBosluk,
                    marginBottom: pdfSettings.toplamTutarUstBosluk,
                  },
                ]}
              >
                Toplam Tutar:
              </Text>
            </View>
            <View style={styles.tableResultCol}>
              <Text
                style={[
                  styles.tableResultCell,
                  {
                    marginRight: pdfSettings.toplamTutarSagBosluk,
                    marginBottom: pdfSettings.toplamTutarUstBosluk,
                  },
                ]}
              >
                {calculateTutar(faturaKarti.rows)}
              </Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableResultTitleCol}>
              <Text
                style={[
                  styles.tableResultTitleCell,
                  {
                    marginRight: pdfSettings.kdvTutariSagBosluk,
                    marginBottom: pdfSettings.kdvTutariUstBosluk,
                  },
                ]}
              >
                KDV Tutar:
              </Text>
            </View>
            <View style={styles.tableResultCol}>
              <Text
                style={[
                  styles.tableResultCell,
                  {
                    marginRight: pdfSettings.kdvTutariSagBosluk,
                    marginBottom: pdfSettings.kdvTutariUstBosluk,
                  },
                ]}
              >
                {calculateKdvTutar(faturaKarti.rows)}
              </Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableResultTitleCol}>
              <Text
                style={[
                  styles.tableResultTitleCell,
                  {
                    marginRight: pdfSettings.otvTutariSagBosluk,
                    marginBottom: pdfSettings.otvTutariUstBosluk,
                  },
                ]}
              >
                OTV Tutar:
              </Text>
            </View>
            <View style={styles.tableResultCol}>
              <Text
                style={[
                  styles.tableResultCell,
                  {
                    marginRight: pdfSettings.otvTutariSagBosluk,
                    marginBottom: pdfSettings.otvTutariUstBosluk,
                  },
                ]}
              >
                {calculateOtvTutar(faturaKarti.rows)}
              </Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableResultTitleCol}>
              <Text
                style={[
                  styles.tableResultTitleCell,
                  {
                    marginRight: pdfSettings.genelTutarSagBosluk,
                    marginBottom: pdfSettings.genelTutarUstBosluk,
                  },
                ]}
              >
                Genel Tutar:
              </Text>
            </View>
            <View style={styles.tableResultCol}>
              <Text
                style={[
                  styles.tableResultCell,
                  {
                    marginRight: pdfSettings.genelTutarSagBosluk,
                    marginBottom: pdfSettings.genelTutarUstBosluk,
                  },
                ]}
              >
                {calculateToplamTutar(faturaKarti.rows)}
              </Text>
            </View>
          </View>
        </View>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
          fixed
        />
      </Page>
    </Document>
  );
};

const data = [
  {
    FaturaTarihi: '2021-02-21',
    FaturaNo: 'xsds',
    CariAdi: 'SAGA TEKSTİL',
    StokAdi: 'YAKIT',
    Miktar: '1.00',
    Yekun: '2332.00',
  },
  {
    FaturaTarihi: '2021-01-21',
    FaturaNo: 'fw23',
    CariAdi: 'PETROL',
    StokAdi: 'YAKIT',
    Miktar: '10.00',
    Yekun: '322.00',
  },
];

TikirInvoice.propTypes = propTypes;
export default TikirInvoice;
