import moment from 'moment';
import accounting from 'accounting';
import { stoklarClient, stoklarServer } from '../../../../constants/stoklar';
import { formatMoney } from '../../../../helpers/utils';

export const responseFetchStokKartlariMapper = (data) =>
  data.reduce((memo, el) => {
    if (el[stoklarServer.STOK_KODU] !== null && el[stoklarServer.STOK_KODU] !== '') {
      if (el[stoklarServer.SATIS_FIYATI] === '') {
        el[stoklarServer.SATIS_FIYATI] = '0,00';
      }
      el[stoklarServer.SATIS_FIYATI] = accounting.formatMoney(
        parseFloat(el[stoklarServer.SATIS_FIYATI].replace(',', '.')),
        '',
        2,
        '.',
        ','
      );

      memo.push(el);
    }
    return memo;
  }, []);

export const responseDateMapper = (data, dateField) =>
  data.reduce((memo, el) => {
    if (el[dateField]) {
      el[dateField] = moment(el[dateField], 'yyyy-MM-DD HH:mm:ss.SSS').format(
        'DD.MM.yyyy HH:mm:ss'
      );
    }
    memo.push(el);
    return memo;
  }, []);

export const requestAddStokKartiMapper = (musteriId, musteriAdi, musteriSifre, payload) => ({
  musteri_id: musteriId,
  musteri_adi: musteriAdi,
  musteri_sifre: musteriSifre,
  [stoklarServer.STOK_KODU]: payload[stoklarClient.STOK_KODU] ?? '',
  [stoklarServer.STOK_ADI]: payload[stoklarClient.STOK_ADI] ?? '',
  [stoklarServer.STOK_GRUBU]: payload[stoklarClient.STOK_GRUBU] ?? '',
  [stoklarServer.BARKOD]: payload[stoklarClient.BARKOD] ?? '',
  [stoklarServer.BIRIMI]: payload[stoklarClient.BIRIMI] ?? '',
  [stoklarServer.PARA_BIRIMI]: payload[stoklarClient.PARA_BIRIMI]
    ? payload[stoklarClient.PARA_BIRIMI].value ?? '0'
    : '0',
  [stoklarServer.ALIS_FIYATI]: payload[stoklarClient.ALIS_FIYATI] || '0.0',
  [stoklarServer.SATIS_FIYATI]: payload[stoklarClient.SATIS_FIYATI] || '0.0',
  [stoklarServer.KDV]: payload[stoklarClient.KDV] || '0.0',
  [stoklarServer.STOK_ID]: payload[stoklarClient.STOK_ID] ?? '',
});

export const requestUpdateStokKartiMapper = (musteriId, musteriAdi, musteriSifre, payload) =>
  requestAddStokKartiMapper(musteriId, musteriAdi, musteriSifre, payload);

export const responseFetchStokDetaylarMapper = (payload) => ({
  [stoklarClient.STOK_ID]: payload[0].StokVeriler,
  [stoklarClient.STOK_KODU]: payload[1].StokVeriler,
  [stoklarClient.STOK_ADI]: payload[2].StokVeriler,
  [stoklarClient.BIRIMI]: payload[3].StokVeriler,
  [stoklarClient.STOK_GRUBU]: payload[4].StokVeriler,
  [stoklarClient.STOK_GRUBU_ID]: payload[5].StokVeriler,
  [stoklarClient.KDV]: formatMoney(parseFloat(payload[6].StokVeriler.replace(',', '.')) || 0.0),
  [stoklarClient.ALIS_FIYATI]: formatMoney(
    parseFloat(payload[7].StokVeriler.replace(',', '.')) || 0.0
  ),
  [stoklarClient.SATIS_FIYATI]: formatMoney(
    parseFloat(payload[8].StokVeriler.replace(',', '.')) || 0.0
  ),
  [stoklarClient.PARA_BIRIMI]: payload[9].StokVeriler,
  [stoklarClient.PARA_BIRIMI_ADI]: payload[10].StokVeriler,
  [stoklarClient.BARKOD]: payload[11].StokVeriler,
});
