import * as yup from 'yup';

export const responseMakbuzDetaylarSchema = yup
  .array()
  .of(
    yup.object().shape({
      MakbuzVeriler: yup.lazy((value) => {
        switch (typeof value) {
          case 'number':
            return yup.number();
          case 'string':
            return yup.string();
          default:
            return yup.mixed();
        }
      }),
    })
  )
  .required()
  .min(12);

export const responseMakbuzGuncelleSchema = yup
  .array()
  .of(
    yup.object().shape({
      Veriler: yup.string().nullable(),
    })
  )
  .required()
  .min(3)
  .max(3);
