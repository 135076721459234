import { combineReducers } from 'redux';
import auth from './auth/reducer';
import layout from './layout/reducer';
import cariler from './modules/cariler/reducer';
import stoklar from './modules/stoklar/reducer';
import hizmetler from './modules/hizmetler/reducer';
import faturalar from './modules/faturalar/reducer';
import makbuzlar from './modules/makbuzlar/reducer';
import raporlar from './modules/raporlar/reducer';
import others from './modules/others/reducer';

const rootReducer = combineReducers({
  auth,
  layout,
  cariler,
  stoklar,
  hizmetler,
  faturalar,
  makbuzlar,
  raporlar,
  others,
});
export default rootReducer;
