export const FETCH_STOK_KARTLARI = 'FETCH_STOK_KARTLARI';
export const FETCH_STOK_KARTLARI_SUCCESS = 'FETCH_STOK_KARTLARI_SUCCESS';
export const FETCH_STOK_KARTLARI_FAILURE = 'FETCH_STOK_KARTLARI_FAILURE';

export const ADD_STOK_KARTI = 'ADD_STOK_KARTI';

export const UPDATE_STOK_KARTI = 'UPDATE_STOK_KARTI';

export const DELETE_STOK_KARTI = 'DELETE_STOK_KARTI';

export const FETCH_STOK_DETAYLAR = 'FETCH_STOK_DETAYLAR';
export const FETCH_STOK_DETAYLAR_SUCCESS = 'FETCH_STOK_DETAYLAR_SUCCESS';
export const FETCH_STOK_DETAYLAR_FAILURE = 'FETCH_STOK_DETAYLAR_FAILURE';
