import {
  cilApplicationsSettings,
  cilSpeedometer,
  cilSun,
  cilMoon,
  cilBriefcase,
  cilUser,
  cilAccountLogout,
  cilZoom,
  cilCreditCard,
  cilLibraryAdd,
  cilPlus,
  cilSave,
  cilDelete,
  cilCloudDownload,
} from '@coreui/icons';
import { logoBlack } from './logo-black';
import {
  cariler,
  stoklar,
  hizmetler,
  faturalar,
  tahsilatlar,
  tediyeler,
  detayliRaporlar,
  gunlukRaporlar,
  alislar,
  satislar,
  makbuzlar,
  kasa,
} from './tikir';
import { logoWhite } from './logo-white';
import { sygnet } from './sygnet';

const icons = {
  sygnet,
  cilApplicationsSettings,
  cilSpeedometer,
  cilBriefcase,
  cilSun,
  cilMoon,
  cilUser,
  cilAccountLogout,
  cilZoom,
  cilCreditCard,
  cilLibraryAdd,
  cilPlus,
  cilSave,
  cilDelete,
  cilCloudDownload,
  logoBlack,
  logoWhite,
  cariler,
  stoklar,
  hizmetler,
  faturalar,
  tahsilatlar,
  tediyeler,
  detayliRaporlar,
  gunlukRaporlar,
  alislar,
  satislar,
  makbuzlar,
  kasa,
};

export default icons;
