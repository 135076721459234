/* eslint-disable func-names */
/* eslint-disable prefer-rest-params */
/* eslint-disable no-extend-native */
import axios from 'axios';
import { config } from '../constants';

axios.defaults.baseURL = config.API_BASE_URL;

const methods = {
  GET: 'get',
  POST: 'post',
};

const apiCall = async (url, method = methods.GET, headers = {}) => {
  const result = await axios({
    url,
    method,
    headers,
  });
  return result;
};

const apiCallPost = async (
  url,
  data,
  headers = { 'Content-Type': 'application/x-www-form-urlencoded' }
) => {
  const method = methods.POST;
  const result = await axios({
    url,
    method,
    data,
    headers,
  });
  return result;
};

String.prototype.format = function () {
  const args = [].slice.call(arguments);
  return this.replace(/(\{\d+\})/g, (a) => args[+a.substr(1, a.length - 2) || 0]);
};

export { methods, apiCall, apiCallPost };
