/* eslint-disable arrow-body-style */
import * as actionTypes from './actionTypes';
import updateObject from '../../utility';

const initialState = {
  hizmetKartlari: [],
  hizmetKartlariTableLoading: false,
  hizmetKarti: null,
};

//= ====================================
//            Hizmet Kartlari
//= ====================================
const fetchHizmetKartlari = (state) => {
  return updateObject(state, {
    hizmetKartlari: [],
    hizmetKartlariTableLoading: true,
    hizmetKartlariMessage: null,
    hizmetKarti: null,
  });
};

const fetchHizmetKartlariSuccess = (state, action) => {
  return updateObject(state, {
    hizmetKartlari: action.payload.hizmetKartlari,
    hizmetKartlariTableLoading: false,
  });
};

const fetchHizmetKartlariFailure = (state) => {
  return updateObject(state, {
    hizmetKartlari: null,
    hizmetKartlariTableLoading: false,
  });
};

//= ====================================
//            Hizmet Karti Detaylar
//= ====================================
const fetchHizmetKartiDetaylar = (state) => {
  return updateObject(state, {
    hizmetKarti: {},
  });
};

const fetchHizmetKartiDetaylarSuccess = (state, action) => {
  return updateObject(state, {
    hizmetKarti: action.payload.hizmetKarti,
  });
};

const fetchHizmetKartiDetaylarFailure = (state) => {
  return updateObject(state, {
    hizmetKarti: null,
  });
};

const hizmetler = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_HIZMET_KARTLARI:
      return fetchHizmetKartlari(state);
    case actionTypes.FETCH_HIZMET_KARTLARI_SUCCESS:
      return fetchHizmetKartlariSuccess(state, action);
    case actionTypes.FETCH_HIZMET_KARTLARI_FAILURE:
      return fetchHizmetKartlariFailure(state, action);
    case actionTypes.FETCH_HIZMET_DETAYLAR:
      return fetchHizmetKartiDetaylar(state);
    case actionTypes.FETCH_HIZMET_DETAYLAR_SUCCESS:
      return fetchHizmetKartiDetaylarSuccess(state, action);
    case actionTypes.FETCH_HIZMET_DETAYLAR_FAILURE:
      return fetchHizmetKartiDetaylarFailure(state);
    default:
      return state;
  }
};

export default hizmetler;
