import { apiCall, apiCallPost, methods } from '../api';

const urls = {
  FETCH_MAKBUZ_KARTLARI_URL:
    '/Makbuzlar?musteri_id={0}&musteri_adi={1}&musteri_sifre={2}&Siralama={3}&Arama={4}&KayitBaslangic={5}&KayitBitis={6}&MakbuzTuru={7}',
  FETCH_MAKBUZ_KARTI_DETAY_URL:
    '/MakbuzDetay?musteri_id={0}&musteri_adi={1}&musteri_sifre={2}&MakbuzID={3}',
  UPDATE_MAKBUZ_KARTI_URL: '/MakbuzGuncelleDeneme',
  ADD_MAKBUZ_KARTI_URL: '/MakbuzEkleDeneme',
  DELETE_MAKBUZ_KARTI_URL: '/MakbuzSil',
  CHECK_MAKBUZ_NO_URL:
    '/MakbuzNoKontrol?musteri_id={0}&musteri_adi={1}&musteri_sifre={2}&MakbuzNo={3}',
  UPDATE_MAKBUZ_DETAYLAR_URL: '/MakbuzGuncelleDetaylar',
  DELETE_MAKBUZ_DETAYLAR_URL:
    '/MakbuzGuncelleDetaylarSil',
};

export const fetchMakbuzKartlari = (
  musteriId,
  musteriAdi,
  musteriSifre,
  siralama,
  arama,
  kayitBaslangic,
  kayitBitis,
  makbuzTuru
) =>
  apiCall(
    urls.FETCH_MAKBUZ_KARTLARI_URL.format(
      musteriId,
      musteriAdi,
      musteriSifre,
      siralama,
      arama,
      kayitBaslangic,
      kayitBitis,
      makbuzTuru
    ),
    methods.GET
  );

export const addMakbuzKarti = (payload) => {
  const data = new URLSearchParams();
  Object.keys(payload).forEach((key) => {
    data.append(key, payload[key]);
  });
  return apiCallPost(urls.ADD_MAKBUZ_KARTI_URL, data);
};

export const checkMakbuzNo = (musteriId, musteriAdi, musteriSifre, makbuzNo) =>
  apiCall(
    urls.CHECK_MAKBUZ_NO_URL.format(musteriId, musteriAdi, musteriSifre, makbuzNo),
    methods.GET
  );

export const fetchMakbuzDetaylar = (musteriId, musteriAdi, musteriSifre, makbuzId) =>
  apiCall(
    urls.FETCH_MAKBUZ_KARTI_DETAY_URL.format(musteriId, musteriAdi, musteriSifre, makbuzId),
    methods.GET
  );

export const deleteMakbuzKarti = (musteriId, musteriAdi, musteriSifre, makbuzId) => {
  const data = new URLSearchParams();
  data.append('musteri_id', musteriId);
  data.append('musteri_adi', musteriAdi);
  data.append('musteri_sifre', musteriSifre);
  data.append('MakbuzID', makbuzId);
  return apiCallPost(urls.DELETE_MAKBUZ_KARTI_URL, data);
};

export const updateMakbuzKarti = (payload) => {
  const data = new URLSearchParams();
  Object.keys(payload).forEach((key) => {
    data.append(key, payload[key]);
  });
  return apiCallPost(urls.UPDATE_MAKBUZ_KARTI_URL, data);
};

export const deleteMakbuzDetaylar = (payload) => {
  const data = new URLSearchParams();
  Object.keys(payload).forEach((key) => {
    data.append(key, payload[key]);
  });
  return apiCallPost(urls.DELETE_MAKBUZ_DETAYLAR_URL, data);
};

export const updateMakbuzDetaylar = (payload) => {
  const data = new URLSearchParams();
  Object.keys(payload).forEach((key) => {
    data.append(key, payload[key]);
  });
  return apiCallPost(urls.UPDATE_MAKBUZ_DETAYLAR_URL, data);
};
