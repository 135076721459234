import { apiCall, apiCallPost, methods } from '../api';

const urls = {
  FETCH_CARI_KARTLARI_URL:
    '/CariKartlari?musteri_id={0}&musteri_adi={1}&musteri_sifre={2}&Siralama={3}&Arama={4}&KayitBaslangic={5}&KayitBitis={6}',
  ADD_CARI_KARTI_URL: '/CariKartiEkle',
  CHECK_CARI_KODU_URL:
    '/CariKoduKontrol?musteri_id={0}&musteri_adi={1}&musteri_sifre={2}&CariKodu={3}',
  FETCH_CARI_KARTI_DETAY_URL:
    '/CariKartiDetaylar?musteri_id={0}&musteri_adi={1}&musteri_sifre={2}&CariID={3}',
  FETCH_CARI_FATURALAR_URL:
    '/CariFaturalar?musteri_id={0}&musteri_adi={1}&musteri_sifre={2}&CariID={3}',
  FETCH_CARI_MAKBUZLAR_URL:
    '/CariMakbuzlar?musteri_id={0}&musteri_adi={1}&musteri_sifre={2}&CariID={3}',
  CHECK_CARI_FISI_URL:
    '/CariFisiKontrol?musteri_id={0}&musteri_adi={1}&musteri_sifre={2}&CariID={3}',
  FETCH_CARI_KARTI_SIL_URL: '/CariKartiSil',
  UPDATE_CARI_KARTI_URL: '/CariKartiGuncelle',
};

export const fetchCariKartlari = (
  musteriId,
  musteriAdi,
  musteriSifre,
  siralama,
  arama,
  kayitBaslangic,
  kayitBitis
) =>
  apiCall(
    urls.FETCH_CARI_KARTLARI_URL.format(
      musteriId,
      musteriAdi,
      musteriSifre,
      siralama,
      arama,
      kayitBaslangic,
      kayitBitis
    ),
    methods.GET
  );

export const addCariKarti = (payload) => {
  const data = new URLSearchParams();
  Object.keys(payload).forEach((key) => {
    data.append(key, payload[key]);
  });
  return apiCallPost(urls.ADD_CARI_KARTI_URL, data);
};

export const checkCariKodu = (musteriId, musteriAdi, musteriSifre, cariKodu) =>
  apiCall(
    urls.CHECK_CARI_KODU_URL.format(musteriId, musteriAdi, musteriSifre, cariKodu),
    methods.GET
  );

export const fetchCariDetaylar = (musteriId, musteriAdi, musteriSifre, cariId) =>
  apiCall(
    urls.FETCH_CARI_KARTI_DETAY_URL.format(musteriId, musteriAdi, musteriSifre, cariId),
    methods.GET
  );

export const fetchCariFaturalar = (musteriId, musteriAdi, musteriSifre, cariId) =>
  apiCall(
    urls.FETCH_CARI_FATURALAR_URL.format(musteriId, musteriAdi, musteriSifre, cariId),
    methods.GET
  );

export const fetchCariMakbuzlar = (musteriId, musteriAdi, musteriSifre, cariId) =>
  apiCall(
    urls.FETCH_CARI_MAKBUZLAR_URL.format(musteriId, musteriAdi, musteriSifre, cariId),
    methods.GET
  );

export const deleteCariKarti = (musteriId, musteriAdi, musteriSifre, cariId) => {
  const data = new URLSearchParams();
  data.append('musteri_id', musteriId);
  data.append('musteri_adi', musteriAdi);
  data.append('musteri_sifre', musteriSifre);
  data.append('CariID', cariId);
  return apiCallPost(urls.FETCH_CARI_KARTI_SIL_URL, data);
};

export const checkCariFisi = (musteriId, musteriAdi, musteriSifre, cariId) =>
  apiCall(
    urls.CHECK_CARI_FISI_URL.format(musteriId, musteriAdi, musteriSifre, cariId),
    methods.GET
  );

export const updateCariKarti = (payload) => {
  const data = new URLSearchParams();
  Object.keys(payload).forEach((key) => {
    data.append(key, payload[key]);
  });
  return apiCallPost(urls.UPDATE_CARI_KARTI_URL, data);
};
