import moment from 'moment';
import accounting from 'accounting';
import {
  getValueOfPairSafely,
  formatDateFromClientToServer,
  formatMoney,
} from '../../../../helpers/utils';
import {
  makbuzlarClient,
  makbuzlarServer,
  makbuzDetaylarClient,
  makbuzDetaylarServer,
} from '../../../../constants/makbuzlar';

export const responseFetchMakbuzKartlariMapper = (data) =>
  data.reduce((memo, el) => {
    if (el[makbuzlarServer.MAKBUZ_NO] !== null && el[makbuzlarServer.MAKBUZ_NO] !== '') {
      if (el[makbuzlarServer.TUTAR] === '') {
        el[makbuzlarServer.TUTAR] = '0,00';
      }
      el[makbuzlarServer.TUTAR] = accounting.formatMoney(
        parseFloat(el[makbuzlarServer.TUTAR].replace(',', '.')),
        '',
        2,
        '.',
        ','
      );

      memo.push(el);
    }
    return memo;
  }, []);

export const responseDateMapper = (data, dateField) =>
  data.reduce((memo, el) => {
    if (el[dateField]) {
      el[dateField] = moment(el[dateField], 'yyyy-MM-DD HH:mm:ss.SSS').format(
        'DD.MM.yyyy HH:mm:ss'
      );
    }
    memo.push(el);
    return memo;
  }, []);

export const requestAddMakbuzKartiMapper = (musteriId, musteriAdi, musteriSifre, payload) => ({
  musteri_id: musteriId,
  musteri_adi: musteriAdi,
  musteri_sifre: musteriSifre,
  [makbuzlarServer.TARIH]: formatDateFromClientToServer(payload[makbuzlarClient.TARIH]),
  [makbuzlarServer.VEZNE_ID]: getValueOfPairSafely(payload[makbuzlarClient.VEZNE_ID], ''),
  [makbuzlarServer.MAKBUZ_NO]: payload[makbuzlarClient.MAKBUZ_NO] ?? '',
  [makbuzlarServer.MAKBUZ_TURU]: payload[makbuzlarClient.MAKBUZ_TURU] ?? '',
  [makbuzlarServer.ACIKLAMA]: payload[makbuzlarClient.ACIKLAMA] ?? '',
  [makbuzlarServer.CARI_ID]: getValueOfPairSafely(payload[makbuzlarClient.CARI_ID], ''),
  [makbuzlarServer.TUTAR]: payload[makbuzlarClient.TUTAR] || 0.0,
  [makbuzlarServer.MAKBUZ_ID]: payload[makbuzlarClient.MAKBUZ_ID] || '',
});

export const requestUpdateMakbuzKartiMapper = (musteriId, musteriAdi, musteriSifre, payload) =>
  requestAddMakbuzKartiMapper(musteriId, musteriAdi, musteriSifre, payload);

export const requestUpdateMakbuzKartiAfterRowChangeMapper = (
  musteriId,
  musteriAdi,
  musteriSifre,
  payload
) => ({
  musteri_id: musteriId,
  musteri_adi: musteriAdi,
  musteri_sifre: musteriSifre,
  [makbuzlarServer.TARIH]: formatDateFromClientToServer(payload[makbuzlarClient.TARIH]),
  [makbuzlarServer.VEZNE_ID]: payload[makbuzlarClient.VEZNE_ID] || '',
  [makbuzlarServer.MAKBUZ_NO]: payload[makbuzlarClient.MAKBUZ_NO] ?? '',
  [makbuzlarServer.MAKBUZ_TURU]: payload[makbuzlarClient.MAKBUZ_TURU] ?? '',
  [makbuzlarServer.ACIKLAMA]: payload[makbuzlarClient.ACIKLAMA] ?? '',
  [makbuzlarServer.CARI_ID]: payload[makbuzlarClient.CARI_ID] || '',
  [makbuzlarServer.TUTAR]: payload[makbuzlarClient.TUTAR] || 0.0,
  [makbuzlarServer.MAKBUZ_ID]: payload[makbuzlarClient.MAKBUZ_ID] || '',
});

export const responseFetchMakbuzDetaylarMapper = (payload, makbuzId) => {
  const rows = [];
  let tutar = 0.0;
  let counter = 1;
  for (let i = 12; i < payload.length; i += 7) {
    const row = {
      [makbuzDetaylarClient.MAKBUZ_DETAY_ID]: payload[i + 0].MakbuzVeriler,
      // [makbuzDetaylarClient.SIRA_NO]: payload[i + 1].MakbuzVeriler,
      [makbuzDetaylarClient.VEZNE_ID]: payload[i + 2].MakbuzVeriler,
      [makbuzDetaylarClient.VEZNE_ADI]: payload[i + 3].MakbuzVeriler,
      [makbuzDetaylarClient.TUTAR]: accounting.formatMoney(
        parseFloat(payload[i + 4].MakbuzVeriler.replace(',', '.')) || 0.0,
        '',
        2,
        '.',
        ','
      ),
      [makbuzDetaylarClient.PARA_BIRIMI_ID]: payload[i + 5].MakbuzVeriler,
      [makbuzDetaylarClient.PARA_BIRIMI]: payload[i + 6].MakbuzVeriler,
      [makbuzDetaylarClient.SIRA_NO]: counter,
    };
    tutar += parseFloat(payload[i + 4].MakbuzVeriler.replace(',', '.')) || 0.0;
    counter += 1;
    rows.push(row);
  }
  return {
    [makbuzlarClient.TARIH]: payload[0].MakbuzVeriler,
    [makbuzlarClient.MAKBUZ_TURU]: payload[1].MakbuzVeriler,
    [makbuzlarClient.MAKBUZ_NO]: payload[2].MakbuzVeriler,
    [makbuzlarClient.VEZNE_ID]: payload[3].MakbuzVeriler,
    [makbuzlarClient.VEZNE_ADI]: payload[4].MakbuzVeriler,
    [makbuzlarClient.CARI_ID]: payload[5].MakbuzVeriler,
    [makbuzlarClient.CARI_ADI]: payload[6].MakbuzVeriler,
    [makbuzlarClient.ACIKLAMA]: payload[7].MakbuzVeriler,
    [makbuzlarClient.UNKNOWN_1]: payload[8].MakbuzVeriler,
    [makbuzlarClient.UNKNOWN_2]: payload[9].MakbuzVeriler,
    [makbuzlarClient.UNKNOWN_3]: payload[10].MakbuzVeriler,
    [makbuzlarClient.UNKNOWN_4]: payload[11].MakbuzVeriler,
    [makbuzlarClient.MAKBUZ_ID]: makbuzId,
    [makbuzlarClient.TUTAR]: formatMoney(tutar),
    rows,
  };
};

export const requestAddMakbuzRowMapper = (
  musteriId,
  musteriAdi,
  musteriSifre,
  payload,
  makbuzKarti
) => ({
  musteri_id: musteriId,
  musteri_adi: musteriAdi,
  musteri_sifre: musteriSifre,
  [makbuzlarServer.MAKBUZ_TURU]: makbuzKarti[makbuzlarClient.MAKBUZ_TURU] || '',
  [makbuzlarServer.MAKBUZ_ID]: makbuzKarti[makbuzlarClient.MAKBUZ_ID] || '',
  [makbuzDetaylarServer.SIRA_NO]: (makbuzKarti.rows.length || 0) + 1,
  [makbuzDetaylarServer.MAKBUZ_DETAY_ID]: 0,
  [makbuzlarServer.CARI_ID]: makbuzKarti[makbuzlarClient.CARI_ID] || '0',
  [makbuzDetaylarServer.VEZNE_ID]: getValueOfPairSafely(
    payload[makbuzDetaylarClient.VEZNE_ID],
    '0'
  ),
  [makbuzlarServer.TARIH]: makbuzKarti[makbuzlarClient.TARIH] || '',
  [makbuzlarServer.MAKBUZ_NO]: makbuzKarti[makbuzlarClient.MAKBUZ_NO] || '',
  [makbuzDetaylarServer.TUTAR]: payload[makbuzDetaylarClient.TUTAR] || 0.0,
  [makbuzDetaylarServer.PARA_BIRIMI]: getValueOfPairSafely(
    payload[makbuzDetaylarClient.PARA_BIRIMI_ID],
    '0'
  ),
});

export const requestUpdateMakbuzRowMapper = (
  musteriId,
  musteriAdi,
  musteriSifre,
  payload,
  makbuzKarti,
  selectedRow
) => ({
  musteri_id: musteriId,
  musteri_adi: musteriAdi,
  musteri_sifre: musteriSifre,
  [makbuzlarServer.MAKBUZ_TURU]: makbuzKarti[makbuzlarClient.MAKBUZ_TURU] || '',
  [makbuzlarServer.MAKBUZ_ID]: makbuzKarti[makbuzlarClient.MAKBUZ_ID] || '',
  [makbuzlarServer.MAKBUZ_NO]: makbuzKarti[makbuzlarClient.MAKBUZ_NO] || '',
  [makbuzDetaylarServer.SIRA_NO]: selectedRow[makbuzDetaylarClient.SIRA_NO],
  [makbuzDetaylarServer.MAKBUZ_DETAY_ID]: selectedRow[makbuzDetaylarClient.MAKBUZ_DETAY_ID] ?? '',
  [makbuzDetaylarServer.PARA_BIRIMI]: getValueOfPairSafely(
    payload[makbuzDetaylarClient.PARA_BIRIMI_ID],
    '0'
  ),
  [makbuzDetaylarServer.VEZNE_ID]: getValueOfPairSafely(payload[makbuzDetaylarClient.VEZNE_ID], ''),
  [makbuzlarServer.CARI_ID]: makbuzKarti[makbuzlarClient.CARI_ID] || '0',
  [makbuzlarServer.TARIH]: makbuzKarti[makbuzlarClient.TARIH] || '',
  [makbuzDetaylarServer.TUTAR]: payload[makbuzDetaylarClient.TUTAR] || 0.0,
});

export const requestUpdateMakbuzRowAfterDeleteMapper = (
  musteriId,
  musteriAdi,
  musteriSifre,
  makbuzKarti,
  selectedRow
) => ({
  musteri_id: musteriId,
  musteri_adi: musteriAdi,
  musteri_sifre: musteriSifre,
  [makbuzlarServer.MAKBUZ_TURU]: makbuzKarti[makbuzlarClient.MAKBUZ_TURU] || '',
  [makbuzlarServer.MAKBUZ_ID]: makbuzKarti[makbuzlarClient.MAKBUZ_ID] || '',
  [makbuzlarServer.MAKBUZ_NO]: makbuzKarti[makbuzlarClient.MAKBUZ_NO] || '',
  [makbuzDetaylarServer.SIRA_NO]: selectedRow[makbuzDetaylarClient.SIRA_NO],
  [makbuzDetaylarServer.MAKBUZ_DETAY_ID]: selectedRow[makbuzDetaylarClient.MAKBUZ_DETAY_ID] ?? '',
  [makbuzDetaylarServer.VEZNE_ID]: selectedRow[makbuzDetaylarClient.VEZNE_ID] || '',
  [makbuzlarServer.CARI_ID]: makbuzKarti[makbuzlarClient.CARI_ID] || '0',
  [makbuzlarServer.TARIH]: makbuzKarti[makbuzlarClient.TARIH] || '',
  [makbuzDetaylarServer.TUTAR]: selectedRow[makbuzDetaylarClient.TUTAR] || 0.0,
  [makbuzDetaylarServer.PARA_BIRIMI]: selectedRow[makbuzDetaylarClient.PARA_BIRIMI_ID] || '0',
});

export const requestDeleteMakbuzRowMapper = (musteriId, musteriAdi, musteriSifre, payload) => ({
  musteri_id: musteriId,
  musteri_adi: musteriAdi,
  musteri_sifre: musteriSifre,
  [makbuzlarServer.MAKBUZ_ID]: payload[makbuzlarClient.MAKBUZ_ID] || '',
  [makbuzDetaylarServer.MAKBUZ_DETAY_ID]: payload[makbuzDetaylarClient.MAKBUZ_DETAY_ID] ?? '',
  [makbuzDetaylarServer.SIRA_NO]: payload[makbuzDetaylarClient.SIRA_NO] || '',
});
