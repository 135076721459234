/* eslint-disable arrow-body-style */
import * as actionTypes from './actionTypes';
import updateObject from '../../utility';

const initialState = {
  stokKartlari: [],
  stokKartlariTableLoading: false,
  stokKarti: null,
};

//= ====================================
//            Stok Kartlari
//= ====================================
const fetchStokKartlari = (state) => {
  return updateObject(state, {
    stokKartlari: [],
    stokKartlariTableLoading: true,
    stokKartlariMessage: null,
    stokKarti: null,
  });
};

const fetchStokKartlariSuccess = (state, action) => {
  return updateObject(state, {
    stokKartlari: action.payload.stokKartlari,
    stokKartlariTableLoading: false,
  });
};

const fetchStokKartlariFailure = (state) => {
  return updateObject(state, {
    stokKartlari: null,
    stokKartlariTableLoading: false,
  });
};

//= ====================================
//            Stok Karti Detaylar
//= ====================================
const fetchStokKartiDetaylar = (state) => {
  return updateObject(state, {
    stokKarti: {},
  });
};

const fetchStokKartiDetaylarSuccess = (state, action) => {
  return updateObject(state, {
    stokKarti: action.payload.stokKarti,
  });
};

const fetchStokKartiDetaylarFailure = (state) => {
  return updateObject(state, {
    stokKarti: null,
  });
};

const stoklar = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_STOK_KARTLARI:
      return fetchStokKartlari(state);
    case actionTypes.FETCH_STOK_KARTLARI_SUCCESS:
      return fetchStokKartlariSuccess(state, action);
    case actionTypes.FETCH_STOK_KARTLARI_FAILURE:
      return fetchStokKartlariFailure(state, action);
    case actionTypes.FETCH_STOK_DETAYLAR:
      return fetchStokKartiDetaylar(state);
    case actionTypes.FETCH_STOK_DETAYLAR_SUCCESS:
      return fetchStokKartiDetaylarSuccess(state, action);
    case actionTypes.FETCH_STOK_DETAYLAR_FAILURE:
      return fetchStokKartiDetaylarFailure(state);
    default:
      return state;
  }
};

export default stoklar;
