/* eslint-disable arrow-body-style */
import * as actionTypes from './actionTypes';
import updateObject from '../utility';

const initialState = {
  isAuthenticated: false,
  user: null,
  error: null,
  loading: null,
  userDetails: null,
};

const logout = (state) => {
  return updateObject(state, {
    isAuthenticated: false,
    user: null,
    loading: false,
    error: null,
  });
};

const checkLogin = (state) => {
  return updateObject(state, {
    user: null,
    loading: true,
    error: null,
  });
};

const authSuccess = (state, action) => {
  return updateObject(state, {
    user: action.payload,
    loading: false,
    error: null,
    isAuthenticated: true,
  });
};

export const updateUserDetails = (state, action) => {
  return updateObject(state, {
    userDetails: action.payload,
  });
};

const apiFailed = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.payload,
    isAuthenticated: false,
  });
};

const checkAutoSignIn = (state) => {
  return updateObject(state, {
    user: null,
    loading: true,
    error: null,
  });
};

const login = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CHECK_AUTO_SIGNIN:
      return checkAutoSignIn(state);
    case actionTypes.AUTH_LOGOUT:
      return logout(state);
    case actionTypes.LOGIN:
      return checkLogin(state);
    case actionTypes.UPDATE_USER_DETAILS:
      return updateUserDetails(state, action);
    case actionTypes.AUTH_SUCCESS:
      return authSuccess(state, action);
    case actionTypes.API_FAILED:
      return apiFailed(state, action);
    default:
      return state;
  }
};

export default login;
