import { apiCall, methods } from '../api';

const urls = {
  FETCH_SEHIRLER_URL:
    '/Sehirler?musteri_id={0}&musteri_adi={1}&musteri_sifre={2}',
  FETCH_PARA_BIRIMLERI_URL:
    '/ParaBirimleri?musteri_id={0}&musteri_adi={1}&musteri_sifre={2}',
  FETCH_CARI_LISTESI_URL:
    '/CariListesi?musteri_id={0}&musteri_adi={1}&musteri_sifre={2}&Arama={3}&KayitBaslangic={4}&KayitBitis={5}',
  FETCH_KASA_LISTESI_URL:
    '/KasaListesi?musteri_id={0}&musteri_adi={1}&musteri_sifre={2}',
  ADD_KASA_URL:
    '/KasaEkle?musteri_id={0}&musteri_adi={1}&musteri_sifre={2}&KasaKodu={3}&Aciklama={4}',
  FETCH_DEPO_LISTESI_URL:
    '/Depolar?musteri_id={0}&musteri_adi={1}&musteri_sifre={2}',
  ADD_DEPO_URL:
    '/DepoEkle?musteri_id={0}&musteri_adi={1}&musteri_sifre={2}&KisaAciklama={3}&Aciklama={4}',
};

export const apiFetchSehirler = (musteriId, musteriAdi, musteriSifre) =>
  apiCall(urls.FETCH_SEHIRLER_URL.format(musteriId, musteriAdi, musteriSifre), methods.GET);

export const apiFetchParaBirimleri = (musteriId, musteriAdi, musteriSifre) =>
  apiCall(urls.FETCH_PARA_BIRIMLERI_URL.format(musteriId, musteriAdi, musteriSifre), methods.GET);

export const apiFetchCariListesi = (
  musteriId,
  musteriAdi,
  musteriSifre,
  arama,
  kayitBaslangic,
  kayitBitis
) =>
  apiCall(
    urls.FETCH_CARI_LISTESI_URL.format(
      musteriId,
      musteriAdi,
      musteriSifre,
      arama,
      kayitBaslangic,
      kayitBitis
    ),
    methods.GET
  );

export const apiFetchKasaListesi = (musteriId, musteriAdi, musteriSifre) =>
  apiCall(urls.FETCH_KASA_LISTESI_URL.format(musteriId, musteriAdi, musteriSifre), methods.GET);

export const apiAddKasa = (musteriId, musteriAdi, musteriSifre, kasaKodu, aciklama) =>
  apiCall(
    urls.ADD_KASA_URL.format(musteriId, musteriAdi, musteriSifre, kasaKodu, aciklama),
    methods.GET
  );

export const apiFetchDepoListesi = (musteriId, musteriAdi, musteriSifre) =>
  apiCall(urls.FETCH_DEPO_LISTESI_URL.format(musteriId, musteriAdi, musteriSifre), methods.GET);

export const apiAddDepo = (musteriId, musteriAdi, musteriSifre, kisaAciklama, aciklama) =>
  apiCall(
    urls.ADD_DEPO_URL.format(musteriId, musteriAdi, musteriSifre, kisaAciklama, aciklama),
    methods.GET
  );
