import * as actionTypes from './actionTypes';

//= ====================================
//            Gunluk Rapor
//= ====================================
export const fetchGunlukRapor = (startDate, endDate) => ({
  type: actionTypes.FETCH_GUNLUK_RAPOR,
  payload: { startDate, endDate },
});

export const fetchGunlukRaporKasa = (startDate, endDate, kasaId) => ({
  type: actionTypes.FETCH_GUNLUK_RAPOR_KASA,
  payload: { startDate, endDate, kasaId },
});

export const changeGunlukRaporDates = (startDate, endDate) => ({
  type: actionTypes.CHANGE_GUNLUK_RAPOR_DATES,
  payload: { startDate, endDate },
});

export const selectGunlukRaporKasa = (selectedKasa) => ({
  type: actionTypes.SELECT_GUNLUK_RAPOR_KASA,
  payload: { selectedKasa },
});

//= ====================================
//            Cari Raporları
//= ====================================
export const fetchCariRaporu = (startDate, endDate, cariId) => ({
  type: actionTypes.FETCH_CARI_RAPORU,
  payload: { startDate, endDate, cariId },
});

export const changeCariRaporuDates = (startDate, endDate) => ({
  type: actionTypes.CHANGE_CARI_RAPORU_DATES,
  payload: { startDate, endDate },
});

export const selectCariRaporuCari = (selectedCari) => ({
  type: actionTypes.SELECT_CARI_RAPORU_CARI,
  payload: { selectedCari },
});

//= ====================================
//            Stok Raporları
//= ====================================
export const fetchStokRaporu = (startDate, endDate, stokId) => ({
  type: actionTypes.FETCH_STOK_RAPORU,
  payload: { startDate, endDate, stokId },
});

export const changeStokRaporuDates = (startDate, endDate) => ({
  type: actionTypes.CHANGE_STOK_RAPORU_DATES,
  payload: { startDate, endDate },
});

export const selectStokRaporuStok = (selectedStok) => ({
  type: actionTypes.SELECT_STOK_RAPORU_STOK,
  payload: { selectedStok },
});

//= ====================================
//            Kasa Raporları
//= ====================================
export const fetchKasaRaporu = (startDate, endDate, kasaId) => ({
  type: actionTypes.FETCH_KASA_RAPORU,
  payload: { startDate, endDate, kasaId },
});

export const changeKasaRaporuDates = (startDate, endDate) => ({
  type: actionTypes.CHANGE_KASA_RAPORU_DATES,
  payload: { startDate, endDate },
});

export const selectKasaRaporuKasa = (selectedKasa) => ({
  type: actionTypes.SELECT_KASA_RAPORU_KASA,
  payload: { selectedKasa },
});

//= ====================================
//            Hizmet Raporları
//= ====================================
export const fetchHizmetRaporu = (startDate, endDate, hizmetId, alisSatis) => ({
  type: actionTypes.FETCH_HIZMET_RAPORU,
  payload: { startDate, endDate, hizmetId, alisSatis },
});

export const changeHizmetRaporuDates = (startDate, endDate) => ({
  type: actionTypes.CHANGE_HIZMET_RAPORU_DATES,
  payload: { startDate, endDate },
});

export const selectHizmetRaporuHizmet = (selectedHizmet) => ({
  type: actionTypes.SELECT_HIZMET_RAPORU_HIZMET,
  payload: { selectedHizmet },
});

export const selectHizmetRaporuAlisSatis = (selectedAlisSatis) => ({
  type: actionTypes.SELECT_HIZMET_RAPORU_ALIS_SATIS,
  payload: { selectedAlisSatis },
});

//= ====================================
//            Fatura Raporları
//= ====================================
export const fetchFaturaRaporu = (startDate, endDate, cariId, stokId, faturaTuru) => ({
  type: actionTypes.FETCH_FATURA_RAPORU,
  payload: { startDate, endDate, cariId, stokId, faturaTuru },
});

export const changeFaturaRaporuDates = (startDate, endDate) => ({
  type: actionTypes.CHANGE_FATURA_RAPORU_DATES,
  payload: { startDate, endDate },
});

export const selectFaturaRaporuCari = (selectedCari) => ({
  type: actionTypes.SELECT_FATURA_RAPORU_CARI,
  payload: { selectedCari },
});

export const selectFaturaRaporuStok = (selectedStok) => ({
  type: actionTypes.SELECT_FATURA_RAPORU_STOK,
  payload: { selectedStok },
});

export const selectFaturaRaporuFaturaTuru = (selectedFaturaTuru) => ({
  type: actionTypes.SELECT_FATURA_RAPORU_FATURA_TURU,
  payload: { selectedFaturaTuru },
});

//= ====================================
//            Mobile Raporları
//= ====================================
export const fetchFaturaInvoiceForMobile = (payload) => ({
  type: actionTypes.FETCH_FATURA_INVOICE_FOR_MOBILE,
  payload,
});

export const fetchCariRaporuForMobile = (payload) => ({
  type: actionTypes.FETCH_CARI_RAPORU_FOR_MOBILE,
  payload,
});

export const fetchStokRaporuForMobile = (payload) => ({
  type: actionTypes.FETCH_STOK_RAPORU_FOR_MOBILE,
  payload,
});

export const fetchHizmetRaporuForMobile = (payload) => ({
  type: actionTypes.FETCH_HIZMET_RAPORU_FOR_MOBILE,
  payload,
});

export const fetchFaturaRaporuForMobile = (payload) => ({
  type: actionTypes.FETCH_FATURA_RAPORU_FOR_MOBILE,
  payload,
});

export const fetchKasaRaporuForMobile = (payload) => ({
  type: actionTypes.FETCH_KASA_RAPORU_FOR_MOBILE,
  payload,
});

//= ====================================
//            Others
//= ====================================
export const raporlarFetchApiSuccess = (key, payload) => ({
  type: actionTypes.RAPORLAR_FETCH_API_SUCCESS,
  payload,
  key,
});

export const raporlarApiError = (error) => ({
  type: actionTypes.API_FAILED,
  payload: error,
});
