import { takeEvery, fork, put, all, delay } from 'redux-saga/effects';
import { SHOW_ERROR, SHOW_LOADING, SHOW_MESSAGE } from './actionTypes';
import * as actions from './actions';
import { isAuthExpired } from '../../helpers/utils';
import { apiError, logout } from '../auth/actions';

function* errorWorker() {
  yield delay(5000);
  yield put(actions.hideError());
}

function* messageWorker() {
  yield delay(5000);
  yield put(actions.hideMessage());
}

function* authExpirationWorker() {
  try {
    const user = localStorage.getItem('user');
    const ts = localStorage.getItem('ts');
    const rememberMe = localStorage.getItem('rememberMe');
    if (user && user !== 'undefined') {
      if (rememberMe === 'false') {
        if (isAuthExpired(ts)) {
          yield put(logout());
        } else {
          localStorage.setItem('ts', Date.now());
        }
      }
    } else {
      yield put(logout());
    }
  } catch (error) {
    yield put(apiError(error.message));
  }
}

export function* watchLayoutErrors() {
  yield takeEvery(SHOW_ERROR, errorWorker);
}

export function* watchLayoutMessages() {
  yield takeEvery(SHOW_MESSAGE, messageWorker);
}

export function* watchLayoutLoading() {
  yield takeEvery(SHOW_LOADING, authExpirationWorker);
}

function* layoutSaga() {
  yield all([fork(watchLayoutErrors), fork(watchLayoutMessages), fork(watchLayoutLoading)]);
}

export default layoutSaga;
