import { takeEvery, fork, put, all, call, select } from 'redux-saga/effects';
import * as actionTypes from './actionTypes';
import { FETCH_FATURA_ROW_STOK } from '../faturalar/actionTypes';
import { selectStokKartiOnFaturaRow } from '../faturalar/actions';
import * as actions from './actions';
import * as layoutActions from '../../layout/actions';
import * as api from '../../../api/stoklar';
import * as mapper from './utils/mapper';
import * as schemas from './utils/schemas';
import * as otherSchemas from '../others/utils/schemas';
import * as constants from '../../../constants/stoklar';

const getMusteriId = (state) => state.auth.user.musteriId;
const getMusteriAdi = (state) => state.auth.user.musteriAdi;
const getMusteriSifre = (state) => state.auth.user.musteriSifre;

function* fetchStokKartlariWorker({ payload: { siralama, arama, kayitBaslangic, kayitBitis } }) {
  const musteriId = yield select(getMusteriId);
  const musteriAdi = yield select(getMusteriAdi);
  const musteriSifre = yield select(getMusteriSifre);

  try {
    const response = yield call(
      api.fetchStokKartlari,
      musteriId,
      musteriAdi,
      musteriSifre,
      siralama,
      arama,
      kayitBaslangic,
      kayitBitis
    );
    const { data } = response;
    yield put(actions.fetchStokKartlariSuccess(mapper.responseFetchStokKartlariMapper(data)));
  } catch (error) {
    yield put(layoutActions.showError(error.message));
  }
}

function* addStokKartiWorker({ payload, history }) {
  yield put(layoutActions.showLoading());
  const musteriId = yield select(getMusteriId);
  const musteriAdi = yield select(getMusteriAdi);
  const musteriSifre = yield select(getMusteriSifre);

  const mappedPayload = mapper.requestAddStokKartiMapper(
    musteriId,
    musteriAdi,
    musteriSifre,
    payload
  );

  try {
    const err = {
      message: 'Bilinmeyen bir hata oluştu!!',
    };

    // First check stok kodu is present or not.
    {
      const responseCheck = yield call(
        api.checkStokKodu,
        musteriId,
        musteriAdi,
        musteriSifre,
        mappedPayload[constants.stoklarServer.STOK_KODU]
      );

      // Check response schema
      const { data } = responseCheck;
      if (!otherSchemas.responseVarYokSchema.isValidSync(data)) {
        throw err;
      }

      if (data[0].geriDonenDeger === 'var') {
        err.message = 'Bu stok kodu kayıtlı. Lütfen başka stok kodu giriniz.';
        throw err;
      }
    }

    // Send request to add
    const response = yield call(api.addStokKarti, mappedPayload);

    // Check response schema
    const { data } = response;
    if (!otherSchemas.responseTrueFalseSchema.isValidSync(data)) {
      throw err;
    }

    if (data.geriDonenDeger !== 'true') {
      err.message = 'Bilinmeyen bir hata oluştu. Stok kartı eklenemedi.';
      throw err;
    }
    yield put(layoutActions.showMessage('Başarıyla eklendi'));
    history.push('/stoklar');
  } catch (error) {
    yield put(layoutActions.showError(error.message));
  }
}

function* fetchStokDetaylarWorker({ payload: { stokId } }) {
  yield put(layoutActions.showLoading());
  const musteriId = yield select(getMusteriId);
  const musteriAdi = yield select(getMusteriAdi);
  const musteriSifre = yield select(getMusteriSifre);

  try {
    const err = {
      message: 'Bilinmeyen bir hata oluştu',
    };
    const response = yield call(api.fetchStokDetaylar, musteriId, musteriAdi, musteriSifre, stokId);
    const { data } = response;
    if (!schemas.responseStokDetaylarSchema.isValidSync(data)) {
      throw err;
    }
    yield put(actions.fetchStokDetaylarSuccess(mapper.responseFetchStokDetaylarMapper(data)));
  } catch (error) {
    yield put(layoutActions.showError(error.message));
  }
  yield put(layoutActions.hideLoading());
}

function* updateStokKartiWorker({ payload, history, previousStokKodu }) {
  yield put(layoutActions.showLoading());
  const musteriId = yield select(getMusteriId);
  const musteriAdi = yield select(getMusteriAdi);
  const musteriSifre = yield select(getMusteriSifre);

  const mappedPayload = mapper.requestUpdateStokKartiMapper(
    musteriId,
    musteriAdi,
    musteriSifre,
    payload
  );

  try {
    const err = {
      message: 'Bilinmeyen bir hata oluştu',
    };

    // First check stok kodu is present or not if it is necessary.
    if (previousStokKodu !== mappedPayload[constants.stoklarServer.STOK_KODU]) {
      const responseCheck = yield call(
        api.checkStokKodu,
        musteriId,
        musteriAdi,
        musteriSifre,
        mappedPayload[constants.stoklarServer.STOK_KODU]
      );

      // Check response schema
      const { data } = responseCheck;
      if (!otherSchemas.responseVarYokSchema.isValidSync(data)) {
        throw err;
      }

      if (data[0].geriDonenDeger === 'var') {
        err.message = 'Bu stok kodu kayıtlı. Lütfen başka stok kodu giriniz.';
        throw err;
      }
    }

    // Send request to update
    const response = yield call(api.updateStokKarti, mappedPayload);

    // Check response schema
    const { data } = response;
    if (!otherSchemas.responseTrueFalseSchema.isValidSync(data)) {
      throw err;
    }

    if (data.geriDonenDeger !== 'true') {
      err.message = 'Stok Kartı Güncellenemedi.';
      throw err;
    }

    yield put(layoutActions.showMessage('Başarıyla güncellendi'));
    history.push('/stoklar');
  } catch (error) {
    yield put(layoutActions.showError(error.message));
  }
}

function* deleteStokKartiWorker({ payload: { stokId }, history }) {
  yield put(layoutActions.showLoading());
  const musteriId = yield select(getMusteriId);
  const musteriAdi = yield select(getMusteriAdi);
  const musteriSifre = yield select(getMusteriSifre);

  try {
    const err = {
      message: 'Bilinmeyen bir hata oluştu',
    };

    // First check stok is used or not
    {
      const responseCheck = yield call(
        api.checkStokFisi,
        musteriId,
        musteriAdi,
        musteriSifre,
        stokId
      );

      // Check response schema
      const { data } = responseCheck;
      if (!otherSchemas.responseVarYokSchema.isValidSync(data)) {
        throw err;
      }

      if (data[0].geriDonenDeger === 'var') {
        err.message = 'Bu stok kartı işlem gördüğü için silinemez.';
        throw err;
      }
    }

    // Send request to delete
    const response = yield call(api.deleteStokKarti, musteriId, musteriAdi, musteriSifre, stokId);

    // Check response schema
    const { data } = response;
    if (!otherSchemas.responseTrueFalseSchema.isValidSync(data)) {
      throw err;
    }

    if (data.geriDonenDeger !== 'true') {
      err.message = 'Bilinmeyen bir hata oluştu. Stok Karti silinemedi.';
      throw err;
    }
    yield put(layoutActions.showMessage('Stok Karti silindi.'));
    history.push('/stoklar');
  } catch (error) {
    yield put(layoutActions.showError(error.message));
  }
}

function* fetchStokDetaylarForFaturaRowWorker({ payload: { stokId } }) {
  yield put(layoutActions.showLoading());
  const musteriId = yield select(getMusteriId);
  const musteriAdi = yield select(getMusteriAdi);
  const musteriSifre = yield select(getMusteriSifre);

  try {
    const err = {
      message: 'Bilinmeyen bir hata oluştu',
    };
    const response = yield call(api.fetchStokDetaylar, musteriId, musteriAdi, musteriSifre, stokId);
    const { data } = response;
    if (!schemas.responseStokDetaylarSchema.isValidSync(data)) {
      throw err;
    }
    yield put(selectStokKartiOnFaturaRow(mapper.responseFetchStokDetaylarMapper(data)));
  } catch (error) {
    yield put(layoutActions.showError(error.message));
  }
  yield put(layoutActions.hideLoading());
}

export function* watchStoklar() {
  yield takeEvery(actionTypes.FETCH_STOK_KARTLARI, fetchStokKartlariWorker);
  yield takeEvery(actionTypes.ADD_STOK_KARTI, addStokKartiWorker);
  yield takeEvery(actionTypes.FETCH_STOK_DETAYLAR, fetchStokDetaylarWorker);
  yield takeEvery(actionTypes.UPDATE_STOK_KARTI, updateStokKartiWorker);
  yield takeEvery(actionTypes.DELETE_STOK_KARTI, deleteStokKartiWorker);
  yield takeEvery(FETCH_FATURA_ROW_STOK, fetchStokDetaylarForFaturaRowWorker);
}

function* stoklarSaga() {
  yield all([fork(watchStoklar)]);
}

export default stoklarSaga;
