/* eslint-disable arrow-body-style */
import * as actionTypes from './actionTypes';
import updateObject from '../../utility';

const initialState = {
  makbuzKartlari: [],
  makbuzKartlariTableLoading: false,
  makbuzKarti: null,
  selectedStokKarti: null,
  selectedHizmetKarti: null,
};

//= ====================================
//            Makbuz Kartlari
//= ====================================
const fetchMakbuzKartlari = (state) => {
  return updateObject(state, {
    makbuzKartlari: [],
    makbuzKartlariTableLoading: true,
    makbuzKartlariMessage: null,
    makbuzKarti: null,
    selectedStokKarti: null,
    selectedHizmetKarti: null,
  });
};

const fetchMakbuzKartlariSuccess = (state, action) => {
  return updateObject(state, {
    makbuzKartlari: action.payload.makbuzKartlari,
    makbuzKartlariTableLoading: false,
  });
};

const fetchMakbuzKartlariFailure = (state) => {
  return updateObject(state, {
    makbuzKartlari: null,
    makbuzKartlariTableLoading: false,
  });
};

//= ====================================
//            Makbuz Karti Detaylar
//= ====================================
const fetchMakbuzKartiDetaylar = (state) => {
  return updateObject(state, {
    makbuzKarti: {},
    selectedStokKarti: null,
    selectedHizmetKarti: null,
  });
};

const fetchMakbuzKartiDetaylarSuccess = (state, action) => {
  return updateObject(state, {
    makbuzKarti: action.payload.makbuzKarti,
  });
};

const fetchMakbuzKartiDetaylarFailure = (state) => {
  return updateObject(state, {
    makbuzKarti: null,
  });
};

//= ====================================
//            Select Stok Karti For Makbuz Row
//= ====================================
const selectStokKartiOnMakbuzRow = (state, action) => {
  return updateObject(state, {
    selectedStokKarti: action.payload.selectedStokKarti,
  });
};

//= ====================================
//            Select Hizmet Karti For Makbuz Row
//= ====================================
const selectHizmetKartiOnMakbuzRow = (state, action) => {
  return updateObject(state, {
    selectedHizmetKarti: action.payload.selectedHizmetKarti,
  });
};

const makbuzlar = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_MAKBUZ_KARTLARI:
      return fetchMakbuzKartlari(state);
    case actionTypes.FETCH_MAKBUZ_KARTLARI_SUCCESS:
      return fetchMakbuzKartlariSuccess(state, action);
    case actionTypes.FETCH_MAKBUZ_KARTLARI_FAILURE:
      return fetchMakbuzKartlariFailure(state, action);
    case actionTypes.FETCH_MAKBUZ_DETAYLAR:
      return fetchMakbuzKartiDetaylar(state);
    case actionTypes.FETCH_MAKBUZ_DETAYLAR_SUCCESS:
      return fetchMakbuzKartiDetaylarSuccess(state, action);
    case actionTypes.FETCH_MAKBUZ_DETAYLAR_FAILURE:
      return fetchMakbuzKartiDetaylarFailure(state);
    case actionTypes.SELECT_MAKBUZ_ROW_STOK:
      return selectStokKartiOnMakbuzRow(state, action);
    case actionTypes.SELECT_MAKBUZ_ROW_HIZMET:
      return selectHizmetKartiOnMakbuzRow(state, action);
    default:
      return state;
  }
};

export default makbuzlar;
