import * as yup from 'yup';

export const responseVarYokSchema = yup
  .array()
  .of(
    yup.object().shape({
      geriDonenDeger: yup.string().required(),
    })
  )
  .required()
  .min(1)
  .max(1);

export const responseStringDegerSchema = yup
  .array()
  .of(
    yup.object().shape({
      StringDeger: yup.string().required(),
    })
  )
  .required()
  .min(1)
  .max(1);

export const responseTrueFalseSchema = yup
  .object()
  .shape({
    geriDonenDeger: yup.string().required(),
  })
  .required();

export const responseIdObjectArraySchema = yup
  .array()
  .of(
    yup.object().shape({
      ID: yup.number().required(),
    })
  )
  .required()
  .min(1)
  .max(1);
