export const logoBlack = ['908 659', `
  <title>Aymet Logo Black</title>
  <g transform="translate(0.000000,659.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M2680 5970 l0 -610 615 0 615 0 0 610 0 610 -615 0 -615 0 0 -610z"/>
<path d="M5150 4630 l0 -1950 1960 0 1960 0 0 615 0 615 -1345 0 -1345 0 0
1335 0 1335 -615 0 -615 0 0 -1950z"/>
<path d="M10 3296 l0 -616 1340 0 1341 0 -1 -1335 -2 -1336 609 1 608 0 0
1945 0 1946 -1947 6 -1948 6 0 -617z"/>
<path d="M5157 1223 c-4 -3 -7 -278 -7 -610 l0 -603 615 0 615 0 -2 608 -3
607 -606 3 c-333 1 -609 -1 -612 -5z"/>
</g>
`]
