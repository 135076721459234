import * as yup from 'yup';

export const responseHizmetDetaylarSchema = yup
  .array()
  .of(
    yup.object().shape({
      StokVeriler: yup.lazy((value) => {
        switch (typeof value) {
          case 'number':
            return yup.number();
          case 'string':
            return yup.string();
          default:
            return yup.mixed();
        }
      }),
    })
  )
  .required()
  .min(10)
  .max(10);
