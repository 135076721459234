import * as actionTypes from './actionTypes';

//= ====================================
//            Cari Kartlari
//= ====================================
export const fetchCariKartlari = (siralama, arama, kayitBaslangic, kayitBitis) => ({
  type: actionTypes.FETCH_CARI_KARTLARI,
  payload: { siralama, arama, kayitBaslangic, kayitBitis },
});

export const fetchCariKartlariSuccess = (cariKartlari) => ({
  type: actionTypes.FETCH_CARI_KARTLARI_SUCCESS,
  payload: {
    cariKartlari,
  },
});

export const fetchCariKartlariFailure = (error) => ({
  type: actionTypes.FETCH_CARI_KARTLARI_FAILURE,
  payload: { error },
});

//= ====================================
//            Cari Faturalar
//= ====================================
export const fetchCariFaturalar = (cariId) => ({
  type: actionTypes.FETCH_CARI_FATURALAR,
  payload: { cariId },
});

export const fetchCariFaturalarSuccess = (cariFaturalar) => ({
  type: actionTypes.FETCH_CARI_FATURALAR_SUCCESS,
  payload: {
    cariFaturalar,
  },
});

export const fetchCariFaturalarFailure = (error) => ({
  type: actionTypes.FETCH_CARI_FATURALAR_FAILURE,
  payload: { error },
});

//= ====================================
//            Cari Makbuzlar
//= ====================================
export const fetchCariMakbuzlar = (cariId) => ({
  type: actionTypes.FETCH_CARI_MAKBUZLAR,
  payload: { cariId },
});

export const fetchCariMakbuzlarSuccess = (cariMakbuzlar) => ({
  type: actionTypes.FETCH_CARI_MAKBUZLAR_SUCCESS,
  payload: {
    cariMakbuzlar,
  },
});

export const fetchCariMakbuzlarFailure = (error) => ({
  type: actionTypes.FETCH_CARI_MAKBUZLAR_FAILURE,
  payload: { error },
});

//= ====================================
//            Add Cari
//= ====================================
export const addCariKarti = (payload, history) => ({
  type: actionTypes.ADD_CARI_KARTI,
  payload,
  history,
});

//= ====================================
//            Cari Karti Detaylar
//= ====================================
export const fetchCariDetaylar = (cariId) => ({
  type: actionTypes.FETCH_CARI_DETAYLAR,
  payload: { cariId },
});

export const fetchCariDetaylarSuccess = (cariKarti) => ({
  type: actionTypes.FETCH_CARI_DETAYLAR_SUCCESS,
  payload: {
    cariKarti,
  },
});

export const fetchCariDetaylarFailure = (error) => ({
  type: actionTypes.FETCH_CARI_DETAYLAR_FAILURE,
  payload: { error },
});

//= ====================================
//            Cari Karti Delete
//= ====================================
export const deleteCariKarti = (cariId, history) => ({
  type: actionTypes.FETCH_CARI_DELETE,
  payload: { cariId },
  history,
});

//= ====================================
//            Update Cari Karti
//= ====================================
export const updateCariKarti = (payload, history) => ({
  type: actionTypes.UPDATE_CARI_KARTI,
  payload,
  history,
});
