import React, { useEffect, useState } from 'react';
import { Document, Page, Text, StyleSheet, View, Image, Font } from '@react-pdf/renderer';
import moment from 'moment';
import PropTypes from 'prop-types';
import Logo from '../../assets/images/logo-pdf.png';
import { dateFormats } from '../../constants';

// import Oswald from '../../assets/fonts/pdf/Oswald-Regular.ttf';
// import Montserrat from '../../assets/fonts/pdf/Montserrat.ttf';
// import MontserratBold from '../../assets/fonts/pdf/Montserrat-Bold.ttf';

const registerFonts = () => {
  Font.register({
    family: 'Oswald',
    src: 'https://fonts.gstatic.com/s/oswald/v36/TK3_WkUHHAIjg75cFRf3bXL8LICs1_FvgUFoZAaQriI.ttf',
  });

  Font.register({
    family: 'Montserrat',
    src: 'https://fonts.gstatic.com/s/montserrat/v15/JTUSjIg1_i6t8kCHKm45xW5rzAbj.ttf',
  });

  Font.register({
    family: 'Montserrat-Bold',
    src: 'https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_ZpC7g7J-d51.ttf',
  });
};
registerFonts();

const propTypes = {
  title: PropTypes.string.isRequired,
  firm: PropTypes.string.isRequired,
  headers: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  footer: PropTypes.object,
};
const TikirReport = (props) => {
  const { title, firm, headers, data, footer } = props;
  const BORDER_COLOR = '#bfbfbf';
  const BORDER_STYLE = 'solid';
  const COLN_WIDTH = 100 / headers.length;

  const styles = StyleSheet.create({
    body: {
      paddingTop: 35,
      paddingBottom: 65,
      paddingHorizontal: 35,
      fontFamily: 'Montserrat-Bold',
    },
    title: {
      fontSize: 24,
      textAlign: 'center',
      fontFamily: 'Oswald',
      marginTop: 0,
    },
    firm: {
      fontSize: 12,
      textAlign: 'center',
      marginBottom: 40,
    },
    subtitle: {
      fontSize: 18,
      margin: 12,
      fontFamily: 'Oswald',
    },
    text: {
      margin: 12,
      fontSize: 14,
      textAlign: 'justify',
      fontFamily: 'Times-Roman',
    },
    image: {
      marginVertical: 0,
      marginBottom: 15,
      marginTop: 4,
      marginHorizontal: 200,
    },
    header: {
      fontSize: 12,
      marginBottom: 10,
      textAlign: 'center',
      color: 'grey',
    },
    pageNumber: {
      position: 'absolute',
      fontSize: 10,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: 'center',
      color: 'grey',
    },
    date: {
      fontSize: 10,
      textAlign: 'left',
      color: 'grey',
      width: '50%',
    },
    brand: {
      fontSize: 10,
      textAlign: 'right',
      color: 'grey',
      width: '50%',
    },
    table: {
      display: 'table',
      width: 'auto',
      borderStyle: BORDER_STYLE,
      borderColor: BORDER_COLOR,
      borderWidth: 1,
      borderRightWidth: 0,
      borderLeftWidth: 0,
      borderBottomWidth: 0,
    },
    tableRow: {
      margin: 'auto',
      flexDirection: 'row',
      borderStyle: BORDER_STYLE,
      borderColor: BORDER_COLOR,
      borderLeftWidth: 1,
    },
    headerRow: {
      flexDirection: 'row',
      marginBottom: 10,
    },
    tableColHeader: {
      width: `${COLN_WIDTH}%`,
      borderStyle: BORDER_STYLE,
      borderColor: BORDER_COLOR,
      borderBottomColor: '#000',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
    },
    tableCol: {
      width: `${COLN_WIDTH}%`,
      borderStyle: BORDER_STYLE,
      borderColor: BORDER_COLOR,
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
    },
    tableCellHeader: {
      margin: 5,
      fontSize: 12,
      fontWeight: 500,
    },
    tableCell: {
      margin: 5,
      fontSize: 10,
      fontFamily: 'Montserrat',
    },
    footerRow: {
      margin: 'auto',
      flexDirection: 'row',
      marginTop: 10,
    },
    footerCol: {
      width: `${COLN_WIDTH}%`,
      borderWidth: 0,
    },
    footerCell: {
      margin: 5,
      fontSize: 11,
      fontFamily: 'Montserrat-Bold',
    },
  });

  if (styles.tableCell.fontFamily === 'Times-Roman' || !data || !headers) {
    return (
      <Document>
        <Page />
      </Document>
    );
  }
  return (
    <Document>
      <Page style={styles.body}>
        <View style={styles.headerRow}>
          <Text style={styles.date} fixed>
            {moment().format(dateFormats.DATE_FORMAT_PDF)}
          </Text>
          <Text style={styles.brand} fixed>
            © Aymet Yazılım
          </Text>
        </View>
        <Image style={styles.image} src={Logo} fixed />
        <Text style={styles.title}>{title}</Text>
        <Text style={styles.firm}>{firm}</Text>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            {headers.map((el) => (
              <View style={styles.tableColHeader} key={el.label}>
                <Text style={styles.tableCellHeader}>{el.label}</Text>
              </View>
            ))}
          </View>
          {data.map((el, index) => (
            <View key={el + index} style={styles.tableRow}>
              {headers.map((headerElement, headerIndex) => (
                <View style={styles.tableCol} key={headerElement.key + index + headerIndex}>
                  <Text style={styles.tableCell}>{el[headerElement.key]}</Text>
                </View>
              ))}
            </View>
          ))}
          {footer && (
            <View style={styles.footerRow}>
              {headers.map((headerElement, headerIndex) => (
                <View style={styles.footerCol} key={`${headerElement.key + headerIndex}f`}>
                  <Text style={styles.footerCell}>{footer[headerElement.key]}</Text>
                </View>
              ))}
            </View>
          )}
        </View>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
          fixed
        />
      </Page>
    </Document>
  );
};

TikirReport.propTypes = propTypes;
export default TikirReport;
