import { apiCall, apiCallPost, methods } from '../api';

const urls = {
  FETCH_FATURA_KARTLARI_URL:
    '/Faturalar?musteri_id={0}&musteri_adi={1}&musteri_sifre={2}&Siralama={3}&Arama={4}&KayitBaslangic={5}&KayitBitis={6}&FaturaTuru={7}',
  FETCH_FATURA_KARTI_DETAY_URL:
    '/FaturaDetayLar?musteri_id={0}&musteri_adi={1}&musteri_sifre={2}&FaturaID={3}',
  UPDATE_FATURA_KARTI_URL: '/FaturaGuncelle',
  ADD_FATURA_KARTI_URL: '/FaturaEkle',
  DELETE_FATURA_KARTI_URL: '/FaturaSil',
  CHECK_FATURA_NO_URL:
    '/FaturaNoKontrol?musteri_id={0}&musteri_adi={1}&musteri_sifre={2}&FaturaNo={3}',
  UPDATE_FATURA_DETAYLAR_URL: '/FaturaGuncelleDetaylar',
  DELETE_FATURA_DETAYLAR_URL: '/FaturaGuncelleDetaylarSil',
};

export const fetchFaturaKartlari = (
  musteriId,
  musteriAdi,
  musteriSifre,
  siralama,
  arama,
  kayitBaslangic,
  kayitBitis,
  faturaTuru
) =>
  apiCall(
    urls.FETCH_FATURA_KARTLARI_URL.format(
      musteriId,
      musteriAdi,
      musteriSifre,
      siralama,
      arama,
      kayitBaslangic,
      kayitBitis,
      faturaTuru
    ),
    methods.GET
  );

export const addFaturaKarti = (payload) => {
  const data = new URLSearchParams();
  Object.keys(payload).forEach((key) => {
    data.append(key, payload[key]);
  });
  return apiCallPost(urls.ADD_FATURA_KARTI_URL, data);
};

export const checkFaturaNo = (musteriId, musteriAdi, musteriSifre, faturaNo) =>
  apiCall(
    urls.CHECK_FATURA_NO_URL.format(musteriId, musteriAdi, musteriSifre, faturaNo),
    methods.GET
  );

export const fetchFaturaDetaylar = (musteriId, musteriAdi, musteriSifre, faturaId) =>
  apiCall(
    urls.FETCH_FATURA_KARTI_DETAY_URL.format(musteriId, musteriAdi, musteriSifre, faturaId),
    methods.GET
  );

export const deleteFaturaKarti = (musteriId, musteriAdi, musteriSifre, faturaId) => {
  const data = new URLSearchParams();
  data.append('musteri_id', musteriId);
  data.append('musteri_adi', musteriAdi);
  data.append('musteri_sifre', musteriSifre);
  data.append('FaturaID', faturaId);
  return apiCallPost(urls.DELETE_FATURA_KARTI_URL, data);
};

export const updateFaturaKarti = (payload) => {
  const data = new URLSearchParams();
  Object.keys(payload).forEach((key) => {
    data.append(key, payload[key]);
  });
  return apiCallPost(urls.UPDATE_FATURA_KARTI_URL, data);
};

export const deleteFaturaDetaylar = (payload) => {
  const data = new URLSearchParams();
  Object.keys(payload).forEach((key) => {
    data.append(key, payload[key]);
  });
  return apiCallPost(urls.DELETE_FATURA_DETAYLAR_URL, data);
};

export const updateFaturaDetaylar = (payload) => {
  const data = new URLSearchParams();
  Object.keys(payload).forEach((key) => {
    data.append(key, payload[key]);
  });
  return apiCallPost(urls.UPDATE_FATURA_DETAYLAR_URL, data);
};
