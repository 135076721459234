import { apiCall, methods } from '../api';

const urls = {
  FETCH_GUNLUK_RAPOR:
    '/GunlukRapor?musteri_id={0}&musteri_adi={1}&musteri_sifre={2}&Tarih1={3}&Tarih2={4}',
  FETCH_GUNLUK_RAPOR_KASA:
    '/GunlukRaporKasa?musteri_id={0}&musteri_adi={1}&musteri_sifre={2}&Tarih1={3}&Tarih2={4}&KasaID={5}',
  FETCH_CARI_RAPORU:
    '/CariGenelBakiyeRaporu?musteri_id={0}&musteri_adi={1}&musteri_sifre={2}&BaslangicTarihi={3}&BitisTarihi={4}&CariID={5}',
  FETCH_STOK_RAPORU:
    '/StokDurumRaporu?musteri_id={0}&musteri_adi={1}&musteri_sifre={2}&BaslangicTarihi={3}&BitisTarihi={4}&StokID={5}',
  FETCH_KASA_RAPORU:
    '/KasaBakiyeRaporu?musteri_id={0}&musteri_adi={1}&musteri_sifre={2}&BaslangicTarihi={3}&BitisTarihi={4}&KasaID={5}',
  FETCH_HIZMET_RAPORU:
    '/HizmetKartlariRaporu?musteri_id={0}&musteri_adi={1}&musteri_sifre={2}&BaslangicTarihi={3}&BitisTarihi={4}&HizmetID={5}&AlisSatis={6}',
  FETCH_FATURA_RAPORU:
    '/FaturaRaporu?musteri_id={0}&musteri_adi={1}&musteri_sifre={2}&BaslangicTarihi={3}&BitisTarihi={4}&CariID={5}&StokID={6}&FaturaTuru={7}',
};

export const apiFetchGunlukRapor = (
  musteriId,
  musteriAdi,
  musteriSifre,
  tarihBaslangic,
  tarihBitis
) =>
  apiCall(
    urls.FETCH_GUNLUK_RAPOR.format(musteriId, musteriAdi, musteriSifre, tarihBaslangic, tarihBitis),
    methods.GET
  );

export const apiFetchGunlukRaporKasa = (
  musteriId,
  musteriAdi,
  musteriSifre,
  tarihBaslangic,
  tarihBitis,
  kasaId
) =>
  apiCall(
    urls.FETCH_GUNLUK_RAPOR_KASA.format(
      musteriId,
      musteriAdi,
      musteriSifre,
      tarihBaslangic,
      tarihBitis,
      kasaId
    ),
    methods.GET
  );

export const apiFetchCariRaporu = (
  musteriId,
  musteriAdi,
  musteriSifre,
  tarihBaslangic,
  tarihBitis,
  cariId
) =>
  apiCall(
    urls.FETCH_CARI_RAPORU.format(
      musteriId,
      musteriAdi,
      musteriSifre,
      tarihBaslangic,
      tarihBitis,
      cariId
    ),
    methods.GET
  );

export const apiFetchStokRaporu = (
  musteriId,
  musteriAdi,
  musteriSifre,
  tarihBaslangic,
  tarihBitis,
  stokId
) =>
  apiCall(
    urls.FETCH_STOK_RAPORU.format(
      musteriId,
      musteriAdi,
      musteriSifre,
      tarihBaslangic,
      tarihBitis,
      stokId
    ),
    methods.GET
  );

export const apiFetchKasaRaporu = (
  musteriId,
  musteriAdi,
  musteriSifre,
  tarihBaslangic,
  tarihBitis,
  kasaId
) =>
  apiCall(
    urls.FETCH_KASA_RAPORU.format(
      musteriId,
      musteriAdi,
      musteriSifre,
      tarihBaslangic,
      tarihBitis,
      kasaId
    ),
    methods.GET
  );

export const apiFetchHizmetRaporu = (
  musteriId,
  musteriAdi,
  musteriSifre,
  tarihBaslangic,
  tarihBitis,
  hizmetId,
  alisSatis
) =>
  apiCall(
    urls.FETCH_HIZMET_RAPORU.format(
      musteriId,
      musteriAdi,
      musteriSifre,
      tarihBaslangic,
      tarihBitis,
      hizmetId,
      alisSatis
    ),
    methods.GET
  );

export const apiFetchFaturaRaporu = (
  musteriId,
  musteriAdi,
  musteriSifre,
  tarihBaslangic,
  tarihBitis,
  cariId,
  stokId,
  faturaTuru
) =>
  apiCall(
    urls.FETCH_FATURA_RAPORU.format(
      musteriId,
      musteriAdi,
      musteriSifre,
      tarihBaslangic,
      tarihBitis,
      cariId,
      stokId,
      faturaTuru
    ),
    methods.GET
  );
