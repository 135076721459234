import * as yup from 'yup';

export const responseAddCariSchema = yup
  .array()
  .of(
    yup.object().shape({
      ID: yup.number().required().integer(),
    })
  )
  .required()
  .min(1)
  .max(1);

export const responseVarYokSchema = yup
  .array()
  .of(
    yup.object().shape({
      geriDonenDeger: yup.string().required(),
    })
  )
  .required()
  .min(1)
  .max(1);

export const responseStringDegerSchema = yup
  .array()
  .of(
    yup.object().shape({
      StringDeger: yup.string().required(),
    })
  )
  .required()
  .min(1)
  .max(1);

export const requestAddCariSchema = yup.object().shape({
  CariKodu: yup.string().required('Cari kodu boş olamaz!'),
  Aciklama: yup.string().required('Cari adı boş olamaz!'),
});

export const responseCariDetaylarSchema = yup
  .array()
  .of(
    yup.object().shape({
      Veriler: yup.lazy((value) => {
        switch (typeof value) {
          case 'number':
            return yup.number();
          case 'string':
            return yup.string();
          default:
            return yup.mixed();
        }
      }),
    })
  )
  .required()
  .min(10)
  .max(10);
