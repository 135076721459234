export const FETCH_HIZMET_KARTLARI = 'FETCH_HIZMET_KARTLARI';
export const FETCH_HIZMET_KARTLARI_SUCCESS = 'FETCH_HIZMET_KARTLARI_SUCCESS';
export const FETCH_HIZMET_KARTLARI_FAILURE = 'FETCH_HIZMET_KARTLARI_FAILURE';

export const ADD_HIZMET_KARTI = 'ADD_HIZMET_KARTI';

export const UPDATE_HIZMET_KARTI = 'UPDATE_HIZMET_KARTI';

export const DELETE_HIZMET_KARTI = 'DELETE_HIZMET_KARTI';

export const FETCH_HIZMET_DETAYLAR = 'FETCH_HIZMET_DETAYLAR';
export const FETCH_HIZMET_DETAYLAR_SUCCESS = 'FETCH_HIZMET_DETAYLAR_SUCCESS';
export const FETCH_HIZMET_DETAYLAR_FAILURE = 'FETCH_HIZMET_DETAYLAR_FAILURE';
