import * as actionTypes from './actionTypes';

export const logout = () => {
  localStorage.removeItem('user');
  localStorage.removeItem('userDetails');
  localStorage.removeItem('ts');
  localStorage.removeItem('rememberMe');
  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};

export const checkAutoSignIn = () => ({
  type: actionTypes.CHECK_AUTO_SIGNIN,
});

export const login = (musteriId, musteriAdi, musteriSifre, rememberMe, history) => ({
  type: actionTypes.LOGIN,
  payload: { musteriId, musteriAdi, musteriSifre, rememberMe, history },
});

export const authSuccess = (user) => ({
  type: actionTypes.AUTH_SUCCESS,
  payload: user,
});

export const updateUserDetails = (userDetails) => ({
  type: actionTypes.UPDATE_USER_DETAILS,
  payload: userDetails,
});

export const apiError = (error) => ({
  type: actionTypes.API_FAILED,
  payload: error,
});
