/* eslint-disable import/prefer-default-export */
import accounting from 'accounting';
import { carilerServer } from '../../../../constants/cariler';
import { depolarServer, kasalarServer } from '../../../../constants/others';
import { stoklarServer } from '../../../../constants/stoklar';
import { hizmetlerServer } from '../../../../constants/hizmetler';

export const sehirlerMapper = (data) =>
  data.map((el) => ({
    value: el.Adi,
    label: `${el.Kod} ${el.Adi}`,
  }));

export const paraBirimleriMapper = (data) =>
  data.map((el) => ({
    value: el.Kod,
    label: el.Aciklama,
  }));

export const cariListesiMapper = (data) =>
  data.reduce((memo, el) => {
    if (el[carilerServer.CARI_KODU] !== null) {
      const cari = {
        value: el[carilerServer.CARI_ID],
        label: `${el[carilerServer.CARI_ADI]} (${el[carilerServer.CARI_KODU]})`,
      };
      memo.push(cari);
    }
    return memo;
  }, []);

export const stokListesiMapper = (data) =>
  data.reduce((memo, el) => {
    if (el[stoklarServer.STOK_KODU] !== null && el[stoklarServer.STOK_KODU] !== '') {
      if (el[stoklarServer.SATIS_FIYATI] === '') {
        el[stoklarServer.SATIS_FIYATI] = '0,00';
      }
      el[stoklarServer.SATIS_FIYATI] = accounting.formatMoney(
        parseFloat(el[stoklarServer.SATIS_FIYATI].replace(',', '.')),
        '',
        2,
        '.',
        ','
      );

      el.value = el[stoklarServer.STOK_ID];
      el.label = `${el[stoklarServer.STOK_ADI]} (${el[stoklarServer.STOK_KODU]})`;
      memo.push(el);
    }
    return memo;
  }, []);

export const hizmetListesiMapper = (data) =>
  data.reduce((memo, el) => {
    if (el[hizmetlerServer.HIZMET_KODU] !== null && el[hizmetlerServer.HIZMET_KODU] !== '') {
      if (el[hizmetlerServer.SATIS_FIYATI] === '') {
        el[hizmetlerServer.SATIS_FIYATI] = '0,00';
      }
      el[hizmetlerServer.SATIS_FIYATI] = accounting.formatMoney(
        parseFloat(el[hizmetlerServer.SATIS_FIYATI].replace(',', '.')),
        '',
        2,
        '.',
        ','
      );

      el.value = el[hizmetlerServer.HIZMET_ID];
      el.label = `${el[hizmetlerServer.HIZMET_ADI]} (${el[hizmetlerServer.HIZMET_KODU]})`;
      memo.push(el);
    }
    return memo;
  }, []);

export const depoListesiMapper = (data) =>
  data.map((el) => ({
    value: el[depolarServer.DEPO_ID],
    label: `${el[depolarServer.DEPO_ADI]} (${el[depolarServer.KISA_ACIKLAMA]})`,
  }));

export const kasaListesiMapper = (data) =>
  data.map((el) => ({
    value: el[kasalarServer.KASA_ID],
    label: `${el[kasalarServer.KASA_ADI]} (${el[kasalarServer.KASA_KODU]})`,
  }));
