//= ====================================
//            Stoklar
//= ====================================
export const stoklarServer = {
  STOK_ID: 'StokID',
  STOK_KODU: 'StokKodu',
  STOK_ADI: 'Aciklama',
  BIRIMI: 'Birimi',
  SATIS_FIYATI: 'SatisFiyati',
  BARKOD: 'Barkod',
  STOK_GRUBU: 'StokGrubu',
  STOK_GRUBU_ID: 'StokGrubuID',
  KDV: 'Kdv',
  ALIS_FIYATI: 'AlisFiyati',
  PARA_BIRIMI: 'ParaBirimi',
  PARA_BIRIMI_ADI: 'ParaBirimiAdi',
};

export const stoklarClient = {
  STOK_ID: 'stokId',
  STOK_KODU: 'stokKodu',
  STOK_ADI: 'stokAdi',
  BIRIMI: 'birimi',
  SATIS_FIYATI: 'satisFiyati',
  BARKOD: 'barkod',
  STOK_GRUBU: 'stokGrubu',
  STOK_GRUBU_ID: 'stokGrubuId',
  KDV: 'kdv',
  ALIS_FIYATI: 'alisFiyati',
  PARA_BIRIMI: 'paraBirimi',
  PARA_BIRIMI_ADI: 'paraBirimiAdi',
};
