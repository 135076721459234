import { apiCall, methods } from '../api';

const urls = {
  MUSTERI_KONTROL_URL:
    '/MusteriKontrol?musteri_id={0}&musteri_adi={1}&musteri_sifre={2}',
};

export const login = (musteriId, musteriAdi, musteriSifre) =>
  apiCall(urls.MUSTERI_KONTROL_URL.format(musteriId, musteriAdi, musteriSifre), methods.GET);

// export const apiSignup = (name, email, password, callback, onError) => {
//   const data = new URLSearchParams();
//   data.append('name', name);
//   data.append('email', email);
//   data.append('password', password);
//   apiCall(urls.SIGNUP_URL, methods.POST, data, callback, onError, { 'Content-Type': 'application/x-www-form-urlencoded' });
// };
