export const FETCH_CARI_KARTLARI = 'FETCH_CARI_KARTLARI';
export const FETCH_CARI_KARTLARI_SUCCESS = 'FETCH_CARI_KARTLARI_SUCCESS';
export const FETCH_CARI_KARTLARI_FAILURE = 'FETCH_CARI_KARTLARI_FAILURE';

export const FETCH_CARI_FATURALAR = 'FETCH_CARI_FATURALAR';
export const FETCH_CARI_FATURALAR_SUCCESS = 'FETCH_CARI_FATURALAR_SUCCESS';
export const FETCH_CARI_FATURALAR_FAILURE = 'FETCH_CARI_FATURALAR_FAILURE';

export const FETCH_CARI_MAKBUZLAR = 'FETCH_CARI_MAKBUZLAR';
export const FETCH_CARI_MAKBUZLAR_SUCCESS = 'FETCH_CARI_MAKBUZLAR_SUCCESS';
export const FETCH_CARI_MAKBUZLAR_FAILURE = 'FETCH_CARI_MAKBUZLAR_FAILURE';

export const ADD_CARI_KARTI = 'ADD_CARI_KARTI';

export const UPDATE_CARI_KARTI = 'UPDATE_CARI_KARTI';

export const FETCH_CARI_DELETE = 'FETCH_CARI_DELETE';

export const FETCH_CARI_DETAYLAR = 'FETCH_CARI_DETAYLAR';
export const FETCH_CARI_DETAYLAR_SUCCESS = 'FETCH_CARI_DETAYLAR_SUCCESS';
export const FETCH_CARI_DETAYLAR_FAILURE = 'FETCH_CARI_DETAYLAR_FAILURE';
