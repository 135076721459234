import React, { useState } from 'react';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { CButton } from '@coreui/react';
import PropTypes from 'prop-types';
import TikirInvoice from '../../TikirInvoice/TikirInvoice';
import { formatMoney } from '../../../helpers/utils';

const propTypes = {
  faturaKarti: PropTypes.object.isRequired,
  isSatis: PropTypes.bool.isRequired,
  pdfSettings: PropTypes.object.isRequired,
  fileName: PropTypes.string.isRequired,
};
const TikirInvoiceWrapperMobile = (props) => {
  const { faturaKarti, isSatis, pdfSettings, fileName } = props;
  const [isOpenedAlready, setOpenedAlready] = useState(false);

  const openPdfInNewTab = (filename, url, blob) => {
    setOpenedAlready(true);
    // IE
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, filename);
      return;
    }

    if (
      navigator.userAgent.indexOf('Chrome') !== -1 ||
      navigator.userAgent.indexOf('Firefox') !== -1
    ) {
      // Chrome, FF
      const w = window.open(url, '_self');
      if (w) {
        w.focus();
      }
    } else {
      // Safari & Opera iOS
      window.location.href = url;
    }
  };

  const faturaType = {
    isSatis,
  };

  return (
    <PDFDownloadLink
      document={
        <TikirInvoice faturaKarti={faturaKarti} faturaType={faturaType} pdfSettings={pdfSettings} />
      }
      fileName={fileName}
    >
      {({ blob, url, loading, error }) => {
        if (!loading && url && blob && !isOpenedAlready) {
          // downloadFile(fileName, url);
          openPdfInNewTab(fileName, url, blob);
        }
      }}
    </PDFDownloadLink>
  );
};

TikirInvoiceWrapperMobile.propTypes = propTypes;
export default TikirInvoiceWrapperMobile;
