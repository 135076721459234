/* eslint-disable arrow-body-style */
import * as actionTypes from './actionTypes';
import updateObject from '../../utility';

const initialState = {
  sehirler: null,
  paraBirimleri: null,
  cariListesi: null,
  depoListesi: null,
  kasaListesi: null,
  stokListesi: null,
  hizmetListesi: null,
  error: null,
  loading: null,
};

const fetchSehirler = (state) => {
  return updateObject(state, {
    sehirler: [],
    loading: true,
    error: null,
  });
};

const fetchParaBirimleri = (state) => {
  return updateObject(state, {
    paraBirimleri: [],
    loading: true,
    error: null,
  });
};

const fetchCariListesi = (state) => {
  return updateObject(state, {
    cariListesi: [],
    loading: true,
    error: null,
  });
};

const fetchStokListesi = (state) => {
  return updateObject(state, {
    stokListesi: [],
    loading: true,
    error: null,
  });
};

const fetchHizmetListesi = (state) => {
  return updateObject(state, {
    hizmetListesi: [],
    loading: true,
    error: null,
  });
};

const fetchDepoListesi = (state) => {
  return updateObject(state, {
    depoListesi: [],
    loading: true,
    error: null,
  });
};

const fetchKasaListesi = (state) => {
  return updateObject(state, {
    kasaListesi: [],
    loading: true,
    error: null,
  });
};

const othersFetchSuccess = (state, action) => {
  return updateObject(state, {
    [action.key]: action.payload,
    loading: false,
    error: null,
  });
};

const apiFailed = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.payload,
  });
};

const others = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_SEHIRLER:
      return fetchSehirler(state);
    case actionTypes.FETCH_PARA_BIRIMLERI:
      return fetchParaBirimleri(state);
    case actionTypes.FETCH_CARI_LISTESI:
      return fetchCariListesi(state);
    case actionTypes.FETCH_STOK_LISTESI:
      return fetchStokListesi(state);
    case actionTypes.FETCH_HIZMET_LISTESI:
      return fetchHizmetListesi(state);
    case actionTypes.FETCH_DEPO_LISTESI:
      return fetchDepoListesi(state);
    case actionTypes.FETCH_KASA_LISTESI:
      return fetchKasaListesi(state);
    case actionTypes.OTHERS_FETCH_API_SUCCESS:
      return othersFetchSuccess(state, action);
    case actionTypes.API_FAILED:
      return apiFailed(state, action);
    default:
      return state;
  }
};

export default others;
