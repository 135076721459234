//= ====================================
//            Günlük Raporlar
//= ====================================
export const gunlukRaporlarClient = {
  ALISLAR_PESIN: 'alislarPesin',
  ALISLAR_VADELI: 'alislarVadeli',
  ALISLAR_TOPLAM: 'alislarToplam',
  SATISLAR_PESIN: 'satislarPesin',
  SATISLAR_VADELI: 'satislarVadeli',
  SATISLAR_TOPLAM: 'satislarToplam',
  TAHSILATLAR_PESIN: 'tahsilatlarPesin',
  TEDIYELER_PESIN: 'tediyelerPesin',
  KASA_GIRIS: 'kasaGiris',
  KASA_CIKIS: 'kasaCikis',
  KASA_BAKIYE: 'kasaBakiye',
};
