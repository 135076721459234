import { apiCall, apiCallPost, methods } from '../api';

const urls = {
  FETCH_STOK_KARTLARI_URL:
    '/StokKartlari?musteri_id={0}&musteri_adi={1}&musteri_sifre={2}&Siralama={3}&Arama={4}&KayitBaslangic={5}&KayitBitis={6}',
  ADD_STOK_KARTI_URL: '/StokEkle',
  CHECK_STOK_KODU_URL:
    '/StokKoduKontrol?musteri_id={0}&musteri_adi={1}&musteri_sifre={2}&StokKodu={3}',
  FETCH_STOK_KARTI_DETAY_URL:
    '/StokDetay?musteri_id={0}&musteri_adi={1}&musteri_sifre={2}&StokID={3}&Barkod=',
  CHECK_STOK_FISI_URL:
    '/StokFisiKontrol?musteri_id={0}&musteri_adi={1}&musteri_sifre={2}&StokID={3}',
  DELETE_STOK_KARTI_URL: '/StokSil',
  UPDATE_STOK_KARTI_URL: '/StokGuncelle',
};

export const fetchStokKartlari = (
  musteriId,
  musteriAdi,
  musteriSifre,
  siralama,
  arama,
  kayitBaslangic,
  kayitBitis
) =>
  apiCall(
    urls.FETCH_STOK_KARTLARI_URL.format(
      musteriId,
      musteriAdi,
      musteriSifre,
      siralama,
      arama,
      kayitBaslangic,
      kayitBitis
    ),
    methods.GET
  );

export const addStokKarti = (payload) => {
  const data = new URLSearchParams();
  Object.keys(payload).forEach((key) => {
    data.append(key, payload[key]);
  });
  return apiCallPost(urls.ADD_STOK_KARTI_URL, data);
};

export const checkStokKodu = (musteriId, musteriAdi, musteriSifre, stokKodu) =>
  apiCall(
    urls.CHECK_STOK_KODU_URL.format(musteriId, musteriAdi, musteriSifre, stokKodu),
    methods.GET
  );

export const fetchStokDetaylar = (musteriId, musteriAdi, musteriSifre, stokId) =>
  apiCall(
    urls.FETCH_STOK_KARTI_DETAY_URL.format(musteriId, musteriAdi, musteriSifre, stokId),
    methods.GET
  );

export const deleteStokKarti = (musteriId, musteriAdi, musteriSifre, stokId) => {
  const data = new URLSearchParams();
  data.append('musteri_id', musteriId);
  data.append('musteri_adi', musteriAdi);
  data.append('musteri_sifre', musteriSifre);
  data.append('StokID', stokId);
  data.append('Barkod', '');
  return apiCallPost(urls.DELETE_STOK_KARTI_URL, data);
};

export const checkStokFisi = (musteriId, musteriAdi, musteriSifre, stokId) =>
  apiCall(
    urls.CHECK_STOK_FISI_URL.format(musteriId, musteriAdi, musteriSifre, stokId),
    methods.GET
  );

export const updateStokKarti = (payload) => {
  const data = new URLSearchParams();
  Object.keys(payload).forEach((key) => {
    data.append(key, payload[key]);
  });
  return apiCallPost(urls.UPDATE_STOK_KARTI_URL, data);
};
