import accounting from 'accounting';
import { faturaDetaylarClient } from '../../constants/faturalar';
import { parseAccountingSafely } from '../utils';

export const calculateTutar = (rows, formatMoney = true) => {
  let total = 0.0;
  for (let i = 0; i < rows.length; i += 1) {
    total +=
      parseAccountingSafely(rows[i][faturaDetaylarClient.FIYAT]) *
      parseAccountingSafely(rows[i][faturaDetaylarClient.MIKTAR]);
  }
  if (formatMoney) {
    return accounting.formatMoney(total.toFixed(2), '', 2, '.', ',');
  }
  return total.toFixed(2);
};

export const calculateKdvTutar = (rows, formatMoney) => {
  let total = 0.0;
  for (let i = 0; i < rows.length; i += 1) {
    total +=
      (parseAccountingSafely(rows[i][faturaDetaylarClient.FIYAT]) *
        parseAccountingSafely(rows[i][faturaDetaylarClient.MIKTAR]) *
        parseAccountingSafely(rows[i][faturaDetaylarClient.KDV])) /
      100;
  }
  if (formatMoney) {
    return accounting.formatMoney(total.toFixed(2), '', 2, '.', ',');
  }
  return total.toFixed(2);
};

export const calculateOtvTutar = (rows, formatMoney) => {
  let total = 0.0;
  for (let i = 0; i < rows.length; i += 1) {
    total +=
      (((parseAccountingSafely(rows[i][faturaDetaylarClient.FIYAT]) *
        parseAccountingSafely(rows[i][faturaDetaylarClient.MIKTAR]) *
        parseAccountingSafely(rows[i][faturaDetaylarClient.KDV])) /
        100) *
        parseAccountingSafely(rows[i][faturaDetaylarClient.OTV])) /
      100;
  }
  if (formatMoney) {
    return accounting.formatMoney(total.toFixed(2), '', 2, '.', ',');
  }
  return total.toFixed(2);
};

export const calculateToplamTutar = (rows, formatMoney) => {
  let total = 0.0;
  for (let i = 0; i < rows.length; i += 1) {
    total +=
      parseAccountingSafely(rows[i][faturaDetaylarClient.FIYAT]) *
      parseAccountingSafely(rows[i][faturaDetaylarClient.MIKTAR]);
    total +=
      (parseAccountingSafely(rows[i][faturaDetaylarClient.FIYAT]) *
        parseAccountingSafely(rows[i][faturaDetaylarClient.MIKTAR]) *
        parseAccountingSafely(rows[i][faturaDetaylarClient.KDV])) /
      100;
    total +=
      (((parseAccountingSafely(rows[i][faturaDetaylarClient.FIYAT]) *
        parseAccountingSafely(rows[i][faturaDetaylarClient.MIKTAR]) *
        parseAccountingSafely(rows[i][faturaDetaylarClient.KDV])) /
        100) *
        parseAccountingSafely(rows[i][faturaDetaylarClient.OTV])) /
      100;
  }
  if (formatMoney) {
    return accounting.formatMoney(total.toFixed(2), '', 2, '.', ',');
  }
  return total.toFixed(2);
};
