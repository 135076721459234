import React, { Component } from 'react';
import { Provider, connect } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Font } from '@react-pdf/renderer';
import { checkAutoSignIn } from './store/actions';
import store from './store/index';
import './scss/style.scss';
import PDFGenerator from './pages/pdf/PDFGenerator';
import PDFInvoiceGenerator from './pages/pdf/PDFInvoiceGenerator';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse" />
  </div>
);

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));
// Pages
const Login = React.lazy(() => import('./pages/login/Login'));

const propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  checkAutoSignIn: PropTypes.func.isRequired,
};

class App extends Component {
  componentDidMount() {
    const { checkAutoSignIn } = this.props;
    checkAutoSignIn();
  }

  render() {
    const { isAuthenticated } = this.props;
    const route =
      isAuthenticated === true ? (
        <Switch>
          <Route
            path="/pdf/invoice/:payload"
            name="PDF Rapor"
            render={(props) => <PDFInvoiceGenerator {...props} />}
          />
          <Route
            path="/pdf/:payload"
            name="PDF Rapor"
            render={(props) => <PDFGenerator {...props} />}
          />
          <Route path="/" name="Anasayfa" render={(props) => <TheLayout {...props} />} />
        </Switch>
      ) : (
        <Switch>
          <Route
            path="/pdf/invoice/:payload"
            name="PDF Rapor"
            render={(props) => <PDFInvoiceGenerator {...props} />}
          />
          <Route
            path="/pdf/:payload"
            name="PDF Rapor"
            render={(props) => <PDFGenerator {...props} />}
          />
          <Route exact path="/login" name="Giriş" render={(props) => <Login {...props} />} />
          <Route path="/" name="Giriş" render={(props) => <Login {...props} />} />
        </Switch>
      );
    return (
      <Provider store={store}>
        <Router basename="">
          <React.Suspense fallback={loading}>
            <Switch>{route}</Switch>
          </React.Suspense>
        </Router>
      </Provider>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

const mapDispatchToProps = (dispatch) => ({
  checkAutoSignIn: () => dispatch(checkAutoSignIn()),
});

App.propTypes = propTypes;
export default connect(mapStateToProps, mapDispatchToProps)(App);
