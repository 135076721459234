import { takeEvery, fork, put, all, call, select } from 'redux-saga/effects';
import * as actionTypes from './actionTypes';
import * as actions from './actions';
import * as layoutActions from '../../layout/actions';
import * as api from '../../../api/makbuzlar';
import * as mapper from './utils/mapper';
import * as schemas from './utils/schemas';
import * as otherSchemas from '../others/utils/schemas';
import * as constants from '../../../constants/makbuzlar';
import { calculateTutar } from '../../../helpers/makbuzlar';

const getMusteriId = (state) => state.auth.user.musteriId;
const getMusteriAdi = (state) => state.auth.user.musteriAdi;
const getMusteriSifre = (state) => state.auth.user.musteriSifre;
const getMakbuzKarti = (state) => state.makbuzlar.makbuzKarti;

function* fetchMakbuzKartlariWorker({
  payload: { siralama, arama, kayitBaslangic, kayitBitis, makbuzTuru },
}) {
  const musteriId = yield select(getMusteriId);
  const musteriAdi = yield select(getMusteriAdi);
  const musteriSifre = yield select(getMusteriSifre);

  try {
    const response = yield call(
      api.fetchMakbuzKartlari,
      musteriId,
      musteriAdi,
      musteriSifre,
      siralama,
      arama,
      kayitBaslangic,
      kayitBitis,
      makbuzTuru
    );
    const { data } = response;
    yield put(actions.fetchMakbuzKartlariSuccess(mapper.responseFetchMakbuzKartlariMapper(data)));
  } catch (error) {
    yield put(layoutActions.showError(error.message));
  }
}

function* addMakbuzKartiWorker({ payload, history }) {
  yield put(layoutActions.showLoading());
  const musteriId = yield select(getMusteriId);
  const musteriAdi = yield select(getMusteriAdi);
  const musteriSifre = yield select(getMusteriSifre);

  const mappedPayload = mapper.requestAddMakbuzKartiMapper(
    musteriId,
    musteriAdi,
    musteriSifre,
    payload
  );

  try {
    const err = {
      message: 'Bilinmeyen bir hata oluştu!',
    };
    // First check makbuz no is present or not.
    {
      const responseCheck = yield call(
        api.checkMakbuzNo,
        musteriId,
        musteriAdi,
        musteriSifre,
        mappedPayload[constants.makbuzlarServer.MAKBUZ_NO]
      );

      // Check response schema
      const { data } = responseCheck;
      if (!otherSchemas.responseVarYokSchema.isValidSync(data)) {
        throw err;
      }

      if (data[0].geriDonenDeger === 'var') {
        err.message = 'Bu makbuz no kayıtlı. Lütfen başka makbuz no giriniz.';
        throw err;
      }
    }

    // Send request to add
    yield call(api.addMakbuzKarti, mappedPayload);
    yield put(layoutActions.showMessage('Başarıyla eklendi'));
    history.goBack();
  } catch (error) {
    yield put(layoutActions.showError(error.message));
  }
}

function* fetchMakbuzDetaylarWorker({ payload: { makbuzId } }) {
  yield put(layoutActions.showLoading());
  const musteriId = yield select(getMusteriId);
  const musteriAdi = yield select(getMusteriAdi);
  const musteriSifre = yield select(getMusteriSifre);

  try {
    const err = {
      message: 'Bilinmeyen bir hata oluştu',
    };
    const response = yield call(
      api.fetchMakbuzDetaylar,
      musteriId,
      musteriAdi,
      musteriSifre,
      makbuzId
    );
    const { data } = response;
    if (!schemas.responseMakbuzDetaylarSchema.isValidSync(data)) {
      throw err;
    }
    yield put(
      actions.fetchMakbuzDetaylarSuccess(mapper.responseFetchMakbuzDetaylarMapper(data, makbuzId))
    );
  } catch (error) {
    yield put(layoutActions.showError(error.message));
  }
  yield put(layoutActions.hideLoading());
}

function* updateMakbuzKartiWorker({ payload, history, previousMakbuzNo }) {
  yield put(layoutActions.showLoading());
  const musteriId = yield select(getMusteriId);
  const musteriAdi = yield select(getMusteriAdi);
  const musteriSifre = yield select(getMusteriSifre);

  const mappedPayload = mapper.requestUpdateMakbuzKartiMapper(
    musteriId,
    musteriAdi,
    musteriSifre,
    payload
  );

  try {
    const err = {
      message: 'Bilinmeyen bir hata oluştu',
    };

    // First check makbuz no is present or not if it is necessary.
    if (previousMakbuzNo !== mappedPayload[constants.makbuzlarServer.MAKBUZ_NO]) {
      const responseCheck = yield call(
        api.checkMakbuzNo,
        musteriId,
        musteriAdi,
        musteriSifre,
        mappedPayload[constants.makbuzlarServer.MAKBUZ_NO]
      );

      // Check response schema
      const { data } = responseCheck;
      if (!otherSchemas.responseVarYokSchema.isValidSync(data)) {
        throw err;
      }

      if (data[0].geriDonenDeger === 'var') {
        err.message = 'Bu makbuz no kayıtlı. Lütfen başka makbuz no giriniz.';
        throw err;
      }
    }

    // Send request to update
    yield call(api.updateMakbuzKarti, mappedPayload);
    yield put(layoutActions.showMessage('Başarıyla güncellendi'));
    history.goBack();
  } catch (error) {
    yield put(layoutActions.showError(error.message));
  }
}

function* updateMakbuzKartiAfterRowsChangeWorker({ payload }) {
  yield put(layoutActions.showLoading());
  const musteriId = yield select(getMusteriId);
  const musteriAdi = yield select(getMusteriAdi);
  const musteriSifre = yield select(getMusteriSifre);

  const mappedPayload = mapper.requestUpdateMakbuzKartiAfterRowChangeMapper(
    musteriId,
    musteriAdi,
    musteriSifre,
    payload
  );

  try {
    // Send request to update
    yield call(api.updateMakbuzKarti, mappedPayload);
  } catch (error) {
    yield put(layoutActions.showError(error.message));
  }
}

function* addMakbuzRowWorker({ payload }) {
  yield put(layoutActions.showLoading());
  const musteriId = yield select(getMusteriId);
  const musteriAdi = yield select(getMusteriAdi);
  const musteriSifre = yield select(getMusteriSifre);
  const makbuzKarti = yield select(getMakbuzKarti);

  const mappedPayload = mapper.requestAddMakbuzRowMapper(
    musteriId,
    musteriAdi,
    musteriSifre,
    payload,
    makbuzKarti
  );

  try {
    // Send request to update
    makbuzKarti.rows.push(payload);
    makbuzKarti[constants.makbuzlarClient.TUTAR] = calculateTutar(makbuzKarti.rows);
    yield call(updateMakbuzKartiAfterRowsChangeWorker, { payload: makbuzKarti });

    // Add row
    yield call(api.updateMakbuzDetaylar, mappedPayload);

    // Fetch data again
    yield put(actions.fetchMakbuzDetaylar(makbuzKarti[constants.makbuzlarClient.MAKBUZ_ID]));
    yield put(layoutActions.showMessage('Başarıyla eklendi'));
  } catch (error) {
    yield put(layoutActions.showError(error.message));
  }
}

function* updateMakbuzRowWorker({ payload, selectedRow }) {
  yield put(layoutActions.showLoading());
  const musteriId = yield select(getMusteriId);
  const musteriAdi = yield select(getMusteriAdi);
  const musteriSifre = yield select(getMusteriSifre);
  const makbuzKarti = yield select(getMakbuzKarti);
  const mappedPayload = mapper.requestUpdateMakbuzRowMapper(
    musteriId,
    musteriAdi,
    musteriSifre,
    payload,
    makbuzKarti,
    selectedRow
  );

  try {
    // Send request to update
    makbuzKarti.rows[selectedRow[constants.makbuzDetaylarClient.SIRA_NO] - 1] = payload;
    makbuzKarti[constants.makbuzlarClient.TUTAR] = calculateTutar(makbuzKarti.rows);
    yield call(updateMakbuzKartiAfterRowsChangeWorker, { payload: makbuzKarti });
    yield call(api.updateMakbuzDetaylar, mappedPayload);
    yield put(actions.fetchMakbuzDetaylar(makbuzKarti[constants.makbuzlarClient.MAKBUZ_ID]));
    yield put(layoutActions.showMessage('Başarıyla eklendi'));
  } catch (error) {
    yield put(layoutActions.showError(error.message));
  }
}

function* updateMakbuzRowAfterDeleteWorker({ selectedRow }) {
  yield put(layoutActions.showLoading());
  const musteriId = yield select(getMusteriId);
  const musteriAdi = yield select(getMusteriAdi);
  const musteriSifre = yield select(getMusteriSifre);
  const makbuzKarti = yield select(getMakbuzKarti);
  const mappedPayload = mapper.requestUpdateMakbuzRowAfterDeleteMapper(
    musteriId,
    musteriAdi,
    musteriSifre,
    makbuzKarti,
    selectedRow
  );

  try {
    yield call(api.updateMakbuzDetaylar, mappedPayload);
  } catch (error) {
    yield put(layoutActions.showError(error.message));
  }
}

function* deleteMakbuzKartiWorker({ payload: { makbuzId }, history }) {
  yield put(layoutActions.showLoading());
  const musteriId = yield select(getMusteriId);
  const musteriAdi = yield select(getMusteriAdi);
  const musteriSifre = yield select(getMusteriSifre);

  try {
    const err = {
      message: 'Bilinmeyen bir hata oluştu',
    };

    // Send request to delete
    const response = yield call(
      api.deleteMakbuzKarti,
      musteriId,
      musteriAdi,
      musteriSifre,
      makbuzId
    );

    // Check response schema
    const { data } = response;
    if (!otherSchemas.responseTrueFalseSchema.isValidSync(data)) {
      throw err;
    }

    if (data.geriDonenDeger !== 'true') {
      err.message = 'Bilinmeyen bir hata oluştu. Makbuz Karti silinemedi.';
      throw err;
    }
    yield put(layoutActions.showMessage('Makbuz Karti silindi.'));
    history.goBack();
  } catch (error) {
    yield put(layoutActions.showError(error.message));
  }
}

function* deleteMakbuzRowWorker({ payload }) {
  yield put(layoutActions.showLoading());
  const musteriId = yield select(getMusteriId);
  const musteriAdi = yield select(getMusteriAdi);
  const musteriSifre = yield select(getMusteriSifre);

  const mappedPayload = mapper.requestDeleteMakbuzRowMapper(
    musteriId,
    musteriAdi,
    musteriSifre,
    payload
  );

  try {
    // Send request to delete
    yield call(api.deleteMakbuzDetaylar, mappedPayload);

    // Update makbuz kartı
    const makbuzKarti = yield select(getMakbuzKarti);
    const index = payload[constants.makbuzDetaylarClient.SIRA_NO] - 1;
    if (index >= 0 && index < makbuzKarti.rows.length) {
      makbuzKarti.rows.splice(index, 1);
    }
    makbuzKarti.rows = makbuzKarti.rows.map((e, i) => {
      e[constants.makbuzDetaylarClient.SIRA_NO] = i + 1;
      return e;
    });
    makbuzKarti[constants.makbuzlarClient.TUTAR] = calculateTutar(makbuzKarti.rows);
    yield call(updateMakbuzKartiAfterRowsChangeWorker, { payload: makbuzKarti });

    // Update rows' sıra no
    for (let i = 0; i < makbuzKarti.rows.length; i += 1) {
      yield call(updateMakbuzRowAfterDeleteWorker, {
        selectedRow: makbuzKarti.rows[i],
      });
    }

    // Fetch makbuz
    yield put(actions.fetchMakbuzDetaylar(payload[constants.makbuzlarClient.MAKBUZ_ID]));
    yield put(layoutActions.showMessage('Makbuz satırı silindi.'));
  } catch (error) {
    yield put(layoutActions.showError(error.message));
  }
}

export function* watchMakbuzlar() {
  yield takeEvery(actionTypes.FETCH_MAKBUZ_KARTLARI, fetchMakbuzKartlariWorker);
  yield takeEvery(actionTypes.ADD_MAKBUZ_KARTI, addMakbuzKartiWorker);
  yield takeEvery(actionTypes.FETCH_MAKBUZ_DETAYLAR, fetchMakbuzDetaylarWorker);
  yield takeEvery(actionTypes.UPDATE_MAKBUZ_KARTI, updateMakbuzKartiWorker);
  yield takeEvery(
    actionTypes.UPDATE_MAKBUZ_KARTI_AFTER_ROWS_CHANGE,
    updateMakbuzKartiAfterRowsChangeWorker
  );
  yield takeEvery(actionTypes.ADD_MAKBUZ_ROW, addMakbuzRowWorker);
  yield takeEvery(actionTypes.UPDATE_MAKBUZ_ROW, updateMakbuzRowWorker);
  yield takeEvery(actionTypes.DELETE_MAKBUZ_KARTI, deleteMakbuzKartiWorker);
  yield takeEvery(actionTypes.DELETE_MAKBUZ_ROW, deleteMakbuzRowWorker);
}

function* makbuzlarSaga() {
  yield all([fork(watchMakbuzlar)]);
}

export default makbuzlarSaga;
